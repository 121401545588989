// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
// import Label from '../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  media: getIcon('ic_kanban'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  business: getIcon('ic_business'),
  roles: getIcon('ic_roles'),
  customlayout: getIcon('ic_customlayout'),
  layout: getIcon('ic_layout'),
  widgets: getIcon('ic_widgets'),
  insights: getIcon('ic_insights'),
  layers: getIcon('ic_layers'),
  apps: getIcon('ic_apps'),
  settings: getIcon('ic_settings'),
  billing: getIcon('ic_billing'),
  usermanagement: getIcon('ic_usermanagement'),
  pallet: getIcon('ic_pallet'),
  phone: getIcon('ic_phone'),
  brush: getIcon('ic_brush'),
  menuOpen: getIcon('ic_menuOpen'),
  component: getIcon('ic_component'),
  notifications: getIcon('ic_notifications')
};

const sidebarConfig = [
  // APP MANAGEMENT
  // -----------------------------------------------
  {
    subheader: 'account',
    items: [
      {
        title: 'overview',
        path: PATH_DASHBOARD.client.account.overview,
        icon: ICONS.dashboard
      },
      {
        title: 'settings',
        path: PATH_DASHBOARD.client.account.accountSettings,
        icon: ICONS.settings
      }
      // {
      //   title: 'user analytics',
      //   path: PATH_DASHBOARD.client.account.userAnalytics,
      //   icon: ICONS.analytics,
      //   badge: 'Coming soon'
      // }
    ]
  },
  {
    subheader: 'engage',
    items: [
      { title: 'notifications', path: PATH_DASHBOARD.client.engage.notifications, icon: ICONS.notifications },
      {
        title: 'events',
        path: PATH_DASHBOARD.client.engage.events,
        icon: ICONS.calendar
      },
      { title: 'content', path: PATH_DASHBOARD.client.engage.items, icon: ICONS.layers },
      { title: 'Content Categories', path: PATH_DASHBOARD.client.engage.itemGroups, icon: ICONS.widgets },
      {
        title: 'Media Library',
        path: PATH_DASHBOARD.client.engage.mediaLibrary,
        icon: ICONS.media
      },
      { title: 'chat', path: PATH_DASHBOARD.client.engage.chat, icon: ICONS.chat }
    ]
  },
  {
    subheader: 'build',
    items: [
      {
        title: 'pages',
        path: PATH_DASHBOARD.client.build.views,
        icon: ICONS.phone
      },
      {
        title: 'components',
        path: PATH_DASHBOARD.client.build.components,
        icon: ICONS.component
      }
    ]
  },

  {
    subheader: 'design',
    items: [{ title: 'navigation', path: PATH_DASHBOARD.client.design.template, icon: ICONS.pallet }]
  }
];

export default sidebarConfig;
