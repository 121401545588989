import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

LogoMVNU.propTypes = {
  sx: PropTypes.object
};

export default function LogoMVNU({ sx }) {
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box
      sx={{
        width: 200,
        maxHeight: 'auto',
        cursor: 'pointer',
        ...sx
      }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 256 220"
        // enableBackground="new 0 0 256 220"
      >
        <path
          fill="#022E5D"
          opacity="1.000000"
          stroke="none"
          d="
M145.000000,221.000000 
	C134.645767,221.000000 124.291542,221.000000 113.457001,220.636414 
	C111.885300,219.636169 110.850006,218.864349 109.693573,218.384476 
	C101.354630,214.924240 94.147629,210.628296 93.420868,200.186981 
	C93.295341,198.383530 94.239662,194.591873 89.987625,194.990479 
	C89.997536,195.002457 90.021530,195.022263 90.021423,194.666840 
	C87.713440,192.763855 85.453003,191.139404 83.090034,189.681122 
	C66.826111,179.644089 53.032192,166.832275 40.760738,152.271896 
	C26.144176,134.928986 18.396730,115.572647 23.389997,92.596977 
	C23.986973,89.850090 23.118467,88.927559 21.144468,87.443130 
	C17.938669,85.032379 15.200727,81.999474 12.265298,79.229179 
	C12.598401,78.880363 12.931505,78.531548 13.264609,78.182732 
	C15.671027,78.028198 18.077446,77.873657 21.120777,77.678223 
	C20.351381,76.391312 20.074978,75.618019 19.549109,75.096046 
	C11.629637,67.235283 5.808781,58.131836 2.788423,47.307808 
	C2.632501,46.749031 1.615893,46.430424 0.999999,46.000000 
	C1.000000,35.645771 1.000000,25.291544 1.364496,14.456484 
	C3.485995,9.650435 5.242997,5.325218 7.000000,1.000000 
	C8.388889,1.000000 9.777778,1.000000 11.578676,1.351277 
	C12.960299,2.576644 13.812510,3.653496 14.917708,4.293420 
	C25.827154,10.610140 37.550411,15.009672 50.093964,15.792231 
	C58.961540,16.345455 68.526489,16.798584 76.747826,14.123673 
	C90.219681,9.740447 103.443733,5.898893 117.705505,4.715399 
	C133.141205,3.434487 148.158676,4.710194 163.044739,8.140700 
	C169.781876,9.693281 176.033829,13.511783 182.791687,14.782611 
	C200.316956,18.078279 217.548584,16.156603 233.912537,8.902351 
	C238.255859,6.976925 241.987503,3.671702 246.000000,1.000003 
	C247.388885,1.000000 248.777786,1.000000 250.532745,1.379815 
	C252.932556,6.173087 254.966278,10.586544 257.000000,15.000001 
	C257.000000,25.020895 257.000000,35.041790 256.630676,45.550667 
	C255.911331,46.371151 255.356598,46.644592 255.241150,47.044765 
	C252.188873,57.625492 246.816803,66.815880 238.976517,74.571266 
	C238.248566,75.291328 237.841354,76.335625 237.040726,77.622307 
	C239.978195,77.935555 242.313828,78.184631 244.649475,78.433701 
	C244.889175,78.925865 245.128876,79.418022 245.368576,79.910187 
	C242.650208,82.397415 240.127945,85.160599 237.147308,87.278786 
	C235.017532,88.792290 233.937057,89.732277 234.650787,92.765945 
	C239.374451,112.843605 233.365768,130.747375 221.614975,146.625244 
	C207.461960,165.749008 189.902435,181.371002 169.038116,193.136200 
	C167.208893,194.167709 165.272522,196.657578 165.066162,198.644562 
	C163.934067,209.544724 157.207016,215.208618 147.696350,218.702774 
	C146.656586,219.084778 145.892136,220.216171 145.000000,221.000000 
M150.811691,199.743011 
	C136.139160,202.163467 121.450424,202.569992 106.254463,199.296005 
	C112.954048,210.394867 125.699837,215.578354 134.793030,211.532806 
	C133.207703,210.283722 131.608734,209.023895 130.905640,208.469925 
	C137.768860,205.741943 144.697800,202.987823 151.534561,200.402451 
	C151.534561,200.402451 151.417847,200.245834 151.417847,200.245834 
	C151.417847,200.245834 151.571732,200.378723 150.811691,199.743011 
M69.490448,111.912056 
	C69.613983,111.606476 69.737511,111.300903 70.218147,110.380852 
	C71.867592,107.358994 73.517044,104.337135 75.800682,101.061737 
	C79.203178,98.417404 82.578499,95.737152 86.014366,93.136902 
	C91.570755,88.931839 97.708427,87.698860 105.006271,89.384483 
	C95.344254,94.911125 88.258408,101.590736 87.756508,112.988365 
	C85.446297,112.128380 83.522339,111.412186 81.598381,110.695984 
	C81.355301,111.028648 81.112228,111.361313 80.869148,111.693985 
	C83.610023,114.764519 86.350899,117.835060 89.147896,120.968475 
	C86.762520,121.314064 84.419479,121.653519 81.873993,122.022301 
	C90.103775,133.337708 110.142838,133.113663 124.988495,123.550995 
	C121.068542,121.377182 117.227623,119.590561 113.771729,117.238266 
	C109.991646,114.665329 107.807594,110.901497 107.387894,106.889374 
	C122.010902,106.889374 136.299362,106.889374 151.985611,106.889374 
	C149.119064,110.626152 147.012146,114.417007 143.934906,117.091415 
	C140.836380,119.784325 136.773392,121.367531 132.856766,123.593979 
	C147.542038,133.025131 169.223557,133.437546 175.604446,121.892082 
	C173.287262,121.558708 170.973816,121.225876 169.181244,120.967979 
	C171.812485,117.619972 174.298401,114.456871 176.784317,111.293770 
	C176.408417,110.998352 176.032501,110.702934 175.656601,110.407516 
	C173.878265,111.290459 172.099930,112.173401 170.132858,113.150055 
	C169.670135,101.247299 162.144577,94.859779 152.943237,89.213631 
	C160.839905,87.799469 167.231216,89.183044 172.890579,93.868073 
	C176.072617,96.502281 179.486801,98.856087 182.991150,101.980240 
	C184.698929,104.976456 186.406723,107.972664 188.074203,111.753426 
	C188.161270,112.267342 188.248322,112.781250 188.161545,113.782547 
	C188.367203,114.415718 188.572861,115.048897 188.692429,116.536667 
	C188.781906,125.425400 185.241791,132.619263 178.623611,138.452301 
	C177.434296,139.500519 175.955353,140.853973 175.727142,142.249207 
	C174.734406,148.318771 174.154678,154.455887 173.424881,160.568451 
	C173.951233,160.725113 174.477570,160.881790 175.003906,161.038452 
	C188.962616,151.868927 201.355942,140.942123 210.590149,126.862846 
	C213.532898,122.376106 215.050339,116.954552 217.721985,111.306732 
	C220.584824,96.151871 213.134872,84.487869 203.854675,72.506561 
	C207.086563,73.410706 209.287369,74.026398 211.918594,74.859596 
	C212.025024,74.496384 212.131470,74.133179 212.130768,73.047173 
	C207.389297,64.025597 202.647812,55.004028 197.962891,46.090092 
	C212.703003,43.043350 226.217682,36.348850 237.649033,25.494032 
	C222.482559,30.832966 206.964386,32.833820 191.012314,32.145100 
	C189.115829,32.063221 187.155212,33.466953 184.635788,34.400017 
	C147.719421,12.091414 109.902565,12.146490 73.374863,34.351025 
	C70.848289,33.343426 69.218369,32.208897 67.521713,32.098335 
	C58.900139,31.536512 50.182175,31.825899 41.652271,30.674295 
	C34.172455,29.664459 26.896843,27.142071 18.937559,25.138388 
	C31.362280,36.046795 45.131256,43.095543 60.248486,46.181950 
	C54.928658,55.632500 49.742100,64.846291 44.555542,74.060089 
	C44.991627,74.299942 45.427708,74.539795 45.863792,74.779655 
	C48.128387,74.189560 50.392979,73.599464 53.547005,72.777611 
	C50.779053,76.839325 48.413071,79.853142 46.540352,83.147377 
	C41.459515,92.084908 37.376263,101.299988 40.826832,112.752937 
	C41.096252,113.707802 41.306538,114.684120 41.643951,115.614326 
	C46.755417,129.705826 56.944069,139.946533 67.795738,149.580185 
	C72.558136,153.808029 77.869316,157.417694 83.595810,161.818756 
	C83.595810,156.236633 83.921318,151.806107 83.475494,147.454605 
	C83.201706,144.782288 82.524399,141.271271 80.691841,139.787689 
	C71.705307,132.512405 68.593651,123.110954 69.034836,111.983299 
	C69.034836,111.983299 68.982613,112.077728 69.490448,111.912056 
M111.618065,142.021820 
	C110.908569,142.701340 109.470779,143.712173 109.605240,144.007736 
	C110.644379,146.291916 111.987679,148.437714 113.496254,151.081146 
	C115.541092,148.247955 117.063843,146.138123 118.774727,143.767639 
	C120.860405,145.632614 122.770554,147.340637 124.843948,149.194626 
	C126.334015,147.135086 127.615028,145.364517 129.033966,143.403305 
	C130.605957,145.623657 131.821823,147.341003 133.176910,149.254990 
	C135.316772,147.294601 137.207916,145.562073 139.199570,143.737457 
	C140.993042,146.280319 142.501724,148.419403 144.483810,151.229675 
	C146.264465,147.971527 147.693176,145.357346 148.848007,143.244293 
	C142.684738,141.086151 137.136169,138.998734 131.494110,137.205551 
	C124.987495,135.137589 120.447571,141.556808 114.337456,141.462677 
	C113.699203,141.452850 113.055679,141.785202 111.618065,142.021820 
M156.973419,141.856522 
	C155.096649,142.347412 153.219894,142.838287 151.713959,143.232178 
	C151.713959,148.748215 151.713959,153.813232 151.713959,158.820206 
	C158.648590,155.939102 162.034027,149.124435 160.534973,140.707703 
	C159.576202,140.994370 158.628723,141.277664 156.973419,141.856522 
M106.037437,155.470596 
	C106.037437,151.401016 106.037437,147.331436 106.037437,143.145508 
	C103.266861,142.386276 100.289528,141.570374 97.279251,140.745453 
	C96.261147,149.934738 99.849136,156.799454 106.601959,158.646637 
	C106.442947,157.789627 106.295723,156.996170 106.037437,155.470596 
M131.237503,187.090942 
	C130.576523,187.972092 129.915543,188.853241 129.013870,190.055267 
	C126.919373,187.328354 125.109589,184.972122 122.946358,182.155701 
	C121.333504,185.530151 120.025703,188.266373 118.409714,191.647385 
	C125.783180,191.647385 132.326157,191.647385 139.637589,191.647385 
	C137.858093,188.043793 136.509811,185.313431 134.921783,182.097549 
	C133.578583,183.939011 132.634811,185.232880 131.237503,187.090942 
M154.097610,178.397995 
	C153.401077,176.438797 152.704544,174.479584 152.126343,172.853210 
	C149.086380,178.943726 146.137070,184.852646 143.217819,190.701324 
	C153.516357,189.830124 154.924377,188.371857 154.097610,178.397995 
M25.970158,52.764107 
	C24.757332,52.257313 23.544506,51.750519 22.331680,51.243725 
	C22.064398,51.596737 21.797115,51.949749 21.529831,52.302757 
	C25.577177,57.202690 29.624523,62.102627 34.200302,67.642303 
	C35.718365,63.540382 36.824345,60.551937 37.956120,57.493797 
	C34.003063,55.936493 30.329800,54.489414 25.970158,52.764107 
M225.736237,55.274422 
	C223.892410,55.946651 222.048584,56.618877 219.948944,57.384361 
	C221.142731,60.581268 222.255020,63.559971 223.810822,67.726379 
	C228.397491,62.034477 232.397736,57.070312 236.397964,52.106148 
	C236.079880,51.812435 235.761780,51.518719 235.443695,51.225006 
	C232.452164,52.505238 229.460648,53.785473 225.736237,55.274422 
M103.838966,178.397156 
	C102.520004,187.820938 104.688881,190.198578 114.634453,190.622162 
	C111.809227,184.882980 108.904648,178.982620 105.888115,172.854828 
	C105.283455,174.374496 104.632149,176.011383 103.838966,178.397156 
z"
        />
        <path
          fill="#99D3F1"
          opacity="1.000000"
          stroke="none"
          d="
M75.166496,101.315277 
	C73.517044,104.337135 71.867592,107.358994 69.855194,110.834641 
	C69.319679,111.549797 69.149803,111.812897 68.982613,112.077728 
	C68.982613,112.077728 69.034836,111.983299 68.723831,112.003006 
	C65.395119,116.247765 62.377396,120.472816 59.103085,125.057121 
	C58.279922,124.664215 56.487095,124.124390 55.071499,123.075455 
	C50.206947,119.470932 45.474438,115.688202 40.691605,111.973389 
	C37.376263,101.299988 41.459515,92.084908 46.540352,83.147377 
	C48.413071,79.853142 50.779053,76.839325 53.547005,72.777611 
	C50.392979,73.599464 48.128387,74.189560 45.863792,74.779655 
	C45.427708,74.539795 44.991627,74.299942 44.555542,74.060089 
	C49.742100,64.846291 54.928658,55.632500 60.248486,46.181950 
	C45.131256,43.095543 31.362280,36.046795 18.937559,25.138388 
	C26.896843,27.142071 34.172455,29.664459 41.652271,30.674295 
	C50.182175,31.825899 58.900139,31.536512 67.521713,32.098335 
	C69.218369,32.208897 70.848289,33.343426 73.374863,34.351025 
	C109.902565,12.146490 147.719421,12.091414 184.635788,34.400017 
	C187.155212,33.466953 189.115829,32.063221 191.012314,32.145100 
	C206.964386,32.833820 222.482559,30.832966 237.649033,25.494032 
	C226.217682,36.348850 212.703003,43.043350 197.962891,46.090092 
	C202.647812,55.004028 207.389297,64.025597 211.989075,73.619873 
	C211.727661,74.342407 211.607925,74.492249 211.488174,74.642090 
	C209.287369,74.026398 207.086563,73.410706 203.854675,72.506561 
	C213.134872,84.487869 220.584824,96.151871 217.149933,111.766663 
	C211.008270,117.611969 205.506760,123.096977 197.519089,125.353951 
	C195.655060,119.637489 194.259720,113.704742 188.114517,110.968872 
	C186.406723,107.972664 184.698929,104.976456 183.118729,101.378403 
	C185.787674,98.879646 188.329025,96.982727 191.631882,94.517410 
	C188.198257,93.386581 186.053680,92.680283 183.374939,91.798073 
	C193.800583,84.746178 200.875778,76.536430 198.200775,63.317249 
	C199.608032,63.453125 200.655701,63.653942 202.368561,63.982262 
	C199.669113,58.224079 195.416092,56.162815 189.929382,55.380798 
	C188.094879,54.076214 186.562775,52.645557 184.765228,52.116184 
	C173.521774,48.804993 163.881973,51.795864 155.765991,60.046322 
	C155.212204,60.609268 155.104523,61.611023 154.376022,63.454910 
	C153.192291,60.584770 152.801239,59.636581 151.942734,57.555012 
	C149.234375,61.185493 147.010696,64.166298 144.462433,67.032364 
	C143.137573,65.975006 142.137268,65.032402 141.003281,63.963818 
	C138.676956,71.969727 140.886078,76.232834 149.438339,80.919495 
	C148.872421,82.634605 148.043228,84.311752 147.836060,86.062508 
	C147.692413,87.276436 148.405548,88.591736 149.042511,90.077522 
	C150.424667,93.428123 151.500168,96.562950 152.575684,99.697777 
	C136.465393,86.002495 121.400360,86.225212 106.308105,100.455055 
	C105.932640,100.140709 105.557167,99.826370 105.181702,99.512024 
	C106.527977,96.303436 107.874245,93.094849 109.535889,89.795837 
	C109.610153,86.646988 109.369049,83.588562 109.113152,80.342529 
	C116.686729,77.126389 119.436035,71.777390 116.898979,64.061562 
	C115.623077,65.114265 114.401855,66.121857 112.999725,66.829758 
	C110.616203,63.748325 108.413589,60.966576 105.715866,57.559547 
	C105.164467,59.873531 104.892403,61.015293 104.491371,62.698257 
	C103.449677,61.465843 102.859909,60.690826 102.192291,59.989967 
	C93.899437,51.284340 80.769272,48.220078 71.309555,52.911549 
	C70.525024,53.300636 70.238457,54.693821 69.328522,55.741470 
	C62.691170,55.482414 58.416897,58.508152 55.066021,64.001099 
	C57.000614,63.761589 58.574604,63.566723 60.149597,63.767235 
	C57.002449,77.194481 64.720078,84.844521 74.496651,91.818359 
	C71.785179,92.743584 69.655548,93.470268 66.426743,94.572021 
	C69.810104,97.182495 72.488297,99.248886 75.166496,101.315277 
M130.874329,54.412968 
	C136.250641,44.331944 144.252151,37.194851 155.102219,33.183445 
	C145.199615,32.308670 136.656464,35.412666 128.925812,41.093578 
	C121.064774,35.325031 112.631859,32.204121 103.246506,32.870476 
	C108.168892,36.067978 113.612236,38.695469 117.862663,42.616196 
	C122.257530,46.670174 125.493927,51.980049 129.382767,56.925812 
	C129.709824,56.384621 130.112152,55.718891 130.874329,54.412968 
z"
        />
        <path
          fill="#F8F9FA"
          opacity="1.000000"
          stroke="none"
          d="
M197.841202,63.382191 
	C200.875778,76.536430 193.800583,84.746178 183.374939,91.798073 
	C186.053680,92.680283 188.198257,93.386581 191.631882,94.517410 
	C188.329025,96.982727 185.787674,98.879646 183.021347,101.056396 
	C179.486801,98.856087 176.072617,96.502281 172.890579,93.868073 
	C167.231216,89.183044 160.839905,87.799469 152.943237,89.213631 
	C162.144577,94.859779 169.670135,101.247299 170.132858,113.150055 
	C172.099930,112.173401 173.878265,111.290459 175.656601,110.407516 
	C176.032501,110.702934 176.408417,110.998352 176.784317,111.293770 
	C174.298401,114.456871 171.812485,117.619972 169.181244,120.967979 
	C170.973816,121.225876 173.287262,121.558708 175.604446,121.892082 
	C169.223557,133.437546 147.542038,133.025131 132.856766,123.593979 
	C136.773392,121.367531 140.836380,119.784325 143.934906,117.091415 
	C147.012146,114.417007 149.119064,110.626152 151.985611,106.889374 
	C136.299362,106.889374 122.010902,106.889374 107.387894,106.889374 
	C107.807594,110.901497 109.991646,114.665329 113.771729,117.238266 
	C117.227623,119.590561 121.068542,121.377182 124.988495,123.550995 
	C110.142838,133.113663 90.103775,133.337708 81.873993,122.022301 
	C84.419479,121.653519 86.762520,121.314064 89.147896,120.968475 
	C86.350899,117.835060 83.610023,114.764519 80.869148,111.693985 
	C81.112228,111.361313 81.355301,111.028648 81.598381,110.695984 
	C83.522339,111.412186 85.446297,112.128380 87.756508,112.988365 
	C88.258408,101.590736 95.344254,94.911125 105.006271,89.384483 
	C97.708427,87.698860 91.570755,88.931839 86.014366,93.136902 
	C82.578499,95.737152 79.203178,98.417404 75.483589,101.188507 
	C72.488297,99.248886 69.810104,97.182495 66.426743,94.572021 
	C69.655548,93.470268 71.785179,92.743584 74.496651,91.818359 
	C64.720078,84.844521 57.002449,77.194481 60.573975,63.648540 
	C64.026398,63.285778 67.055450,63.437088 70.022263,63.585289 
	C69.774582,78.627975 78.603073,87.581017 90.707268,85.446907 
	C82.069359,80.108833 79.548805,75.126701 82.213257,68.657616 
	C91.777306,72.704094 92.510323,74.239662 86.732925,80.379005 
	C89.898956,80.630898 92.855186,81.765999 94.854134,80.838326 
	C99.052078,78.890167 101.310287,81.014862 103.696213,83.554703 
	C105.612656,85.594765 107.384506,87.770645 109.220520,89.886261 
	C107.874245,93.094849 106.527977,96.303436 105.181702,99.512024 
	C105.557167,99.826370 105.932640,100.140709 106.308105,100.455055 
	C121.400360,86.225212 136.465393,86.002495 152.575684,99.697777 
	C151.500168,96.562950 150.424667,93.428123 149.162933,89.762321 
	C150.550217,87.519470 152.080444,85.765175 153.707062,84.105370 
	C156.329849,81.429085 158.584183,78.463821 163.329498,80.865906 
	C165.183075,81.804192 168.141342,80.560127 171.349380,80.233360 
	C165.282333,74.292793 166.421143,72.200577 176.720505,68.386536 
	C178.553726,76.740982 174.559067,81.958191 166.844513,85.813789 
	C180.936539,86.408073 186.714737,80.279732 188.632004,63.382191 
	C191.656296,63.382191 194.748749,63.382191 197.841202,63.382191 
z"
        />
        <path
          fill="#FAFAFB"
          opacity="1.000000"
          stroke="none"
          d="
M188.094360,111.361145 
	C194.259720,113.704742 195.655060,119.637489 197.519089,125.353951 
	C205.506760,123.096977 211.008270,117.611969 216.898926,112.093674 
	C215.050339,116.954552 213.532898,122.376106 210.590149,126.862846 
	C201.355942,140.942123 188.962616,151.868927 175.003906,161.038452 
	C174.477570,160.881790 173.951233,160.725113 173.424881,160.568451 
	C174.154678,154.455887 174.734406,148.318771 175.727142,142.249207 
	C175.955353,140.853973 177.434296,139.500519 178.623611,138.452301 
	C185.241791,132.619263 188.781906,125.425400 188.854797,115.879051 
	C188.789902,114.579346 188.562653,113.937248 188.335388,113.295158 
	C188.248322,112.781250 188.161270,112.267342 188.094360,111.361145 
z"
        />
        <path
          fill="#FAFAFB"
          opacity="1.000000"
          stroke="none"
          d="
M40.759216,112.363159 
	C45.474438,115.688202 50.206947,119.470932 55.071499,123.075455 
	C56.487095,124.124390 58.279922,124.664215 59.103085,125.057121 
	C62.377396,120.472816 65.395119,116.247765 68.771202,112.028839 
	C68.593651,123.110954 71.705307,132.512405 80.691841,139.787689 
	C82.524399,141.271271 83.201706,144.782288 83.475494,147.454605 
	C83.921318,151.806107 83.595810,156.236633 83.595810,161.818756 
	C77.869316,157.417694 72.558136,153.808029 67.795738,149.580185 
	C56.944069,139.946533 46.755417,129.705826 41.643951,115.614326 
	C41.306538,114.684120 41.096252,113.707802 40.759216,112.363159 
z"
        />
        <path
          fill="#F2F2F4"
          opacity="1.000000"
          stroke="none"
          d="
M151.626755,200.233704 
	C144.697800,202.987823 137.768860,205.741943 130.905640,208.469925 
	C131.608734,209.023895 133.207703,210.283722 134.793030,211.532806 
	C125.699837,215.578354 112.954048,210.394867 106.254463,199.296005 
	C121.450424,202.569992 136.139160,202.163467 151.179840,200.014893 
	C151.547974,200.286758 151.626755,200.233704 151.626755,200.233704 
z"
        />
        <path
          fill="#F2F3F5"
          opacity="1.000000"
          stroke="none"
          d="
M112.016327,141.990295 
	C113.055679,141.785202 113.699203,141.452850 114.337456,141.462677 
	C120.447571,141.556808 124.987495,135.137589 131.494110,137.205551 
	C137.136169,138.998734 142.684738,141.086151 148.848007,143.244293 
	C147.693176,145.357346 146.264465,147.971527 144.483810,151.229675 
	C142.501724,148.419403 140.993042,146.280319 139.199570,143.737457 
	C137.207916,145.562073 135.316772,147.294601 133.176910,149.254990 
	C131.821823,147.341003 130.605957,145.623657 129.033966,143.403305 
	C127.615028,145.364517 126.334015,147.135086 124.843948,149.194626 
	C122.770554,147.340637 120.860405,145.632614 118.774727,143.767639 
	C117.063843,146.138123 115.541092,148.247955 113.496254,151.081146 
	C111.987679,148.437714 110.644379,146.291916 109.605240,144.007736 
	C109.470779,143.712173 110.908569,142.701340 112.016327,141.990295 
z"
        />
        <path
          fill="#F1F2F4"
          opacity="1.000000"
          stroke="none"
          d="
M157.327332,141.708740 
	C158.628723,141.277664 159.576202,140.994370 160.534973,140.707703 
	C162.034027,149.124435 158.648590,155.939102 151.713959,158.820206 
	C151.713959,153.813232 151.713959,148.748215 151.713959,143.232178 
	C153.219894,142.838287 155.096649,142.347412 157.327332,141.708740 
z"
        />
        <path
          fill="#F2F3F5"
          opacity="1.000000"
          stroke="none"
          d="
M106.092972,155.836655 
	C106.295723,156.996170 106.442947,157.789627 106.601959,158.646637 
	C99.849136,156.799454 96.261147,149.934738 97.279251,140.745453 
	C100.289528,141.570374 103.266861,142.386276 106.037437,143.145508 
	C106.037437,147.331436 106.037437,151.401016 106.092972,155.836655 
z"
        />
        <path
          fill="#EEEFF1"
          opacity="1.000000"
          stroke="none"
          d="
M131.464264,186.808853 
	C132.634811,185.232880 133.578583,183.939011 134.921783,182.097549 
	C136.509811,185.313431 137.858093,188.043793 139.637589,191.647385 
	C132.326157,191.647385 125.783180,191.647385 118.409714,191.647385 
	C120.025703,188.266373 121.333504,185.530151 122.946358,182.155701 
	C125.109589,184.972122 126.919373,187.328354 129.013870,190.055267 
	C129.915543,188.853241 130.576523,187.972092 131.464264,186.808853 
z"
        />
        <path
          fill="#ECEDEF"
          opacity="1.000000"
          stroke="none"
          d="
M154.189484,178.775635 
	C154.924377,188.371857 153.516357,189.830124 143.217819,190.701324 
	C146.137070,184.852646 149.086380,178.943726 152.126343,172.853210 
	C152.704544,174.479584 153.401077,176.438797 154.189484,178.775635 
z"
        />
        <path
          fill="#F0F1F3"
          opacity="1.000000"
          stroke="none"
          d="
M26.313347,52.903221 
	C30.329800,54.489414 34.003063,55.936493 37.956120,57.493797 
	C36.824345,60.551937 35.718365,63.540382 34.200302,67.642303 
	C29.624523,62.102627 25.577177,57.202690 21.529831,52.302757 
	C21.797115,51.949749 22.064398,51.596737 22.331680,51.243725 
	C23.544506,51.750519 24.757332,52.257313 26.313347,52.903221 
z"
        />
        <path
          fill="#F0F1F3"
          opacity="1.000000"
          stroke="none"
          d="
M226.102676,55.170063 
	C229.460648,53.785473 232.452164,52.505238 235.443695,51.225006 
	C235.761780,51.518719 236.079880,51.812435 236.397964,52.106148 
	C232.397736,57.070312 228.397491,62.034477 223.810822,67.726379 
	C222.255020,63.559971 221.142731,60.581268 219.948944,57.384361 
	C222.048584,56.618877 223.892410,55.946651 226.102676,55.170063 
z"
        />
        <path
          fill="#EDEEF0"
          opacity="1.000000"
          stroke="none"
          d="
M103.909912,178.022705 
	C104.632149,176.011383 105.283455,174.374496 105.888115,172.854828 
	C108.904648,178.982620 111.809227,184.882980 114.634453,190.622162 
	C104.688881,190.198578 102.520004,187.820938 103.909912,178.022705 
z"
        />
        <path
          fill="#99D3F1"
          opacity="1.000000"
          stroke="none"
          d="
M188.248474,113.538849 
	C188.562653,113.937248 188.789902,114.579346 188.897842,115.451752 
	C188.572861,115.048897 188.367203,114.415718 188.248474,113.538849 
z"
        />
        <path
          fill="#99D3F1"
          opacity="1.000000"
          stroke="none"
          d="
M212.042648,73.981277 
	C212.131470,74.133179 212.025024,74.496384 211.703384,74.750839 
	C211.607925,74.492249 211.727661,74.342407 212.042648,73.981277 
z"
        />
        <path
          fill="#FAFAFB"
          opacity="1.000000"
          stroke="none"
          d="
M69.236526,111.994888 
	C69.149803,111.812897 69.319679,111.549797 69.676636,111.141876 
	C69.737511,111.300903 69.613983,111.606476 69.236526,111.994888 
z"
        />
        <path
          fill="#F2F2F4"
          opacity="1.000000"
          stroke="none"
          d="
M151.559845,200.332733 
	C151.571732,200.378723 151.417847,200.245834 151.417847,200.245834 
	C151.417847,200.245834 151.534561,200.402451 151.580658,200.318085 
	C151.626755,200.233704 151.547974,200.286758 151.559845,200.332733 
z"
        />
        <path
          fill="#002C5C"
          opacity="1.000000"
          stroke="none"
          d="
M89.987625,194.990479 
	C89.977707,198.102966 89.977707,201.227448 89.977707,204.609314 
	C86.329056,204.609314 83.121765,204.609314 79.523918,204.609314 
	C79.122040,202.503250 78.703186,200.308228 78.284332,198.113205 
	C77.797401,198.112061 77.310463,198.110901 76.823524,198.109756 
	C76.274673,200.368973 75.725815,202.628189 75.176956,204.887390 
	C74.839561,204.881409 74.502167,204.875427 74.164780,204.869446 
	C73.046173,201.994171 71.927574,199.118881 70.738876,196.063446 
	C76.108994,193.361008 82.135078,194.519470 84.005531,198.133057 
	C85.734924,197.106689 87.498314,196.060150 89.641617,195.017929 
	C90.021530,195.022263 89.997536,195.002457 89.987625,194.990479 
z"
        />
        <path
          fill="#07315F"
          opacity="1.000000"
          stroke="none"
          d="
M109.535889,89.795837 
	C107.384506,87.770645 105.612656,85.594765 103.696213,83.554703 
	C101.310287,81.014862 99.052078,78.890167 94.854134,80.838326 
	C92.855186,81.765999 89.898956,80.630898 86.732925,80.379005 
	C92.510323,74.239662 91.777306,72.704094 82.213257,68.657616 
	C79.548805,75.126701 82.069359,80.108833 90.707268,85.446907 
	C78.603073,87.581017 69.774582,78.627975 70.022263,63.585289 
	C67.055450,63.437088 64.026398,63.285778 60.572971,63.253159 
	C58.574604,63.566723 57.000614,63.761589 55.066021,64.001099 
	C58.416897,58.508152 62.691170,55.482414 69.778580,55.829208 
	C78.496796,56.171173 85.125450,59.784599 91.700638,63.698162 
	C95.218544,65.792023 98.926186,67.638824 102.717224,69.181877 
	C106.487930,70.716652 110.145096,70.119476 113.180626,67.129440 
	C114.401855,66.121857 115.623077,65.114265 116.898979,64.061562 
	C119.436035,71.777390 116.686729,77.126389 109.113152,80.342529 
	C109.369049,83.588562 109.610153,86.646988 109.535889,89.795837 
z"
        />
        <path
          fill="#06305F"
          opacity="1.000000"
          stroke="none"
          d="
M198.200775,63.317249 
	C194.748749,63.382191 191.656296,63.382191 188.632004,63.382191 
	C186.714737,80.279732 180.936539,86.408073 166.844513,85.813789 
	C174.559067,81.958191 178.553726,76.740982 176.720505,68.386536 
	C166.421143,72.200577 165.282333,74.292793 171.349380,80.233360 
	C168.141342,80.560127 165.183075,81.804192 163.329498,80.865906 
	C158.584183,78.463821 156.329849,81.429085 153.707062,84.105370 
	C152.080444,85.765175 150.550217,87.519470 148.856293,89.546547 
	C148.405548,88.591736 147.692413,87.276436 147.836060,86.062508 
	C148.043228,84.311752 148.872421,82.634605 149.438339,80.919495 
	C140.886078,76.232834 138.676956,71.969727 141.003281,63.963818 
	C142.137268,65.032402 143.137573,65.975006 144.665253,67.345932 
	C152.212753,72.379738 158.218918,68.772675 163.980621,65.149757 
	C172.043091,60.080147 180.178558,55.475166 190.109055,55.671387 
	C195.416092,56.162815 199.669113,58.224079 202.368561,63.982262 
	C200.655701,63.653942 199.608032,63.453125 198.200775,63.317249 
z"
        />
        <path
          fill="#073260"
          opacity="1.000000"
          stroke="none"
          d="
M130.694397,54.733063 
	C130.112152,55.718891 129.709824,56.384621 129.382767,56.925812 
	C125.493927,51.980049 122.257530,46.670174 117.862663,42.616196 
	C113.612236,38.695469 108.168892,36.067978 103.246506,32.870476 
	C112.631859,32.204121 121.064774,35.325031 128.925812,41.093578 
	C136.656464,35.412666 145.199615,32.308670 155.102219,33.183445 
	C144.252151,37.194851 136.250641,44.331944 130.694397,54.733063 
z"
        />
        <path
          fill="#F9FAFB"
          opacity="1.000000"
          stroke="none"
          d="
M189.929382,55.380798 
	C180.178558,55.475166 172.043091,60.080147 163.980621,65.149757 
	C158.218918,68.772675 152.212753,72.379738 144.989822,67.460670 
	C147.010696,64.166298 149.234375,61.185493 151.942734,57.555012 
	C152.801239,59.636581 153.192291,60.584770 154.376022,63.454910 
	C155.104523,61.611023 155.212204,60.609268 155.765991,60.046322 
	C163.881973,51.795864 173.521774,48.804993 184.765228,52.116184 
	C186.562775,52.645557 188.094879,54.076214 189.929382,55.380798 
M152.497528,63.522587 
	C152.497528,63.522587 152.469284,63.451145 152.497528,63.522587 
z"
        />
        <path
          fill="#F9FAFB"
          opacity="1.000000"
          stroke="none"
          d="
M112.999725,66.829758 
	C110.145096,70.119476 106.487930,70.716652 102.717224,69.181877 
	C98.926186,67.638824 95.218544,65.792023 91.700638,63.698162 
	C85.125450,59.784599 78.496796,56.171173 70.170593,55.708214 
	C70.238457,54.693821 70.525024,53.300636 71.309555,52.911549 
	C80.769272,48.220078 93.899437,51.284340 102.192291,59.989967 
	C102.859909,60.690826 103.449677,61.465843 104.491371,62.698257 
	C104.892403,61.015293 105.164467,59.873531 105.715866,57.559547 
	C108.413589,60.966576 110.616203,63.748325 112.999725,66.829758 
z"
        />
        <path
          fill="#99D3F1"
          opacity="1.000000"
          stroke="none"
          d="
M152.483398,63.486866 
	C152.469284,63.451145 152.497528,63.522587 152.483398,63.486866 
z"
        />
      </svg>
    </Box>
  );
}
