import { createSlice } from '@reduxjs/toolkit';
import { omit, pullAll, cloneDeep } from 'lodash';
// Firebase
import { db, functions, arrayRemove, arrayUnion, serverTimestamp } from 'config/firebase';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  viewList: [],
  currentView: null,
  isOpenModal: false,
  selectedViewId: null
};

const slice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // END LOADING
    endLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET VIEW LIST
    getViewListSuccess(state, action) {
      state.isLoading = false;
      state.viewList = action.payload;
      state.currentView = null;
    },

    // GET VIEW
    getViewSuccess(state, action) {
      state.isLoading = false;
      state.currentView = action.payload;
    },

    // CREATE VIEW
    getCreateViewSuccess(state, action) {
      state.isLoading = false;
      state.viewList = action.payload;
    },

    // SELECT VIEW
    selectView(state, action) {
      const viewId = action.payload;
      state.isOpenModal = true;
      state.selectedViewId = viewId;
    },

    // UPDATE VIEW NAME
    getUpdateViewSuccess(state, action) {
      const { viewId, updateView } = action.payload;
      const viewIndex = state.viewList.findIndex((view) => view.id === viewId);
      state.isLoading = false;
      if (state.currentView !== null) {
        state.currentView = updateView;
      }
      state.viewList[viewIndex] = updateView;
    },

    // DELETE VIEW
    getDeleteViewSuccess(state, action) {
      const deletedViewId = action.payload;
      state.isLoading = false;
      state.viewList = state.viewList.filter((view) => view.id !== deletedViewId);
    },

    // DELETE MULTIPLE VIEWS
    getDeleteMultiViewsSuccess(state, action) {
      const deletedViews = action.payload;
      state.isLoading = false;
      state.viewList = state.viewList.filter((view) => !deletedViews.includes(view.id));
    },

    // GET COMPONENTS
    getViewComponentsSuccess(state, action) {
      state.isLoading = false;
      state.currentViewComponents = action.payload;
    },

    persistComponent(state, action) {
      const { sections } = action.payload;
      state.isLoading = false;
      state.currentView.sections = sections;
    },

    persistSection(state, action) {
      state.isLoading = false;
      state.currentView.sectionOrder = action.payload;
    },

    // ADD COMPONENT
    addComponentSuccess(state, action) {
      const { component, sectionId } = action.payload;
      const componentsIds = [...state.currentView.componentIds, component.id];
      const sectionComponents = [...state.currentView.sections[sectionId].componentIds, component.id];
      state.isLoading = false;
      state.currentView.components[component.id] = component;
      state.currentView.sections[sectionId].componentIds = sectionComponents;
      state.currentView.componentIds = componentsIds;
    },

    // UPDATE COMPONENT
    updateComponentSuccess(state, action) {
      const updatedComponent = action.payload;
      state.isLoading = false;
      state.currentView.components[updatedComponent.id] = updatedComponent;
    },

    // DELETE COMPONENT
    deleteComponentSuccess(state, action) {
      const { component, sectionId } = action.payload;
      state.isLoading = false;
      state.currentView.componentIds = state.currentView.componentIds.filter((comp) => comp !== component.id);
      state.currentView.components = omit(state.currentView.components, [component.id]);
      state.currentView.sections[sectionId].componentIds = state.currentView.sections[sectionId].componentIds.filter(
        (comp) => comp !== component.id
      );
    },

    // CREATE NEW SECTION
    createSectionSuccess(state, action) {
      const newSection = action.payload;
      state.isLoading = false;
      state.currentView.sections = {
        ...state.currentView.sections,
        [newSection.id]: newSection
      };
      state.currentView.sectionOrder.push(newSection.id);
    },

    // UPDATE SECTION
    updateSectionSuccess(state, action) {
      const section = action.payload;
      state.isLoading = false;
      state.currentView.sections[section.id] = section;
    },

    // DELETE SECTION
    deleteSectionSuccess(state, action) {
      const { sectionId, deletedComponentIds } = action.payload;
      state.isLoading = false;
      state.currentView.sections = omit(state.currentView.sections, [sectionId]);
      state.currentView.components = pullAll(state.currentView.components, deletedComponentIds);
      state.currentView.sectionOrder = state.currentView.sectionOrder.filter((section) => section !== sectionId);
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedViewId = null;
    }
  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// Actions
export const { openModal, closeModal, selectView } = actions;

// ----------------------------------------------------------------------

export function getViewList() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { client } = cloneDeep(getState());
    const clientRef = db.collection('clients').doc(client.currentClient.id);
    const viewRef = clientRef.collection('views');
    try {
      const views = await viewRef.get();
      const viewList = [];
      views.forEach(async (view) => viewList.push(view.data()));
      dispatch(slice.actions.getViewListSuccess(viewList));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getView(viewId, clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const clientRef = db.collection('clients').doc(clientId);
    try {
      // Get Top Level View
      const view = await clientRef.collection('views').doc(viewId).get();
      const currentView = view.data();

      // Get View Components
      const { componentIds } = currentView;
      let componentReads = [];
      if (componentIds.length >= 1) {
        componentReads = componentIds.map((id) => clientRef.collection('components').doc(id).get());
      }
      const viewComponents = await Promise.all(componentReads);
      const currentViewComponents = {};
      // reading components for views and sections here
      viewComponents.forEach((component) => (currentViewComponents[component.data().id] = component.data()));

      // Get Sections
      const sections = {};
      const firebaseSections = await clientRef.collection('sections').get();

      firebaseSections.forEach((doc) => {
        sections[doc.id] = doc.data();
      });
      dispatch(slice.actions.getViewSuccess({ ...currentView, components: currentViewComponents, sections }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createView(newView) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { client, view } = cloneDeep(getState());
    const { viewList } = view;
    const collectionRef = db.collection('clients').doc(client.currentClient.id).collection('views');
    const viewRef = collectionRef.doc();

    try {
      await viewRef.set({
        ...newView,
        id: viewRef.id,
        createdAt: serverTimestamp()
      });

      const createdView = await collectionRef.doc(viewRef.id).get();
      viewList.push(createdView.data());
      dispatch(slice.actions.getCreateViewSuccess(viewList));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateView(viewId, updateView) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client } = cloneDeep(getState());
    const viewRef = db.collection('clients').doc(client.currentClient.id).collection('views').doc(viewId);

    try {
      await viewRef.set(updateView, { merge: true });
      dispatch(slice.actions.getUpdateViewSuccess({ viewId, updateView }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function deleteView(viewId) {
  return async (dispatch, getState) => {
    // dispatch(slice.actions.startLoading());
    const { client } = cloneDeep(getState());
    try {
      const onDeleteView = functions.httpsCallable('callable-onDeleteView');
      await onDeleteView({ clientId: client.currentClient.id, viewId });
      dispatch(slice.actions.getDeleteViewSuccess(viewId));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteMultiViews(viewsToDelete) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { client } = cloneDeep(getState());
    try {
      const clientId = client.currentClient.id;
      const batch = db.batch();
      const viewIdsToDelete = viewsToDelete.map((view) => view.id);
      const clientRef = db.doc(`clients/${clientId}`);

      // loop through viewsToDelete array and create a query for that view
      viewsToDelete.forEach((view) => {
        const viewRef = clientRef.collection('views').doc(view.id);

        // delete view sections
        if (view.sectionOrder.length >= 1) {
          const sectionsToDelete = view.sectionOrder.map((sectionId) =>
            clientRef.collection('sections').doc(sectionId)
          );
          sectionsToDelete.forEach((section) => batch.delete(section));
        }

        // detach view id from components
        if (view.componentIds.length >= 1) {
          view.componentIds.forEach((componentId) => {
            const componentRef = clientRef.collection('components').doc(componentId);
            batch.set(
              componentRef,
              {
                activeViews: arrayRemove(view.id)
              },
              { merge: true }
            );
          });
        }

        // delete view
        batch.delete(viewRef);
      });

      await batch.commit();

      if (viewIdsToDelete.length === 1) {
        dispatch(slice.actions.getDeleteViewSuccess(viewIdsToDelete[0]));
      } else {
        dispatch(slice.actions.getDeleteMultiViewsSuccess(viewIdsToDelete));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createSection(section) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client, view } = cloneDeep(getState());
    const viewRef = db.collection('clients').doc(client.currentClient.id).collection('views').doc(view.currentView.id);
    const sectionRef = db.collection('clients').doc(client.currentClient.id).collection('sections').doc();

    try {
      const batch = db.batch();

      // Section Document Create
      batch.set(sectionRef, {
        ...section,
        id: sectionRef.id
      });

      // View Document Update
      batch.set(
        viewRef,
        {
          sectionOrder: arrayUnion(sectionRef.id)
        },
        { merge: true }
      );

      await batch.commit();
      dispatch(
        slice.actions.createSectionSuccess({
          ...section,
          id: sectionRef.id
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteSection(sectionId) {
  return async (dispatch, getState) => {
    // dispatch(slice.actions.startLoading());

    const { client, view } = cloneDeep(getState());
    const viewRef = db.collection('clients').doc(client.currentClient.id).collection('views').doc(view.currentView.id);
    const sectionRef = db.collection('clients').doc(client.currentClient.id).collection('sections').doc(sectionId);
    try {
      const deletedSection = await sectionRef.get();
      console.log(deletedSection.data());
      console.log([...deletedSection.data().componentIds]);
      const deleteSectionBatch = db.batch();

      // Remove Section from View
      deleteSectionBatch.set(
        viewRef,
        {
          components: arrayRemove(...deletedSection.data().componentIds),
          sectionOrder: arrayRemove(sectionId)
        },
        { merge: true }
      );
      // Delete Section Document
      deleteSectionBatch.delete(sectionRef);

      await deleteSectionBatch.commit();

      dispatch(
        slice.actions.deleteSectionSuccess({ sectionId, deletedComponentIds: [...deletedSection.data().componentIds] })
      );
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updateSection(sectionId, updateSection) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client, view } = cloneDeep(getState());
    const clientRef = db.collection('clients').doc(client.currentClient.id);

    try {
      await clientRef.collection('sections').doc(sectionId).set(updateSection, { merge: true });

      dispatch(slice.actions.updateSectionSuccess({ ...view.currentView.sections[sectionId], ...updateSection }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistSection(newSectionOrder) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client, view } = cloneDeep(getState());
    const viewRef = db.collection('clients').doc(client.currentClient.id).collection('views').doc(view.currentView.id);

    // TO DO: Rework this to catch if document write returns error
    dispatch(slice.actions.persistSection(newSectionOrder));

    try {
      await viewRef.set({ sectionOrder: newSectionOrder }, { merge: true });
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistComponent(newComponentOrder) {
  return async (dispatch, getState) => {
    // dispatch(slice.actions.startLoading());

    const { client } = cloneDeep(getState());
    const viewRef = db.collection('clients').doc(client.currentClient.id).collection('sections');

    // TO DO: Rework this to catch if document write returns error
    dispatch(slice.actions.persistComponent(newComponentOrder));
    try {
      const sections = { ...newComponentOrder.sections };

      Object.entries(sections).forEach(([key, value]) =>
        viewRef.doc(key).set({ componentIds: value.componentIds }, { merge: true })
      );
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addCustomComponent(componentValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client, view } = cloneDeep(getState());

    try {
      const { component, sectionId } = componentValues;
      const clientRef = db.collection(`clients`).doc(client.currentClient.id);
      const viewRef = clientRef.collection('views').doc(view.currentView.id);
      const sectionRef = clientRef.collection('sections').doc(sectionId);

      const batch = db.batch();

      // Update View Document
      batch.set(
        viewRef,
        {
          componentIcons: arrayUnion({
            icon: component.icon,
            iconColor: component.iconColor
          }),
          componentIds: arrayUnion(component.id)
        },
        { merge: true }
      );

      // Update Section Document
      batch.set(
        sectionRef,
        {
          componentIds: arrayUnion(component.id)
        },
        { merge: true }
      );

      await batch.commit();

      dispatch(slice.actions.addComponentSuccess({ component: { ...component, id: component.id }, sectionId }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addComponent(componentValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client, view } = cloneDeep(getState());

    try {
      const { newComponent, sectionId } = componentValues;
      const clientRef = db.collection(`clients`).doc(client.currentClient.id);
      const viewRef = clientRef.collection('views').doc(view.currentView.id);
      const componentRef = clientRef.collection('components').doc();
      const sectionRef = clientRef.collection('sections').doc(sectionId);

      const batch = db.batch();

      // Component Document Create
      batch.set(componentRef, {
        ...newComponent,
        id: componentRef.id,
        updatedAt: serverTimestamp(),
        createdAt: serverTimestamp()
      });

      // Update View Document
      batch.set(
        viewRef,
        {
          componentIcons: arrayUnion({
            icon: newComponent.icon,
            iconColor: newComponent.iconColor
          }),
          componentIds: arrayUnion(componentRef.id)
        },
        { merge: true }
      );

      // Update Section Document
      batch.set(
        sectionRef,
        {
          componentIds: arrayUnion(componentRef.id)
        },
        { merge: true }
      );

      await batch.commit();

      dispatch(slice.actions.addComponentSuccess({ component: { ...newComponent, id: componentRef.id }, sectionId }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateComponent(componentId, updatedComponentValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client } = cloneDeep(getState());
    const componentRef = db
      .collection('clients')
      .doc(client.currentClient.id)
      .collection('components')
      .doc(componentId);

    try {
      await componentRef.set({ customName: updatedComponentValues.customName }, { merge: true });
      dispatch(slice.actions.updateComponentSuccess(updatedComponentValues));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function deleteComponent(deletedValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client, view } = cloneDeep(getState());
    const { sectionId, component } = deletedValues;
    const componentId = component.id;

    try {
      const clientRef = db.collection('clients').doc(client.currentClient.id);
      const componentRef = clientRef.collection('components').doc(componentId);
      const sectionRef = clientRef.collection('sections').doc(sectionId);
      const viewRef = clientRef.collection('views').doc(view.currentView.id);

      const batch = db.batch();

      // Update View Document
      batch.set(
        viewRef,
        {
          componentIcons: arrayRemove({
            icon: component.icon,
            iconColor: component.iconColor
          }),
          componentIds: arrayRemove(componentId)
        },
        { merge: true }
      );

      // Update Section Document
      batch.set(
        sectionRef,
        {
          componentIds: arrayRemove(componentId)
        },
        { merge: true }
      );
      await batch.commit();

      // Finally Delete Component
      await componentRef.delete();

      dispatch(slice.actions.deleteComponentSuccess(deletedValues));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
