import { cloneDeep, pullAt, omit } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// firebase
import { db, fieldValue, fromDate } from 'config/firebase';
// utils
import { formatDate, convertTimestampToTimezone, getUnix } from 'utils/date-utils';
import { RRule } from 'rrule';

// ----------------------------------------------------------------------

function getEventIndexById(array, filterValue) {
  return array.indexOf(array.filter((item) => item.id === filterValue)[0]);
}

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  events: [],
  calendars: [],
  calendarList: [],
  isOpenModal: false,
  selectedEventId: '',
  selectedRange: null
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // IS SUCCESS
    isSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // CREATE RECURRING EVENTS SUCCESS
    createRecurEventSuccess(state, action) {
      const newEvents = action.payload;
      state.isLoading = false;
      state.events = [...state.events, ...newEvents];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },

    // GET CALENDARS
    getCalendarsSuccess(state, action) {
      state.isLoading = false;
      state.calendars = action.payload.calendars;
      state.calendarList = action.payload.calendarList;
    },

    // CREATE CALENDARS
    getCreateCalendarSuccess(state, action) {
      state.isLoading = false;
      state.calendars = [...state.calendars, action.payload];
      state.calendarList = [...state.calendarList, action.payload];
    },

    // UPDATE CALENDARS
    getUpdateCalendarSuccess(state, action) {
      state.isLoading = false;

      const calendarIndex = state.calendars.findIndex((calendar) => calendar.id === action.payload.id);
      state.calendars[calendarIndex] = action.payload;
      const calendarListIndex = state.calendarList.findIndex((calendar) => calendar.id === action.payload.id);
      state.calendarList[calendarListIndex] = action.payload;

      for (const event of state.events) {
        for (const id of action.payload.eventIds) {
          if (event.id === id) {
            event.textColor = action.payload.groupColor;
          }
        }
      }
    },

    // DELETE CALENDARS
    getDeleteCalendarSuccess(state, action) {
      state.isLoading = false;

      state.calendars = state.calendars.filter((calendar) => calendar.id !== action.payload.calendarId);
      state.calendarList = state.calendarList.filter((calendar) => calendar.id !== action.payload.calendarId);

      for (const event of state.events) {
        for (const id of action.payload.eventIds) {
          if (event.id === id) {
            event.calendars[0] = 'unassigned';
            event.textColor = '#E6E6E6';
          }
        }
      }
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent, updateCalendarUI } = slice.actions;

// ----------------------------------------------------------------------

export function getEvents() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client } = cloneDeep(getState());
    const { currentClient } = client;

    try {
      const eventsCollection = db.collection('clients').doc(currentClient.id).collection('events');
      const getEvents = await eventsCollection.get();
      const events = [];
      getEvents.forEach(async (item) => {
        const event = item.data();

        const { start, end, timeZone } = event;
        const startDate = new Date(start?.seconds * 1000);
        const endDate = new Date(end?.seconds * 1000);

        const eventObject = {
          ...event,
          start: startDate,
          end: endDate,
          ...(event?.rrule && {
            rrule: {
              ...event?.rrule,
              ...(event?.rrule?.dtstart && { dtstart: new Date(event?.rrule?.dtstart?.seconds * 1000) }),
              ...(event?.rrule?.until && { until: new Date(event?.rrule?.until?.seconds * 1000) }),
              ...(event?.rrule?.byweekday && {
                byweekday: event?.rrule?.byweekday.map((day) => day + 1)
              })
            }
          }),
          editedValues: {
            startTime: convertTimestampToTimezone(getUnix(startDate), timeZone, 'HH:mm'),
            endTime: convertTimestampToTimezone(getUnix(endDate), timeZone, 'HH:mm')
          }
        };

        return events.push(eventObject);
      });

      dispatch(slice.actions.getEventsSuccess(events));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

async function deleteEventNotifications(eventDocsToDelete, eventIds, batch) {
  const notifCollectionRef = db.collection('notifications');

  if (eventIds.length > 0) {
    const notificationsToDelete = []; // firestore where method 'in' query has a 10 value limit
    const eventIdsChunks = [];
    for (let i = 0; i < eventIds.length; i += 10) {
      eventIdsChunks.push(eventIds.slice(i, i + 10));
    }
    const promises = [];

    for (let i = 0; i < eventIdsChunks.length; i++) {
      eventIdsChunks[i].forEach(() => {
        promises.push(
          notifCollectionRef
            .where('eventId', 'in', eventIdsChunks[i])
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                notificationsToDelete.push(doc.data());
              });
            })
        );
      });
    }
    Promise.all(promises)
      // .then is being used here because the nested for and forEach loops are completing before promises are resolved and notificationsToDelete is empty
      .then(() => {
        eventDocsToDelete.forEach((event) => {
          // const eventIndex = getEventIndexById(events, event.id);
          // pullIndexes.push(eventIndex);
          if (notificationsToDelete.length > 0) {
            notificationsToDelete.forEach((notification) => {
              if (notification.eventId === event.id) {
                batch.delete(notifCollectionRef.doc(notification.id));
              }
            });
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

// ----------------------------------------------------------------------

export function createEvent(newEvent) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { client } = cloneDeep(getState());
    const { currentClient } = client;
    const rule = newEvent.rrule && new RRule(newEvent.rrule);

    const { date, startTime, endTime, timeZone, recurrence, notification } = newEvent;

    const eventCollectionRef = db.collection('clients').doc(currentClient.id).collection('events');
    const eventDocRef = eventCollectionRef.doc();
    const timeZoneString = convertTimestampToTimezone(getUnix(new Date(date)), timeZone, 'ZZ');

    const start = new Date(`${date}T${startTime}:00.000${timeZoneString}`);
    const end = new Date(`${date}T${endTime}:00.000${timeZoneString}`);

    const createdEvents = [];

    try {
      let notificationObject = null;
      if (notification) {
        try {
          const notificationRef = db.collection('notifications').doc();

          const { notifDate, notifTime, title, notifMessage } = newEvent;

          const timeZoneString = formatDate(new Date(notifDate), 'ZZ');
          const notificationDate = new Date(`${notifDate}T${notifTime}:00.000${timeZoneString}`);

          notificationObject = {
            status: 'scheduled',
            clientId: currentClient.id,
            id: notificationRef.id,
            message: notifMessage,
            title,
            performAt: fromDate(notificationDate),
            eventId: eventDocRef.id
          };
          await notificationRef.set({ ...notificationObject });
        } catch (error) {
          dispatch(slice.actions.hasError(error));
          console.log(error);
        }
      }

      const fbEventObject = {
        ...omit(newEvent, ['date', 'startTime', 'endTime']),
        clientId: currentClient.id,
        id: eventDocRef.id,
        start,
        end,
        editedValues: {
          start: fromDate(start),
          end: fromDate(end),
          startTime,
          endTime
        },
        ...(recurrence !== 'no-repeat' && { recurringEventId: null })
      };

      await eventDocRef.set(fbEventObject);
      createdEvents.push(fbEventObject);

      if (recurrence !== 'no-repeat') {
        const batch = db.batch();
        let _recurrences = [];

        const recurringDateInstances = rule.all();

        _recurrences = recurringDateInstances.map((dateInstance) => {
          const start = new Date(`${dateInstance.toISOString().split('T')[0]}T${startTime}`);
          const end = new Date(`${dateInstance.toISOString().split('T')[0]}T${endTime}`);
          return { start, end };
        });

        _recurrences.shift();

        const recurrenceWrites = _recurrences.map((recurrenceDates) => ({
          ...omit(newEvent, ['date', 'notifDate', 'notifMessage', 'notifTime', 'startTime', 'endTime']),
          ...recurrenceDates,
          recurringEventId: fbEventObject.id,
          editedValues: {
            ...recurrenceDates,
            startTime,
            endTime
          },
          notification: false
        }));

        recurrenceWrites.forEach((doc) => {
          const recurrenceRef = eventCollectionRef.doc();
          doc.id = recurrenceRef.id;

          batch.set(recurrenceRef, {
            ...doc,
            clientId: currentClient.id,
            id: recurrenceRef.id,
            start: doc.start,
            end: doc.end,
            editedValues: {
              start: fromDate(doc.start),
              end: fromDate(doc.end)
            }
          });
        });
        batch.commit();

        createdEvents.push(...recurrenceWrites);
      }
      dispatch(slice.actions.createRecurEventSuccess(createdEvents));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(updateEvent, scope) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client, calendar } = cloneDeep(getState());
    const { currentClient } = client;
    const { events } = calendar;
    const eventIndex = events.findIndex((event) => event.id === updateEvent.id);
    const curEvent = events[eventIndex];
    const rule = updateEvent.rrule && new RRule(updateEvent.rrule);

    const eventCollectionRef = db.collection('clients').doc(currentClient.id).collection('events');
    const eventDocRef = eventCollectionRef.doc(updateEvent.id);

    const { date, startTime, endTime, timeZone, notification } = updateEvent;

    let { recurringEventId } = updateEvent;

    const changeFromNoRepeatToRecurr = curEvent.recurrence === 'no-repeat' && updateEvent.recurrence !== 'no-repeat';

    const changeToNoRepeat = curEvent.recurrence !== 'no-repeat' && updateEvent.recurrence === 'no-repeat';

    const { changeInRecurrenceType } = updateEvent;

    if (typeof recurringEventId === 'undefined' && changeFromNoRepeatToRecurr) {
      recurringEventId = null;
    }

    let parentRecurrEventRef = null;
    const isRecurrParentEvent =
      (updateEvent.recurringEventId === null && updateEvent.rrule !== null) || changeFromNoRepeatToRecurr;

    if (isRecurrParentEvent) {
      parentRecurrEventRef = eventCollectionRef.doc(updateEvent.id);
    } else if (updateEvent.recurringEventId !== null) {
      const _parentDoc = await eventCollectionRef.doc(recurringEventId).get();
      if (_parentDoc.exists) {
        parentRecurrEventRef = eventCollectionRef.doc(recurringEventId);
      } else {
        parentRecurrEventRef = null;
      }
    }

    const timeZoneString = convertTimestampToTimezone(getUnix(new Date()), timeZone, 'ZZ');

    const start = new Date(`${date}T${startTime}:00.000`);
    const end = new Date(`${date}T${endTime}:00.000`);

    let fbEventObject = null;
    try {
      let fbNotificationObject = null;
      const removingNotification = curEvent.notification && !notification;
      // remove notification from firestore and update the event
      if (removingNotification) {
        const notifCollectionRef = db.collection('notifications');
        try {
          await notifCollectionRef
            .where('eventId', '==', updateEvent.id)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const curNotification = doc.data();
                db.doc(`notifications/${curNotification.id}`).delete();
              });
            });

          // update event
          await eventDocRef.update({
            notification: false,
            notifDate: fieldValue.delete(),
            notifTime: fieldValue.delete(),
            notifMessage: fieldValue.delete()
          });
        } catch (error) {
          console.log(error);
        }
      }

      let notificationRef = null;
      const notifCollectionRef = db.collection('notifications');
      // update the current event's notification, adding one if there wasn't one before
      if (notification) {
        try {
          const addingNotification = !curEvent.notification && notification;

          // adding a new notification to the event
          if (addingNotification) {
            notificationRef = db.collection('notifications').doc();
            const { notifDate, notifTime, title, notifMessage, id } = updateEvent;
            const timeZoneString = formatDate(new Date(notifDate), 'ZZ');
            const notificationDate = new Date(`${notifDate}T${notifTime}:00.000${timeZoneString}`);
            fbNotificationObject = {
              status: 'scheduled',
              clientId: currentClient.id,
              id: notificationRef.id,
              message: notifMessage,
              title,
              performAt: fromDate(notificationDate),
              eventId: id
            };
          } else {
            // updating an existing notification
            await notifCollectionRef
              .where('eventId', '==', updateEvent.id)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const curNotification = doc.data();
                  notificationRef = db.doc(`notifications/${curNotification.id}`);
                  const { notifDate, notifTime, title, notifMessage } = updateEvent;
                  const timeZoneString = formatDate(new Date(notifDate), 'ZZ');
                  const newNotifDate = new Date(`${notifDate}T${notifTime}:00.000${timeZoneString}`);
                  const curNotificationDate = new Date(curNotification.performAt.seconds * 1000);

                  fbNotificationObject = {
                    ...curNotification,
                    message: notifMessage,
                    title,
                    performAt: fromDate(newNotifDate),
                    ...(newNotifDate > curNotificationDate && { status: 'scheduled' })
                  };
                });
              });
          }

          // send updated notification to notification collection
          await notificationRef.set(fbNotificationObject, { merge: true });
        } catch (error) {
          console.log(error);
        }
      }

      fbEventObject = {
        ...omit(updateEvent, ['date', 'startTime', 'endTime']),
        start: fromDate(start),
        end: fromDate(end),
        editedValues: {
          start: fromDate(start),
          end: fromDate(end),
          startTime,
          endTime
        },
        ...(isRecurrParentEvent && { recurringEventId: null })
      };

      // UPDATE A SINGLE EVENT TO A RECURRING EVENT
      if (changeFromNoRepeatToRecurr) {
        console.log('going from no repeat to recurring');
        const batch = db.batch();
        // scope being set to null makes it so we don't enter into the if statement below
        scope = null;

        // update original updated event in firestore and add to events array to send to redux state
        await eventDocRef.set(fbEventObject, { merge: true });
        const eventIndex = events.findIndex((event) => event.id === updateEvent.id);
        events[eventIndex] = {
          ...fbEventObject,
          start,
          end
        };

        let _recurrences = [];

        const recurringDateInstances = rule.all();

        _recurrences = recurringDateInstances.map((dateInstance) => {
          const start = new Date(`${dateInstance.toISOString().split('T')[0]}T${startTime}`);
          const end = new Date(`${dateInstance.toISOString().split('T')[0]}T${endTime}`);
          return { start, end };
        });

        _recurrences.shift();

        const recurrenceWrites = _recurrences.map((recurrenceDates) => ({
          ...omit(fbEventObject, ['date', 'startTime', 'endTime', 'rrule', 'notifDate', 'notifMessage', 'notifTime']),
          ...recurrenceDates,
          recurringEventId: fbEventObject.id,
          editedValues: {
            ...recurrenceDates,
            startTime,
            endTime
          },
          notification: false
        }));

        recurrenceWrites.forEach((doc) => {
          const recurrenceRef = eventCollectionRef.doc();

          batch.set(recurrenceRef, {
            ...doc,
            id: recurrenceRef.id,
            start: fromDate(doc.start),
            end: fromDate(doc.end),
            editedValues: {
              start: fromDate(start),
              end: fromDate(end),
              startTime,
              endTime
            }
          });
          events.push({
            ...doc,
            start: doc.start,
            end: doc.end,
            id: recurrenceRef.id,
            editedValues: {
              start,
              end,
              startTime,
              endTime
            }
          });
        });

        batch.commit();
      }

      // DELETE AND REPLACE EVENTS WITH NEW RECURRENCES
      if (changeInRecurrenceType) {
        console.log('changing recurrence type');
        const batch = db.batch();
        const eventDocsToDelete = [];
        const pullIndexes = [];

        const collectionGroupRef = db
          .collectionGroup('events')
          .where('recurringEventId', '==', recurringEventId !== null ? recurringEventId : updateEvent.id);

        if (scope === 'future') {
          // delete the events from that date forward
          if (isRecurrParentEvent && parentRecurrEventRef) {
            console.log('parent event selected');
            await parentRecurrEventRef.get().then((doc) => eventDocsToDelete.push(doc.data()));
          }
          const eventGroup = await collectionGroupRef.orderBy('start').startAt(start).get();
          eventGroup.forEach((doc) => eventDocsToDelete.push(doc.data()));
        }
        // if all is selected, delete all events and create new ones using the updated event's rule keeping the extended props and titles
        if (scope === 'all') {
          console.log('scope is all');
          // delete the events to replace them
          if (parentRecurrEventRef) {
            await parentRecurrEventRef.get().then((doc) => eventDocsToDelete.push(doc.data()));
          }
          const eventGroup = await collectionGroupRef.get();
          eventGroup.forEach((doc) => {
            eventDocsToDelete.push(doc.data());
          });
        }

        // LOOP THROUGH EVENTS LOOKING FOR NOTIFICATIONS TO DELETE, ELSE DELETE THE EVENTS
        const eventIds = [];
        eventDocsToDelete.forEach((doc) => {
          if (doc.notification) {
            eventIds.push(doc.id);
          }
        });
        if (eventIds.length > 0) {
          await deleteEventNotifications(eventIds, batch, eventCollectionRef, events);
        } else {
          eventDocsToDelete.forEach((event) => {
            const eventIndex = getEventIndexById(events, event.id);
            pullIndexes.push(eventIndex);
            batch.delete(eventCollectionRef.doc(event.id));
          });

          pullAt(events, pullIndexes);
        }

        // CREATE THE NEW RECURRING SET OF EVENTS
        let _recurrences = [];

        let recurringDateInstances = null;
        if (scope === 'future') {
          // recurringDateInstances = rule.after(new Date(start), true);
          recurringDateInstances = rule.between(new Date(start), rule.all()[rule.all().length - 1], true);
          // push the fbEventObject into the array of recurrences
          recurringDateInstances.unshift(new Date(`${date}T00:00:00.000${timeZoneString}`));
          console.log('inside future scope, dates look like => ', recurringDateInstances);
        } else {
          recurringDateInstances = rule.all();
        }

        _recurrences = recurringDateInstances.map((dateInstance) => {
          const start = new Date(`${dateInstance.toISOString().split('T')[0]}T${startTime}`);
          const end = new Date(`${dateInstance.toISOString().split('T')[0]}T${endTime}`);
          return { start, end };
        });

        const recurrenceWrites = _recurrences.map((recurrenceDates) => ({
          ...omit(updateEvent, [
            'date',
            'notifDate',
            'notifMessage',
            'notifTime',
            'startTime',
            'endTime',
            'changeInRecurrenceType'
          ]),
          ...recurrenceDates,
          recurringEventId: fbEventObject.id,
          editedValues: {
            ...recurrenceDates,
            startTime,
            endTime
          },
          notification: false
        }));

        recurrenceWrites.forEach((doc) => {
          const recurrenceRef = eventCollectionRef.doc();
          doc.id = recurrenceRef.id;

          batch.set(recurrenceRef, {
            ...doc,
            clientId: currentClient.id,
            id: recurrenceRef.id,
            start: doc.start,
            end: doc.end,
            editedValues: {
              start: fromDate(doc.start),
              end: fromDate(doc.end)
            }
          });
        });

        batch.commit();
        events.push(...recurrenceWrites);
      } else if (changeToNoRepeat) {
        const batch = db.batch();
        const eventDocsToDelete = [];
        const pullIndexes = [];

        const collectionGroupRef = db
          .collectionGroup('events')
          .where('recurringEventId', '==', recurringEventId !== null ? recurringEventId : updateEvent.id);

        if (!isRecurrParentEvent && parentRecurrEventRef) {
          console.log('grabbing the parent event');
          await parentRecurrEventRef.get().then((doc) => eventDocsToDelete.push(doc.data()));
        }

        const eventGroup = await collectionGroupRef.get();
        eventGroup.forEach((doc) => {
          if (doc.id !== updateEvent.id) {
            eventDocsToDelete.push(doc.data());
          }
        });

        // LOOP THROUGH EVENTS LOOKING FOR NOTIFICATIONS TO DELETE, ELSE DELETE THE EVENTS
        const eventIds = [];
        eventDocsToDelete.forEach((doc) => {
          if (doc.notification) {
            eventIds.push(doc.id);
          }
        });
        if (eventIds.length > 0) {
          await deleteEventNotifications(eventDocsToDelete, eventIds, batch, eventCollectionRef, events);
        } else {
          eventDocsToDelete.forEach((event) => {
            const eventIndex = getEventIndexById(events, event.id);
            pullIndexes.push(eventIndex);
            batch.delete(eventCollectionRef.doc(event.id));
          });

          pullAt(events, pullIndexes);
          batch.commit();
        }
      } else {
        // UPDATE EVENTS ACCORDING TO SCOPE WITHOUT NEW RECURRENCES
        if (scope === 'only') {
          // Creates Recurring Events
          // TO DO: Move logic to be triggered and ran in Cloud Function
          await eventDocRef.set(fbEventObject, { merge: true });
          const eventIndex = events.findIndex((event) => event.id === updateEvent.id);
          events[eventIndex] = {
            ...fbEventObject,
            start,
            end
          };
        }

        if (scope === 'future' || scope === 'all') {
          const reduxEventIndex = events.findIndex((event) => event.id === updateEvent.id);

          const batch = db.batch();
          const docsToUpdate = [];

          const collectionGroupRef = db
            .collectionGroup('events')
            .where('recurringEventId', '==', recurringEventId !== null ? recurringEventId : updateEvent.id);
          let parentRecurrEventRef = null;
          if (isRecurrParentEvent) {
            parentRecurrEventRef = eventCollectionRef.doc(updateEvent.id);
          } else if (updateEvent.recurringEventId !== null) {
            const _parentDoc = await eventCollectionRef.doc(recurringEventId).get();
            if (_parentDoc.exists) {
              parentRecurrEventRef = eventCollectionRef.doc(recurringEventId);
            } else {
              parentRecurrEventRef = null;
            }
          }

          // this and future events
          if (scope === 'future') {
            if (parentRecurrEventRef && isRecurrParentEvent) {
              await parentRecurrEventRef.get().then((doc) => docsToUpdate.push(doc.data()));
            }
            const collectionGroup = await collectionGroupRef
              .orderBy('start')
              .startAt(events[reduxEventIndex].start)
              .get();
            collectionGroup.forEach((doc) => docsToUpdate.push(doc.data()));
          }

          // all events
          if (scope === 'all') {
            if (parentRecurrEventRef) {
              await parentRecurrEventRef.get().then((doc) => docsToUpdate.push(doc.data()));
            }

            const collectionGroup = await collectionGroupRef.get();
            collectionGroup.forEach((doc) => {
              docsToUpdate.push(doc.data());
            });
          }

          docsToUpdate.forEach((doc) => {
            const reduxEventIndex = events.findIndex((event) => event.id === doc.id);
            const date = formatDate(new Date(doc.start.seconds * 1000), 'YYYY-MM-DD');
            const timeZoneString = convertTimestampToTimezone(getUnix(new Date(date)), timeZone, 'ZZ');
            const _start = new Date(`${date}T${startTime}:00.000${timeZoneString}`);
            const _end = new Date(`${date}T${endTime}:00.000${timeZoneString}`);

            // update the selected event in firestore and add to events array to send to redux state
            if (doc.id === updateEvent.id) {
              events[reduxEventIndex] = {
                ...fbEventObject,
                id: doc.id,
                recurringEventId: doc.recurringEventId,
                start: _start,
                end: _end
              };
              batch.set(eventCollectionRef.doc(doc.id), {
                ...fbEventObject,
                start: fromDate(_start),
                end: fromDate(_end),
                id: doc.id,
                recurringEventId: doc.recurringEventId
              });
            }
            // if the current document has a notification, update the event's data model in redux state and firestore, notification creation/update has already been handled above
            else if (doc.notification) {
              events[reduxEventIndex] = {
                ...omit(fbEventObject, ['notifDate', 'notifMessage', 'notifTime']),
                id: doc.id,
                recurringEventId: doc.recurringEventId,
                start: _start,
                end: _end,
                notification: doc.notification,
                notifDate: doc.notifDate,
                notifMessage: doc.notifMessage,
                notifTime: doc.notifTime
              };

              batch.set(eventCollectionRef.doc(doc.id), {
                ...omit(fbEventObject, ['notifDate', 'notifMessage', 'notifTime']),
                start: fromDate(_start),
                end: fromDate(_end),
                id: doc.id,
                recurringEventId: doc.recurringEventId,
                notification: doc.notification,
                notifDate: doc.notifDate,
                notifMessage: doc.notifMessage,
                notifTime: doc.notifTime
              });
            } else {
              // keep notifications from being added to events that do not have them
              events[reduxEventIndex] = {
                ...omit(fbEventObject, ['notifDate', 'notifMessage', 'notifTime']),
                id: doc.id,
                recurringEventId: doc.recurringEventId,
                start: _start,
                end: _end
              };

              batch.set(eventCollectionRef.doc(doc.id), {
                ...omit(fbEventObject, ['notifDate', 'notifMessage', 'notifTime']),
                start: fromDate(_start),
                end: fromDate(_end),
                id: doc.id,
                recurringEventId: doc.recurringEventId
              });
            }
          });
          batch.commit();
        }
      }

      dispatch(slice.actions.updateEventSuccess(events));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventToDelete, scope) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client, calendar } = cloneDeep(getState());
    const { currentClient } = client;
    const { events } = calendar;

    try {
      const { recurringEventId, start, notification } = eventToDelete;

      const collectionRef = db.collection('clients').doc(currentClient.id).collection('events');

      let parentRecurrEventRef = null;
      const isRecurrParentEvent =
        (eventToDelete.recurringEventId === null && eventToDelete.recurrence !== 'no-repeat') ||
        eventToDelete.rrule !== null;

      if (isRecurrParentEvent) {
        parentRecurrEventRef = collectionRef.doc(eventToDelete.id);
      } else if (eventToDelete.recurringEventId !== null) {
        const _parentDoc = await collectionRef.doc(recurringEventId).get();
        if (_parentDoc.exists) {
          parentRecurrEventRef = collectionRef.doc(recurringEventId);
        } else {
          parentRecurrEventRef = null;
        }
      }

      const notifCollectionRef = db.collection('notifications');

      if (scope === 'only') {
        if (notification) {
          try {
            await notifCollectionRef
              .where('eventId', '==', eventToDelete.id)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const curNotification = doc.data();
                  db.doc(`notifications/${curNotification.id}`).delete();
                });
              });
          } catch (error) {
            console.log(error);
          }
        }
        await collectionRef.doc(eventToDelete.id).delete();
        const deleteEvent = events.filter((curEvent) => curEvent.id !== eventToDelete.id);
        dispatch(slice.actions.deleteEventSuccess(deleteEvent));
      }

      // query for set of events to delete
      if (scope === 'future' || scope === 'all') {
        const collectionGroupRef = db
          .collectionGroup('events')
          .where('recurringEventId', '==', recurringEventId !== null ? recurringEventId : eventToDelete.id);

        const batch = db.batch();
        const eventDocsToDelete = [];
        const pullIndexes = [];

        // get set of events to delete
        if (scope === 'future') {
          if (isRecurrParentEvent && parentRecurrEventRef) {
            await parentRecurrEventRef.get().then((doc) => eventDocsToDelete.push(doc.data()));
          }

          const eventGroup = await collectionGroupRef.orderBy('start').startAt(start).get();
          eventGroup.forEach((doc) => eventDocsToDelete.push(doc.data()));
        }

        if (scope === 'all') {
          if (parentRecurrEventRef) {
            await parentRecurrEventRef.get().then((doc) => eventDocsToDelete.push(doc.data()));
          }
          const eventGroup = await collectionGroupRef.get();
          eventGroup.forEach((doc) => {
            eventDocsToDelete.push(doc.data());
          });

          pullIndexes.push(getEventIndexById(events, recurringEventId));
        }

        // -------------------------------------------------------------------

        // get event ids of events with notifications
        const eventIds = [];
        eventDocsToDelete.forEach((doc) => {
          if (doc.notification) {
            eventIds.push(doc.id);
          }
        });

        // if there are notifications to delete, query for them, else delete events
        if (eventIds.length > 0) {
          const notificationsToDelete = []; // firestore where method 'in' query has a 10 value limit
          const eventIdsChunks = [];
          for (let i = 0; i < eventIds.length; i += 10) {
            eventIdsChunks.push(eventIds.slice(i, i + 10));
          }
          const promises = [];

          for (let i = 0; i < eventIdsChunks.length; i++) {
            eventIdsChunks[i].forEach(() => {
              promises.push(
                notifCollectionRef
                  .where('eventId', 'in', eventIdsChunks[i])
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      notificationsToDelete.push(doc.data());
                    });
                  })
              );
            });
          }
          Promise.all(promises)
            // .then is being used here because the nested for and forEach loops are completing before promises are resolved and notificationsToDelete is empty
            .then(() => {
              eventDocsToDelete.forEach((event) => {
                const eventIndex = getEventIndexById(events, event.id);
                pullIndexes.push(eventIndex);
                if (notificationsToDelete.length > 0) {
                  notificationsToDelete.forEach((notification) => {
                    if (notification.eventId === event.id) {
                      batch.delete(notifCollectionRef.doc(notification.id));
                    }
                  });
                }
                batch.delete(collectionRef.doc(event.id));
              });

              pullAt(events, pullIndexes);
              dispatch(slice.actions.deleteEventSuccess(events));
              batch.commit();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          eventDocsToDelete.forEach((event) => {
            const eventIndex = getEventIndexById(events, event.id);
            pullIndexes.push(eventIndex);
            batch.delete(collectionRef.doc(event.id));
          });

          pullAt(events, pullIndexes);
          dispatch(slice.actions.deleteEventSuccess(events));
          batch.commit();
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function selectRange(start, end) {
  return async (dispatch) => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime()
      })
    );
  };
}

// CALENDARS
// ----------------------------------------------------------------------

export function getCalendars(clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await db.collection(`clients/${clientId}/calendars`).get();
      const calendars = [];
      const calendarList = [];
      response.forEach((calendar) => {
        calendars.push(calendar.data());
        if (calendar.data().name !== 'Unassigned') {
          calendarList.push(calendar.data());
        }
      });
      dispatch(slice.actions.getCalendarsSuccess({ calendars, calendarList }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCalendars(values, clientId, calendarId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const calendarRef = db.collection(`clients/${clientId}/calendars`).doc(calendarId);
      await calendarRef.set({ ...values }, { merge: true });

      const eventsCollectionRef = await db.collection(`clients/${clientId}/events`);
      const response = await eventsCollectionRef.get();
      const events = [];
      const ids = [];
      response.forEach((eventObj) => {
        const event = eventObj.data();
        if (event.calendars) {
          if (event.calendars[0] === `${calendarId}`) {
            events.push(event);
            ids.push(event.id);
          }
        }
      });
      const batch = db.batch();
      for (const event of events) {
        const eventRef = eventsCollectionRef.doc(event.id);
        batch.set(eventRef, { textColor: values.groupColor }, { merge: true });
      }
      batch.commit();
      dispatch(slice.actions.getUpdateCalendarSuccess({ ...values, eventIds: ids, id: calendarId }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createCalendar(values, clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const { id } = values;
    try {
      const calendarRef = db.collection(`clients/${clientId}/calendars`).doc(id);
      const firebaseObject = {
        ...values,
        eventIds: []
      };
      await calendarRef.set(firebaseObject, { merge: true });

      dispatch(slice.actions.getCreateCalendarSuccess(firebaseObject));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCalendar(calendar, clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const { id } = calendar;
    try {
      await db.doc(`clients/${clientId}/calendars/${id}`).delete();
      const eventsCollectionRef = await db.collection(`clients/${clientId}/events`);
      const response = await eventsCollectionRef.get();
      const events = [];
      const ids = [];
      response.forEach((eventObj) => {
        const event = eventObj.data();
        if (event.calendars) {
          if (event.calendars[0] === `${id}`) {
            events.push(event);
            ids.push(event.id);
          }
        }
      });
      const batch = db.batch();
      for (const event of events) {
        const eventRef = eventsCollectionRef.doc(event.id);
        batch.set(eventRef, { calendars: ['unassigned'] }, { merge: true });
        batch.set(eventRef, { textColor: '#e6e6e6' }, { merge: true });
      }
      batch.commit();
      dispatch(slice.actions.getDeleteCalendarSuccess({ eventIds: ids, calendarId: id }));
      dispatch(slice.actions.isSuccess('Calendar delete success'));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
