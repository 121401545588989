import {
  LogoDefault,
  LogoMymoose,
  LogoMyelks,
  LogoMyfoe,
  LogoMyVfw,
  LogoMyPost,
  LogoMyScottsdale,
  LogoNordecke,
  LogoMVNU,
  LogoStanislaus
} from './index';

export default function Logo() {
  const containerId = process.env.REACT_APP_CONTAINER_ID;
  switch (containerId) {
    case 'MYELKS':
      return <LogoMyelks />;
    case 'MYMOOSE':
      return <LogoMymoose />;
    case 'MYVFW':
      return <LogoMyVfw />;
    case 'MYFOE':
      return <LogoMyfoe />;
    case 'MYPOST':
      return <LogoMyPost />;
    case 'MYSCOTTSDALE':
      return <LogoMyScottsdale />;
    case 'MYLEGION':
      return <LogoDefault />;
    case 'NORDECKE':
      return <LogoNordecke />;
    case 'MVNU':
      return <LogoMVNU />;
    case 'Stanislaus':
      return <LogoStanislaus />;
    default:
      return <LogoDefault />;
  }
}
