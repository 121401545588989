import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

LogoMyPost.propTypes = {
  sx: PropTypes.object
};

export default function LogoMyPost({ sx }) {
  return (
    <Box
      sx={{
        width: 65,
        maxHeight: 'auto',
        cursor: 'pointer',
        ...sx
      }}
    >
      <svg version="1.1" id="Layer_1" x="0px" y="0px" width="100%" viewBox="0 0 2666 2659">
        <path
          fill="#FFFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M1.999996,2496.000000 
	C2.000000,1664.858032 2.000000,833.715942 2.000000,2.000000 
	C394.718170,2.000000 787.436584,2.000000 1181.211304,2.929268 
	C1182.267578,134.388092 1182.267578,264.917633 1182.267578,395.453918 
	C1354.057251,395.453918 1524.514404,395.453918 1695.681885,395.453918 
	C1695.681885,390.839874 1695.682007,386.897400 1695.682007,382.954926 
	C1695.682007,259.963135 1695.678955,136.971329 1695.695312,13.979527 
	C1695.695801,9.986338 1695.893921,5.993175 1696.000000,2.000000 
	C1709.385010,2.000000 1722.770020,2.000000 1737.208740,2.929652 
	C1738.262451,86.324974 1738.262451,168.790649 1738.262451,251.324127 
	C1816.301758,251.324127 1893.032715,251.324127 1970.308716,251.324127 
	C1970.308716,247.354828 1970.308716,244.058380 1970.308716,240.761932 
	C1970.308716,164.498886 1970.312744,88.235855 1970.295776,11.972819 
	C1970.295044,8.648524 1970.102905,5.324272 1970.000000,2.000000 
	C2202.604980,2.000000 2435.210205,2.000000 2668.000000,2.000000 
	C2668.000000,832.718323 2668.000000,1663.436646 2667.070801,2495.112793 
	C2657.512939,2496.070801 2648.884033,2496.070801 2639.481934,2496.070801 
	C2634.654541,2521.198486 2629.908203,2545.903076 2625.162109,2570.607910 
	C2624.799805,2570.634521 2624.437500,2570.660889 2624.075195,2570.687500 
	C2619.309570,2546.070312 2614.544189,2521.453125 2609.746338,2496.669434 
	C2599.732422,2496.669434 2589.908936,2496.669434 2580.259277,2496.669434 
	C2580.259277,2550.722656 2580.259277,2604.180908 2580.259277,2657.372559 
	C2587.996338,2657.372559 2595.102295,2657.372559 2602.754883,2657.372559 
	C2602.754883,2625.075439 2602.754883,2593.507324 2602.754883,2561.939209 
	C2603.312988,2561.882568 2603.871338,2561.826172 2604.429443,2561.769531 
	C2609.258789,2593.680664 2614.087891,2625.591553 2618.941895,2657.666016 
	C2622.888184,2657.666016 2626.429932,2657.666016 2630.192871,2657.666016 
	C2635.065186,2625.343262 2639.878418,2593.412109 2644.691650,2561.480957 
	C2645.286621,2561.532959 2645.881836,2561.584961 2646.476807,2561.637207 
	C2646.476807,2593.523682 2646.476807,2625.409912 2646.476807,2658.000000 
	C2654.156006,2658.000000 2661.078125,2658.000000 2668.000000,2658.000000 
	C2668.000000,2659.328125 2668.000000,2660.656494 2668.000000,2662.000000 
	C2596.614990,2662.000000 2525.229980,2662.000000 2453.048828,2661.069824 
	C2452.148926,2605.820801 2452.046143,2551.502197 2451.916016,2497.183350 
	C2451.915283,2496.920898 2451.536377,2496.653076 2451.320557,2496.399170 
	C2451.110107,2496.151367 2450.882324,2495.918701 2450.192627,2495.168701 
	C2447.011475,2495.168701 2443.360107,2495.168701 2439.708496,2495.168701 
	C1630.763916,2495.168701 821.819397,2495.167725 12.874860,2495.203125 
	C9.249892,2495.203125 5.624947,2495.722900 1.999996,2496.000000 
M1157.666504,2011.000000 
	C1157.666504,1802.730347 1157.630981,1594.460815 1157.729492,1386.191162 
	C1157.738037,1368.232178 1156.721313,1350.402710 1154.338745,1332.612061 
	C1147.709229,1283.112305 1131.552856,1237.149414 1103.270142,1195.792358 
	C1082.042725,1164.751953 1054.909302,1140.174194 1021.024597,1123.535034 
	C988.252319,1107.442139 953.090393,1100.889526 916.954956,1100.365356 
	C898.783752,1100.101685 880.407227,1101.614746 862.405945,1104.251709 
	C800.606262,1113.304810 751.390198,1143.171387 716.651733,1195.582520 
	C680.615845,1249.951172 662.851135,1310.460693 662.642090,1375.108276 
	C661.867432,1614.698975 662.313538,1854.293579 662.390198,2093.886719 
	C662.394226,2106.496338 663.276306,2119.103760 663.649536,2131.715088 
	C664.349487,2155.364502 668.127686,2178.580566 674.318604,2201.338135 
	C688.246521,2252.536133 710.998535,2298.769775 750.941040,2335.152588 
	C776.274963,2358.228760 805.416870,2374.028076 838.763245,2382.089600 
	C869.390015,2389.493408 900.392517,2391.292969 931.780151,2389.420654 
	C956.162231,2387.966309 980.124084,2384.205078 1002.831909,2375.321533 
	C1058.398438,2353.583984 1097.479858,2313.748779 1123.876465,2261.124512 
	C1147.838257,2213.354248 1157.654175,2162.187500 1157.665283,2108.970459 
	C1157.671997,2076.980225 1157.666504,2044.990112 1157.666504,2011.000000 
M200.330902,2011.000000 
	C200.330902,1964.825684 200.330902,1918.651245 200.330902,1872.228638 
	C236.072845,1871.320801 269.931305,1867.368408 303.007385,1857.659668 
	C349.469360,1844.021851 390.627167,1821.080688 425.508820,1787.494263 
	C462.991943,1751.403442 487.556549,1707.238770 503.312531,1658.057007 
	C518.919189,1609.341309 525.114319,1559.054199 526.259888,1508.103638 
	C527.059143,1472.557861 525.362915,1437.106445 520.323608,1401.856323 
	C513.599670,1354.822632 501.278290,1309.507202 479.026093,1267.262573 
	C454.945251,1221.546265 421.532104,1184.509766 376.926971,1157.903931 
	C326.807587,1128.009033 271.780029,1115.479492 214.254822,1114.370850 
	C150.617325,1113.144531 86.937943,1114.090454 23.276808,1114.090454 
	C20.362417,1114.090454 17.448027,1114.090576 14.119776,1114.090576 
	C14.119776,1535.075684 14.119776,1955.170288 14.119776,2375.473145 
	C76.346985,2375.473145 138.121704,2375.473145 200.330917,2375.473145 
	C200.330917,2254.240479 200.330917,2133.620117 200.330902,2011.000000 
M14.329695,426.999908 
	C14.329695,604.158020 14.329695,781.316101 14.329695,958.845581 
	C58.210693,958.845581 100.737236,958.845581 144.329620,958.845581 
	C144.329620,745.622986 144.329620,532.845581 144.329620,320.248169 
	C149.870926,318.579834 151.804291,319.801941 152.450836,324.890686 
	C155.580994,349.526855 158.929031,374.139526 162.569992,398.705658 
	C168.300720,437.371704 174.409363,475.981598 180.204178,514.638306 
	C185.112335,547.380249 189.724457,580.166504 194.610748,612.911804 
	C199.744019,647.312256 205.100967,681.679382 210.259705,716.076050 
	C214.436020,743.922363 218.414154,771.798279 222.568512,799.647888 
	C228.431549,838.952026 234.365448,878.245605 240.291565,917.540344 
	C242.354889,931.221741 244.485123,944.893188 246.604767,958.699341 
	C267.310089,958.699341 287.153839,958.699341 307.127350,958.699341 
	C312.225769,925.077637 317.333344,892.030701 322.228760,858.952454 
	C328.388580,817.330566 334.254791,775.664978 340.469910,734.051392 
	C346.731476,692.126953 353.370789,650.258911 359.648804,608.336853 
	C365.389008,570.006226 370.822174,531.629761 376.472534,493.285553 
	C383.715363,444.134399 391.040741,394.995422 398.329010,345.850952 
	C399.444031,338.332306 400.754761,330.834930 401.586456,323.284882 
	C402.103241,318.593903 404.544342,319.181244 408.703125,320.180695 
	C408.703125,533.096680 408.703125,745.839722 408.703125,958.453491 
	C425.805603,960.353760 532.225708,959.673096 539.217407,957.749878 
	C541.299988,928.726807 540.243530,11.938716 538.181824,4.657792 
	C481.243317,4.657792 424.369415,4.657792 367.162354,4.657792 
	C366.723480,6.715959 366.357819,8.290496 366.053864,9.876855 
	C360.939453,36.569931 355.826691,63.263332 350.725677,89.958977 
	C343.197968,129.354630 335.658569,168.748077 328.162903,208.149826 
	C318.374390,259.604370 308.445831,311.033203 298.920898,362.536591 
	C292.833862,395.450256 287.317505,428.470123 281.655487,461.461304 
	C280.088898,470.589447 278.916656,479.785248 277.316833,490.620209 
	C250.292618,326.664093 216.026794,165.997223 186.940689,4.668709 
	C128.772812,4.668709 71.927078,4.668709 14.329707,4.668709 
	C14.329707,145.166687 14.329707,285.083282 14.329695,426.999908 
M1301.666626,2065.015625 
	C1302.997559,2084.854980 1304.076294,2104.715332 1305.713867,2124.529541 
	C1308.784668,2161.684814 1315.833618,2198.075684 1328.055420,2233.355713 
	C1339.376587,2266.036133 1355.486328,2296.038818 1379.308960,2321.411621 
	C1405.725952,2349.547852 1438.234985,2367.840576 1475.183105,2377.978271 
	C1529.953857,2393.006348 1585.400391,2394.319092 1640.984619,2383.127441 
	C1676.398071,2375.996826 1708.524048,2361.326416 1736.336426,2337.854492 
	C1769.827881,2309.589844 1791.618042,2273.567871 1805.791382,2232.611816 
	C1815.487671,2204.593018 1820.932007,2175.667725 1824.638672,2146.239014 
	C1828.688110,2114.090820 1829.377563,2081.883301 1827.835571,2049.702148 
	C1825.996948,2011.329834 1816.203857,1974.491089 1802.679199,1938.582886 
	C1785.378052,1892.648193 1761.941040,1849.813477 1736.763184,1807.903076 
	C1697.362183,1742.317261 1653.706665,1679.517944 1610.494507,1616.427856 
	C1582.862915,1576.085449 1555.792480,1535.354370 1534.884033,1490.943726 
	C1524.379395,1468.631226 1515.529297,1445.716431 1511.775024,1421.199097 
	C1507.407349,1392.676147 1509.434448,1364.212280 1514.488525,1336.023315 
	C1517.425659,1319.641968 1522.511963,1303.905884 1533.311523,1290.440186 
	C1550.518066,1268.986084 1581.612915,1264.713135 1602.530518,1282.148438 
	C1608.804810,1287.378296 1614.185913,1294.094482 1618.665771,1300.982056 
	C1630.635132,1319.384399 1636.315918,1340.187744 1639.876831,1361.632080 
	C1644.237183,1387.890869 1645.911377,1414.348755 1645.671509,1440.945679 
	C1645.636230,1444.851685 1645.666626,1448.758179 1645.666626,1453.266113 
	C1707.103760,1447.459961 1767.366089,1441.764893 1828.333374,1436.003052 
	C1828.333374,1432.683960 1828.291748,1430.070923 1828.339844,1427.459473 
	C1828.867920,1398.827026 1826.402710,1370.400391 1822.264282,1342.108765 
	C1815.642822,1296.841797 1803.999878,1253.089722 1782.536133,1212.355713 
	C1762.414307,1174.168457 1733.630493,1144.786377 1694.858521,1125.304688 
	C1659.944214,1107.761475 1622.489502,1101.679932 1584.021973,1100.155762 
	C1565.723267,1099.430664 1547.525635,1101.063354 1529.422852,1103.930054 
	C1483.383545,1111.220825 1443.481323,1130.965942 1409.979614,1163.260132 
	C1376.058228,1195.959106 1355.044189,1236.319214 1342.292236,1281.187866 
	C1329.766479,1325.260376 1324.149292,1370.203369 1327.754517,1416.022339 
	C1328.973022,1431.507568 1330.107910,1447.149536 1333.273438,1462.303833 
	C1343.983154,1513.576050 1362.294067,1562.298096 1385.133545,1609.341309 
	C1403.471313,1647.112305 1425.535889,1682.726318 1448.818970,1717.588745 
	C1490.740479,1780.358765 1532.959717,1842.930054 1574.818481,1905.741699 
	C1597.171143,1939.283203 1616.914307,1974.270020 1631.062988,2012.179932 
	C1640.058960,2036.283203 1646.839600,2060.827637 1645.648438,2086.906494 
	C1645.088501,2099.167236 1644.949341,2111.490967 1643.530273,2123.659180 
	C1641.064453,2144.803223 1636.769653,2165.573975 1626.290405,2184.505371 
	C1613.183716,2208.183105 1593.465088,2220.877686 1566.019165,2219.163330 
	C1540.850708,2217.591309 1523.407227,2203.796631 1511.977539,2181.981201 
	C1498.565063,2156.381348 1491.429688,2128.783447 1488.479858,2100.263184 
	C1486.195312,2078.174072 1485.599609,2055.907715 1484.356445,2033.714111 
	C1483.840088,2024.494629 1483.592651,2015.260132 1483.171021,2004.735840 
	C1421.880981,2010.513916 1361.325928,2016.222778 1301.666626,2021.847168 
	C1301.666626,2036.572998 1301.666626,2049.799316 1301.666626,2065.015625 
M1943.030640,1114.089844 
	C1935.114136,1114.089844 1927.197632,1114.089844 1918.915894,1114.089844 
	C1918.915894,1178.114624 1918.915894,1241.215454 1918.915894,1305.430176 
	C1977.277954,1305.430176 2035.071411,1305.430176 2093.965332,1305.430176 
	C2093.965332,1662.933838 2093.965332,2019.324219 2093.965332,2375.578369 
	C2156.608154,2375.578369 2218.133301,2375.578369 2280.729980,2375.578369 
	C2280.729980,2018.671753 2280.729980,1662.272705 2280.729980,1305.287598 
	C2293.439453,1305.287598 2305.082275,1305.287598 2316.725342,1305.287598 
	C2328.391357,1305.287598 2340.057129,1305.287598 2351.723145,1305.287598 
	C2363.055664,1305.287598 2374.388428,1305.287598 2385.720947,1305.287598 
	C2397.386963,1305.287598 2409.053223,1305.347656 2420.718750,1305.270508 
	C2432.239990,1305.194214 2443.790527,1305.631592 2455.185059,1304.994141 
	C2455.185059,1240.797363 2455.185059,1177.912231 2455.185059,1114.089844 
	C2284.812012,1114.089844 2114.917236,1114.089844 1943.030640,1114.089844 
M1970.306763,657.000000 
	C1970.306763,633.423340 1970.306763,609.846680 1970.306763,586.475525 
	C1958.263672,584.342957 1190.459961,584.770142 1183.066650,586.751404 
	C1183.066650,667.166565 1183.066650,747.607788 1183.066650,828.275452 
	C1445.625854,828.275452 1707.437500,828.275452 1970.306885,828.275452 
	C1970.306885,771.475769 1970.306885,715.237854 1970.306763,657.000000 
M860.223328,123.241974 
	C854.052734,150.077255 847.872314,176.910263 841.713928,203.748352 
	C834.075562,237.036819 826.522827,270.345245 818.751404,303.602600 
	C817.635132,308.379791 815.501404,312.919250 813.838440,317.568726 
	C813.110474,317.474762 812.382507,317.380768 811.654541,317.286804 
	C810.219055,312.942200 808.455994,308.675323 807.404053,304.239746 
	C802.505066,283.581909 797.783447,262.881897 793.033508,242.188843 
	C777.228455,173.334152 761.456116,104.471893 745.623596,35.623482 
	C743.202087,25.093071 740.528931,14.620500 738.080994,4.568158 
	C691.372803,4.568158 645.859070,4.568158 599.617188,4.568158 
	C602.797546,17.057362 605.752991,28.940077 608.851257,40.785446 
	C630.432678,123.296013 652.076355,205.790298 673.635986,288.306549 
	C694.272156,367.288177 714.957581,446.257477 735.298218,525.315308 
	C738.664368,538.398438 742.586243,551.283386 742.545715,565.310425 
	C742.177307,692.633484 742.329773,819.957947 742.329773,947.282043 
	C742.329773,950.875732 742.329712,954.469421 742.329712,957.883240 
	C753.216431,960.148071 876.344543,959.743591 883.670288,957.465149 
	C883.670288,953.943359 883.670227,950.290405 883.670227,946.637512 
	C883.670227,826.646240 883.882385,706.654236 883.481201,586.664307 
	C883.406067,564.203186 885.710754,542.529114 891.586243,520.757751 
	C910.347290,451.239563 928.079163,381.443817 946.256714,311.767853 
	C971.306091,215.751648 996.406128,119.748665 1021.459167,23.733418 
	C1023.102783,17.434597 1024.531494,11.079699 1026.160400,4.335300 
	C979.683899,4.335300 934.063782,4.335300 887.690796,4.335300 
	C878.598328,43.459755 869.520325,82.521660 860.223328,123.241974 
M1855.071045,933.987549 
	C1833.857300,941.603882 1812.643677,949.220276 1791.429932,956.836670 
	C1791.597290,957.290833 1791.764526,957.745056 1791.931885,958.199219 
	C2011.744141,958.199219 2231.556396,958.199219 2451.836914,958.199219 
	C2451.836914,876.721191 2451.836914,795.887268 2451.836914,714.706421 
	C2306.334229,714.706421 2161.253906,714.706421 2015.693604,714.706421 
	C2015.693604,719.049255 2015.693604,722.691589 2015.693604,726.333923 
	C2015.693481,773.665894 2015.708130,820.997925 2015.679810,868.329895 
	C2015.674683,876.984131 2015.599243,876.999146 2007.833496,879.761414 
	C1957.416870,897.694885 1906.997803,915.621216 1855.071045,933.987549 
M1189.319580,536.841003 
	C1449.373413,536.841003 1709.427246,536.841003 1969.681152,536.841003 
	C1969.681152,454.959778 1969.681152,374.506744 1969.681152,294.143646 
	C1954.072754,291.954620 1745.784058,292.774994 1738.319092,295.184875 
	C1738.319092,298.565155 1738.319336,302.189758 1738.319214,305.814362 
	C1738.319092,341.131470 1738.371704,376.448730 1738.289917,411.765656 
	C1738.261597,424.027191 1732.065063,432.577057 1720.303833,436.342682 
	C1716.605957,437.526672 1712.516479,437.929565 1708.604736,437.936646 
	C1653.963257,438.035583 1599.321411,437.884766 1544.680298,438.028259 
	C1427.436890,438.336121 1310.193848,438.775787 1192.950806,439.185852 
	C1189.727783,439.197113 1186.505737,439.486328 1182.876587,439.666046 
	C1182.656982,443.047241 1182.345459,445.643982 1182.341919,448.241119 
	C1182.305542,474.562317 1182.316772,500.883575 1182.324707,527.204834 
	C1182.325317,529.202271 1181.776123,531.534729 1182.616333,533.104492 
	C1183.485596,534.728088 1185.738037,535.611328 1189.319580,536.841003 
M2265.000000,126.004829 
	C2182.135742,126.004829 2099.271484,126.004829 2016.426514,126.004829 
	C2016.426514,210.619812 2016.426514,294.034729 2016.426514,377.398041 
	C2161.969482,377.398041 2306.779053,377.398041 2451.573486,377.398041 
	C2451.573486,293.382050 2451.573486,209.967163 2451.573486,126.004829 
	C2389.561768,126.004829 2328.280762,126.004829 2265.000000,126.004829 
M2167.000000,421.168365 
	C2116.827881,421.168365 2066.656006,421.168365 2016.225586,421.168365 
	C2016.225586,502.116913 2016.225586,581.607483 2016.225586,661.025635 
	C2031.306274,663.048645 2445.096680,662.395508 2451.594238,660.511963 
	C2451.594238,580.882690 2451.594238,501.356964 2451.594238,421.167572 
	C2356.922363,421.167572 2262.961182,421.167572 2167.000000,421.168365 
M1803.000000,879.992249 
	C1768.082886,880.113586 1733.165771,880.233704 1698.248779,880.377258 
	C1697.981812,880.378357 1697.692871,880.670959 1697.456055,880.875732 
	C1697.206421,881.091309 1697.006958,881.364990 1697.047119,881.320007 
	C1697.047119,906.978149 1697.047119,932.336853 1697.047119,959.176758 
	C1758.245605,932.994751 1818.271606,907.314270 1878.297729,881.633789 
	C1878.117310,881.086609 1877.936890,880.539429 1877.756470,879.992249 
	C1853.504272,879.992249 1829.252197,879.992249 1803.000000,879.992249 
M2501.620117,2506.983398 
	C2501.620117,2511.524902 2501.620117,2516.066406 2501.620117,2521.321777 
	C2509.642578,2521.321777 2516.506104,2521.321777 2524.294922,2521.321777 
	C2524.294922,2567.146973 2524.294922,2612.231934 2524.294922,2657.339844 
	C2533.313965,2657.339844 2541.359619,2657.339844 2550.017090,2657.339844 
	C2550.017090,2611.726807 2550.017090,2566.898926 2550.017090,2521.077637 
	C2557.435303,2521.077637 2564.246338,2521.077637 2571.104492,2521.077637 
	C2571.104492,2512.266602 2571.104492,2504.465820 2571.104492,2496.623047 
	C2547.695801,2496.623047 2524.897949,2496.623047 2501.620117,2496.623047 
	C2501.620117,2499.816406 2501.620117,2502.440918 2501.620117,2506.983398 
z"
        />
        <path
          fill="#E64D3B"
          opacity="1.000000"
          stroke="none"
          d="
M1.999996,2496.937256 
	C5.624947,2495.722900 9.249892,2495.203125 12.874860,2495.203125 
	C821.819397,2495.167725 1630.763916,2495.168701 2439.708496,2495.168701 
	C2443.360107,2495.168701 2447.011475,2495.168701 2450.192627,2495.168701 
	C2450.882324,2495.918701 2451.110107,2496.151367 2451.320557,2496.399170 
	C2451.536377,2496.653076 2451.915283,2496.920898 2451.916016,2497.183350 
	C2452.046143,2551.502197 2452.148926,2605.820801 2452.126465,2661.069824 
	C1635.333374,2662.000000 818.666687,2662.000000 2.000000,2662.000000 
	C2.000000,2607.171387 2.000000,2552.522949 1.999996,2496.937256 
z"
        />
        <path
          fill="#39577C"
          opacity="1.000000"
          stroke="none"
          d="
M1695.062744,2.000000 
	C1695.893921,5.993175 1695.695801,9.986338 1695.695312,13.979527 
	C1695.678955,136.971329 1695.682007,259.963135 1695.682007,382.954926 
	C1695.682007,386.897400 1695.681885,390.839874 1695.681885,395.453918 
	C1524.514404,395.453918 1354.057251,395.453918 1182.267578,395.453918 
	C1182.267578,264.917633 1182.267578,134.388092 1182.133789,2.929268 
	C1352.708496,2.000000 1523.416870,2.000000 1695.062744,2.000000 
M1564.497925,306.766907 
	C1567.855469,313.041901 1571.213013,319.316925 1574.781250,325.985840 
	C1583.285156,320.690552 1590.763184,316.034058 1598.712524,311.084045 
	C1591.703491,302.597839 1585.420166,294.990326 1578.254395,286.314423 
	C1589.390869,283.829559 1598.961426,281.694122 1609.239380,279.400848 
	C1606.448242,269.937408 1603.948975,261.463287 1601.295044,252.464645 
	C1591.246704,256.579315 1582.096313,260.326263 1571.652832,264.602661 
	C1572.634033,253.330246 1573.503784,243.337234 1574.426880,232.732178 
	C1564.161987,232.698380 1555.399780,231.698318 1546.412109,233.429886 
	C1547.349121,243.967911 1548.214600,253.700394 1549.185669,264.620117 
	C1538.762329,260.328979 1529.668213,256.585083 1519.618530,252.447784 
	C1516.890869,261.648987 1514.381104,270.114990 1511.657593,279.301849 
	C1521.979492,281.680664 1531.715698,283.924530 1542.404785,286.387970 
	C1535.206787,295.274994 1528.976562,302.967102 1522.217407,311.312195 
	C1530.418579,316.310272 1537.993164,320.926422 1546.337280,326.011597 
	C1551.030029,316.941284 1555.367310,308.558014 1560.242920,299.134430 
	C1561.969604,302.140381 1562.924072,303.801941 1564.497925,306.766907 
M1449.933472,263.563782 
	C1449.213745,261.749084 1447.793823,259.894775 1447.893799,258.126404 
	C1448.356812,249.936066 1449.241943,241.769623 1450.018066,233.123245 
	C1440.238037,233.123245 1431.433960,233.123245 1421.832764,233.123245 
	C1422.448242,238.717392 1423.062378,243.621872 1423.507690,248.541626 
	C1423.951294,253.440445 1424.225464,258.354584 1424.670166,264.620514 
	C1414.088257,260.243713 1404.803711,256.403473 1394.989136,252.344055 
	C1392.223877,262.225433 1389.862671,270.662720 1387.400269,279.461823 
	C1398.136719,281.885162 1407.583008,284.017273 1418.100464,286.391205 
	C1410.787354,295.283569 1404.483154,302.949066 1397.633789,311.277466 
	C1405.934692,316.294434 1413.688843,320.980896 1421.894043,325.939911 
	C1426.646118,316.674866 1430.816650,308.543488 1435.805420,298.816956 
	C1441.003174,308.638031 1445.431152,317.004456 1450.177002,325.971802 
	C1458.514526,320.893768 1466.093384,316.277771 1474.382812,311.229004 
	C1467.438477,302.831757 1460.949829,294.985596 1453.752930,286.282928 
	C1464.680542,283.879730 1474.302002,281.763763 1484.740845,279.468018 
	C1482.051270,270.153961 1479.604370,261.680542 1476.916382,252.372116 
	C1467.909424,256.144012 1459.689453,259.586365 1449.933472,263.563782 
M1353.723145,281.093903 
	C1355.770264,280.342407 1357.817383,279.590942 1360.288818,278.683655 
	C1357.507690,269.663422 1354.916504,261.259338 1352.215454,252.499039 
	C1341.995483,256.691650 1332.911499,260.418213 1322.778320,264.575226 
	C1323.778687,253.398514 1324.658325,243.571732 1325.594482,233.113815 
	C1316.263062,233.113815 1307.453979,233.113815 1297.706665,233.113815 
	C1298.505737,243.545471 1299.267334,253.486923 1300.110352,264.490417 
	C1289.507080,260.180450 1280.380859,256.470825 1270.592896,252.492264 
	C1267.923584,261.746307 1265.476196,270.230652 1262.840210,279.369385 
	C1273.351929,281.779602 1282.814209,283.949158 1293.614380,286.425507 
	C1286.291138,295.330841 1280.010742,302.968170 1273.209717,311.238373 
	C1281.420776,316.253876 1289.032715,320.903534 1297.319092,325.965118 
	C1301.946045,317.040527 1306.314941,308.613495 1311.383301,298.837372 
	C1316.428467,308.451111 1320.842773,316.862823 1325.647583,326.018738 
	C1333.941284,320.961121 1341.510498,316.345337 1349.832031,311.270752 
	C1342.921265,302.786957 1336.521484,294.930542 1329.312134,286.080231 
	C1337.870117,284.373047 1345.008789,282.948975 1353.723145,281.093903 
M1605.197510,99.642136 
	C1603.879150,95.658188 1602.560669,91.674240 1601.021729,87.023651 
	C1591.065308,91.146248 1581.997803,94.900787 1571.671753,99.176422 
	C1572.643433,87.775215 1573.474854,78.018898 1574.315674,68.152786 
	C1564.300171,68.152786 1555.521606,68.152786 1546.428711,68.152786 
	C1547.353882,78.407867 1548.209595,87.892426 1549.219238,99.082680 
	C1538.643921,94.768204 1529.604248,91.080269 1519.553589,86.979866 
	C1516.821045,96.296394 1514.355591,104.702301 1511.640747,113.958153 
	C1522.067261,116.250450 1531.638672,118.354774 1542.605103,120.765778 
	C1535.519409,129.408478 1529.101807,137.236298 1522.137695,145.730820 
	C1530.567383,150.871735 1538.302246,155.588928 1546.329590,160.484451 
	C1551.221802,151.047577 1555.456909,142.878036 1560.359863,133.420303 
	C1565.470459,143.151154 1569.870728,151.529266 1574.619019,160.570053 
	C1582.979370,155.360809 1590.654419,150.578613 1598.720337,145.552841 
	C1591.531738,136.877960 1585.215210,129.255432 1578.221069,120.815346 
	C1589.292114,118.336754 1599.101074,116.140671 1609.094360,113.903358 
	C1607.731934,108.827126 1606.698608,104.976517 1605.197510,99.642136 
M1325.733032,97.859222 
	C1321.889282,97.478477 1323.231812,94.671097 1323.390625,92.593948 
	C1324.011719,84.470688 1324.750854,76.356453 1325.450317,68.189865 
	C1315.631226,68.189865 1306.862915,68.189865 1297.569702,68.189865 
	C1298.491333,78.560547 1299.338379,88.091721 1300.295776,98.863487 
	C1289.833008,94.758957 1280.476196,91.088257 1270.540405,87.190460 
	C1267.816162,96.604919 1265.441772,104.810493 1262.808350,113.910614 
	C1273.204224,116.239922 1282.940308,118.421371 1293.755005,120.844528 
	C1286.490723,129.615799 1280.168335,137.249710 1273.129272,145.748978 
	C1281.471191,150.786667 1289.223145,155.468048 1297.439941,160.430145 
	C1302.198730,151.103043 1306.415161,142.838974 1311.309448,133.246216 
	C1316.394897,142.857712 1320.951782,151.470184 1325.744629,160.528809 
	C1334.305298,155.215256 1341.851440,150.531448 1349.691772,145.665009 
	C1342.773804,137.090485 1336.398315,129.188400 1329.559937,120.712502 
	C1340.532959,118.291306 1350.150269,116.169266 1360.445923,113.897530 
	C1357.572388,104.440422 1355.025269,96.057648 1352.249756,86.923454 
	C1343.203979,90.742020 1335.292114,94.081902 1325.733032,97.859222 
M1390.373047,114.724876 
	C1399.261841,116.652451 1408.150513,118.580032 1418.254883,120.771210 
	C1410.987915,129.549820 1404.652954,137.202621 1397.600342,145.722290 
	C1405.779297,150.682709 1413.551147,155.396149 1421.847046,160.427505 
	C1426.556519,151.272629 1430.768921,143.084290 1435.663940,133.568665 
	C1440.902222,143.129684 1445.562378,151.635330 1450.394531,160.455154 
	C1458.924438,155.128403 1466.419922,150.447662 1474.116333,145.641388 
	C1467.109253,136.928696 1460.801636,129.085693 1454.037354,120.674812 
	C1465.145996,118.238052 1474.748535,116.131653 1484.807617,113.925110 
	C1482.010620,104.407303 1479.596191,96.191673 1476.868042,86.908234 
	C1466.993164,91.001328 1457.927368,94.759087 1447.368652,99.135651 
	C1448.280273,87.827690 1449.064087,78.105530 1449.871460,68.090790 
	C1440.156250,68.090790 1431.384644,68.090790 1422.258179,68.090790 
	C1421.627686,78.441917 1425.694702,88.051208 1423.487793,98.619026 
	C1413.663452,94.595528 1404.639404,90.899742 1394.255249,86.646980 
	C1392.241943,96.886932 1390.552979,105.477219 1390.373047,114.724876 
z"
        />
        <path
          fill="#E64D3B"
          opacity="1.000000"
          stroke="none"
          d="
M1969.062744,2.000000 
	C1970.102905,5.324272 1970.295044,8.648524 1970.295776,11.972819 
	C1970.312744,88.235855 1970.308716,164.498886 1970.308716,240.761932 
	C1970.308716,244.058380 1970.308716,247.354828 1970.308716,251.324127 
	C1893.032715,251.324127 1816.301758,251.324127 1738.262451,251.324127 
	C1738.262451,168.790649 1738.262451,86.324974 1738.131226,2.929652 
	C1814.708496,2.000000 1891.416870,2.000000 1969.062744,2.000000 
z"
        />
        <path
          fill="#040303"
          opacity="1.000000"
          stroke="none"
          d="
M2668.000000,2657.062744 
	C2661.078125,2658.000000 2654.156006,2658.000000 2646.476807,2658.000000 
	C2646.476807,2625.409912 2646.476807,2593.523682 2646.476807,2561.637207 
	C2645.881836,2561.584961 2645.286621,2561.532959 2644.691650,2561.480957 
	C2639.878418,2593.412109 2635.065186,2625.343262 2630.192871,2657.666016 
	C2626.429932,2657.666016 2622.888184,2657.666016 2618.941895,2657.666016 
	C2614.087891,2625.591553 2609.258789,2593.680664 2604.429443,2561.769531 
	C2603.871338,2561.826172 2603.312988,2561.882568 2602.754883,2561.939209 
	C2602.754883,2593.507324 2602.754883,2625.075439 2602.754883,2657.372559 
	C2595.102295,2657.372559 2587.996338,2657.372559 2580.259277,2657.372559 
	C2580.259277,2604.180908 2580.259277,2550.722656 2580.259277,2496.669434 
	C2589.908936,2496.669434 2599.732422,2496.669434 2609.746338,2496.669434 
	C2614.544189,2521.453125 2619.309570,2546.070312 2624.075195,2570.687500 
	C2624.437500,2570.660889 2624.799805,2570.634521 2625.162109,2570.607910 
	C2629.908203,2545.903076 2634.654541,2521.198486 2639.481934,2496.070801 
	C2648.884033,2496.070801 2657.512939,2496.070801 2667.070801,2496.035400 
	C2668.000000,2549.375244 2668.000000,2602.750244 2668.000000,2657.062744 
z"
        />
        <path
          fill="#242021"
          opacity="1.000000"
          stroke="none"
          d="
M1157.666504,2012.000000 
	C1157.666504,2044.990112 1157.671997,2076.980225 1157.665283,2108.970459 
	C1157.654175,2162.187500 1147.838257,2213.354248 1123.876465,2261.124512 
	C1097.479858,2313.748779 1058.398438,2353.583984 1002.831909,2375.321533 
	C980.124084,2384.205078 956.162231,2387.966309 931.780151,2389.420654 
	C900.392517,2391.292969 869.390015,2389.493408 838.763245,2382.089600 
	C805.416870,2374.028076 776.274963,2358.228760 750.941040,2335.152588 
	C710.998535,2298.769775 688.246521,2252.536133 674.318604,2201.338135 
	C668.127686,2178.580566 664.349487,2155.364502 663.649536,2131.715088 
	C663.276306,2119.103760 662.394226,2106.496338 662.390198,2093.886719 
	C662.313538,1854.293579 661.867432,1614.698975 662.642090,1375.108276 
	C662.851135,1310.460693 680.615845,1249.951172 716.651733,1195.582520 
	C751.390198,1143.171387 800.606262,1113.304810 862.405945,1104.251709 
	C880.407227,1101.614746 898.783752,1100.101685 916.954956,1100.365356 
	C953.090393,1100.889526 988.252319,1107.442139 1021.024597,1123.535034 
	C1054.909302,1140.174194 1082.042725,1164.751953 1103.270142,1195.792358 
	C1131.552856,1237.149414 1147.709229,1283.112305 1154.338745,1332.612061 
	C1156.721313,1350.402710 1157.738037,1368.232178 1157.729492,1386.191162 
	C1157.630981,1594.460815 1157.666504,1802.730347 1157.666504,2012.000000 
M848.326416,1993.000000 
	C848.770935,2038.930298 849.041870,2084.863281 849.736694,2130.789795 
	C850.062256,2152.305908 856.506287,2172.147949 869.865967,2189.110840 
	C874.456177,2194.938965 880.526550,2200.548096 887.141907,2203.666748 
	C913.036255,2215.874268 946.494873,2207.657227 959.683899,2176.802734 
	C967.452209,2158.629395 971.701477,2139.707031 971.698425,2119.837158 
	C971.660339,1873.880981 971.692566,1627.924805 971.609436,1381.968628 
	C971.605896,1371.361694 971.172119,1360.699951 970.035950,1350.161133 
	C968.346741,1334.492920 963.799072,1319.610840 955.466980,1306.033691 
	C941.651184,1283.520996 912.140320,1274.854370 888.232544,1286.216919 
	C874.243713,1292.865234 865.558899,1304.090820 859.395813,1317.900391 
	C850.675903,1337.439453 848.447205,1358.182373 848.429626,1379.109131 
	C848.258179,1583.072632 848.326416,1787.036377 848.326416,1993.000000 
z"
        />
        <path
          fill="#231F20"
          opacity="1.000000"
          stroke="none"
          d="
M200.330902,2012.000000 
	C200.330917,2133.620117 200.330917,2254.240479 200.330917,2375.473145 
	C138.121704,2375.473145 76.346985,2375.473145 14.119776,2375.473145 
	C14.119776,1955.170288 14.119776,1535.075684 14.119776,1114.090576 
	C17.448027,1114.090576 20.362417,1114.090454 23.276808,1114.090454 
	C86.937943,1114.090454 150.617325,1113.144531 214.254822,1114.370850 
	C271.780029,1115.479492 326.807587,1128.009033 376.926971,1157.903931 
	C421.532104,1184.509766 454.945251,1221.546265 479.026093,1267.262573 
	C501.278290,1309.507202 513.599670,1354.822632 520.323608,1401.856323 
	C525.362915,1437.106445 527.059143,1472.557861 526.259888,1508.103638 
	C525.114319,1559.054199 518.919189,1609.341309 503.312531,1658.057007 
	C487.556549,1707.238770 462.991943,1751.403442 425.508820,1787.494263 
	C390.627167,1821.080688 349.469360,1844.021851 303.007385,1857.659668 
	C269.931305,1867.368408 236.072845,1871.320801 200.330902,1872.228638 
	C200.330902,1918.651245 200.330902,1964.825684 200.330902,2012.000000 
M200.325180,1579.000000 
	C200.325180,1613.199219 200.325180,1647.398438 200.325180,1682.102295 
	C224.299408,1682.192871 246.759995,1680.460571 268.521851,1673.636841 
	C289.611633,1667.023926 306.888092,1654.872925 317.273193,1635.036133 
	C322.609650,1624.842651 326.725250,1613.752563 329.664154,1602.611694 
	C337.347168,1573.486938 337.949219,1543.480469 338.172119,1513.609985 
	C338.348053,1490.031494 337.042755,1466.440674 336.333923,1442.857178 
	C335.673096,1420.873657 332.618042,1399.241211 326.157837,1378.181519 
	C320.197235,1358.750366 310.689911,1341.461304 294.397491,1328.680908 
	C283.811462,1320.377075 271.906128,1314.575806 258.884796,1311.015137 
	C239.936264,1305.833740 220.755814,1303.249756 200.325195,1304.373047 
	C200.325195,1395.811768 200.325195,1486.405884 200.325180,1579.000000 
z"
        />
        <path
          fill="#242021"
          opacity="1.000000"
          stroke="none"
          d="
M14.329700,425.999878 
	C14.329707,285.083282 14.329707,145.166687 14.329707,4.668709 
	C71.927078,4.668709 128.772812,4.668709 186.940689,4.668709 
	C216.026794,165.997223 250.292618,326.664093 277.316833,490.620209 
	C278.916656,479.785248 280.088898,470.589447 281.655487,461.461304 
	C287.317505,428.470123 292.833862,395.450256 298.920898,362.536591 
	C308.445831,311.033203 318.374390,259.604370 328.162903,208.149826 
	C335.658569,168.748077 343.197968,129.354630 350.725677,89.958977 
	C355.826691,63.263332 360.939453,36.569931 366.053864,9.876855 
	C366.357819,8.290496 366.723480,6.715959 367.162354,4.657792 
	C424.369415,4.657792 481.243317,4.657792 538.181824,4.657792 
	C540.243530,11.938716 541.299988,928.726807 539.217407,957.749878 
	C532.225708,959.673096 425.805603,960.353760 408.703125,958.453491 
	C408.703125,745.839722 408.703125,533.096680 408.703125,320.180695 
	C404.544342,319.181244 402.103241,318.593903 401.586456,323.284882 
	C400.754761,330.834930 399.444031,338.332306 398.329010,345.850952 
	C391.040741,394.995422 383.715363,444.134399 376.472534,493.285553 
	C370.822174,531.629761 365.389008,570.006226 359.648804,608.336853 
	C353.370789,650.258911 346.731476,692.126953 340.469910,734.051392 
	C334.254791,775.664978 328.388580,817.330566 322.228760,858.952454 
	C317.333344,892.030701 312.225769,925.077637 307.127350,958.699341 
	C287.153839,958.699341 267.310089,958.699341 246.604767,958.699341 
	C244.485123,944.893188 242.354889,931.221741 240.291565,917.540344 
	C234.365448,878.245605 228.431549,838.952026 222.568512,799.647888 
	C218.414154,771.798279 214.436020,743.922363 210.259705,716.076050 
	C205.100967,681.679382 199.744019,647.312256 194.610748,612.911804 
	C189.724457,580.166504 185.112335,547.380249 180.204178,514.638306 
	C174.409363,475.981598 168.300720,437.371704 162.569992,398.705658 
	C158.929031,374.139526 155.580994,349.526855 152.450836,324.890686 
	C151.804291,319.801941 149.870926,318.579834 144.329620,320.248169 
	C144.329620,532.845581 144.329620,745.622986 144.329620,958.845581 
	C100.737236,958.845581 58.210693,958.845581 14.329695,958.845581 
	C14.329695,781.316101 14.329695,604.158020 14.329700,425.999878 
z"
        />
        <path
          fill="#242021"
          opacity="1.000000"
          stroke="none"
          d="
M1301.666626,2064.020508 
	C1301.666626,2049.799316 1301.666626,2036.572998 1301.666626,2021.847168 
	C1361.325928,2016.222778 1421.880981,2010.513916 1483.171021,2004.735840 
	C1483.592651,2015.260132 1483.840088,2024.494629 1484.356445,2033.714111 
	C1485.599609,2055.907715 1486.195312,2078.174072 1488.479858,2100.263184 
	C1491.429688,2128.783447 1498.565063,2156.381348 1511.977539,2181.981201 
	C1523.407227,2203.796631 1540.850708,2217.591309 1566.019165,2219.163330 
	C1593.465088,2220.877686 1613.183716,2208.183105 1626.290405,2184.505371 
	C1636.769653,2165.573975 1641.064453,2144.803223 1643.530273,2123.659180 
	C1644.949341,2111.490967 1645.088501,2099.167236 1645.648438,2086.906494 
	C1646.839600,2060.827637 1640.058960,2036.283203 1631.062988,2012.179932 
	C1616.914307,1974.270020 1597.171143,1939.283203 1574.818481,1905.741699 
	C1532.959717,1842.930054 1490.740479,1780.358765 1448.818970,1717.588745 
	C1425.535889,1682.726318 1403.471313,1647.112305 1385.133545,1609.341309 
	C1362.294067,1562.298096 1343.983154,1513.576050 1333.273438,1462.303833 
	C1330.107910,1447.149536 1328.973022,1431.507568 1327.754517,1416.022339 
	C1324.149292,1370.203369 1329.766479,1325.260376 1342.292236,1281.187866 
	C1355.044189,1236.319214 1376.058228,1195.959106 1409.979614,1163.260132 
	C1443.481323,1130.965942 1483.383545,1111.220825 1529.422852,1103.930054 
	C1547.525635,1101.063354 1565.723267,1099.430664 1584.021973,1100.155762 
	C1622.489502,1101.679932 1659.944214,1107.761475 1694.858521,1125.304688 
	C1733.630493,1144.786377 1762.414307,1174.168457 1782.536133,1212.355713 
	C1803.999878,1253.089722 1815.642822,1296.841797 1822.264282,1342.108765 
	C1826.402710,1370.400391 1828.867920,1398.827026 1828.339844,1427.459473 
	C1828.291748,1430.070923 1828.333374,1432.683960 1828.333374,1436.003052 
	C1767.366089,1441.764893 1707.103760,1447.459961 1645.666626,1453.266113 
	C1645.666626,1448.758179 1645.636230,1444.851685 1645.671509,1440.945679 
	C1645.911377,1414.348755 1644.237183,1387.890869 1639.876831,1361.632080 
	C1636.315918,1340.187744 1630.635132,1319.384399 1618.665771,1300.982056 
	C1614.185913,1294.094482 1608.804810,1287.378296 1602.530518,1282.148438 
	C1581.612915,1264.713135 1550.518066,1268.986084 1533.311523,1290.440186 
	C1522.511963,1303.905884 1517.425659,1319.641968 1514.488525,1336.023315 
	C1509.434448,1364.212280 1507.407349,1392.676147 1511.775024,1421.199097 
	C1515.529297,1445.716431 1524.379395,1468.631226 1534.884033,1490.943726 
	C1555.792480,1535.354370 1582.862915,1576.085449 1610.494507,1616.427856 
	C1653.706665,1679.517944 1697.362183,1742.317261 1736.763184,1807.903076 
	C1761.941040,1849.813477 1785.378052,1892.648193 1802.679199,1938.582886 
	C1816.203857,1974.491089 1825.996948,2011.329834 1827.835571,2049.702148 
	C1829.377563,2081.883301 1828.688110,2114.090820 1824.638672,2146.239014 
	C1820.932007,2175.667725 1815.487671,2204.593018 1805.791382,2232.611816 
	C1791.618042,2273.567871 1769.827881,2309.589844 1736.336426,2337.854492 
	C1708.524048,2361.326416 1676.398071,2375.996826 1640.984619,2383.127441 
	C1585.400391,2394.319092 1529.953857,2393.006348 1475.183105,2377.978271 
	C1438.234985,2367.840576 1405.725952,2349.547852 1379.308960,2321.411621 
	C1355.486328,2296.038818 1339.376587,2266.036133 1328.055420,2233.355713 
	C1315.833618,2198.075684 1308.784668,2161.684814 1305.713867,2124.529541 
	C1304.076294,2104.715332 1302.997559,2084.854980 1301.666626,2064.020508 
z"
        />
        <path
          fill="#231F20"
          opacity="1.000000"
          stroke="none"
          d="
M1944.026611,1114.089844 
	C2114.917236,1114.089844 2284.812012,1114.089844 2455.185059,1114.089844 
	C2455.185059,1177.912231 2455.185059,1240.797363 2455.185059,1304.994141 
	C2443.790527,1305.631592 2432.239990,1305.194214 2420.718750,1305.270508 
	C2409.053223,1305.347656 2397.386963,1305.287598 2385.720947,1305.287598 
	C2374.388428,1305.287598 2363.055664,1305.287598 2351.723145,1305.287598 
	C2340.057129,1305.287598 2328.391357,1305.287598 2316.725342,1305.287598 
	C2305.082275,1305.287598 2293.439453,1305.287598 2280.729980,1305.287598 
	C2280.729980,1662.272705 2280.729980,2018.671753 2280.729980,2375.578369 
	C2218.133301,2375.578369 2156.608154,2375.578369 2093.965332,2375.578369 
	C2093.965332,2019.324219 2093.965332,1662.933838 2093.965332,1305.430176 
	C2035.071411,1305.430176 1977.277954,1305.430176 1918.915894,1305.430176 
	C1918.915894,1241.215454 1918.915894,1178.114624 1918.915894,1114.089844 
	C1927.197632,1114.089844 1935.114136,1114.089844 1944.026611,1114.089844 
z"
        />
        <path
          fill="#E64D3B"
          opacity="1.000000"
          stroke="none"
          d="
M1970.306885,658.000000 
	C1970.306885,715.237854 1970.306885,771.475769 1970.306885,828.275452 
	C1707.437500,828.275452 1445.625854,828.275452 1183.066650,828.275452 
	C1183.066650,747.607788 1183.066650,667.166565 1183.066650,586.751404 
	C1190.459961,584.770142 1958.263672,584.342957 1970.306763,586.475525 
	C1970.306763,609.846680 1970.306763,633.423340 1970.306885,658.000000 
z"
        />
        <path
          fill="#242021"
          opacity="1.000000"
          stroke="none"
          d="
M860.332825,122.412766 
	C869.520325,82.521660 878.598328,43.459755 887.690796,4.335300 
	C934.063782,4.335300 979.683899,4.335300 1026.160400,4.335300 
	C1024.531494,11.079699 1023.102783,17.434597 1021.459167,23.733418 
	C996.406128,119.748665 971.306091,215.751648 946.256714,311.767853 
	C928.079163,381.443817 910.347290,451.239563 891.586243,520.757751 
	C885.710754,542.529114 883.406067,564.203186 883.481201,586.664307 
	C883.882385,706.654236 883.670227,826.646240 883.670227,946.637512 
	C883.670227,950.290405 883.670288,953.943359 883.670288,957.465149 
	C876.344543,959.743591 753.216431,960.148071 742.329712,957.883240 
	C742.329712,954.469421 742.329773,950.875732 742.329773,947.282043 
	C742.329773,819.957947 742.177307,692.633484 742.545715,565.310425 
	C742.586243,551.283386 738.664368,538.398438 735.298218,525.315308 
	C714.957581,446.257477 694.272156,367.288177 673.635986,288.306549 
	C652.076355,205.790298 630.432678,123.296013 608.851257,40.785446 
	C605.752991,28.940077 602.797546,17.057362 599.617188,4.568158 
	C645.859070,4.568158 691.372803,4.568158 738.080994,4.568158 
	C740.528931,14.620500 743.202087,25.093071 745.623596,35.623482 
	C761.456116,104.471893 777.228455,173.334152 793.033508,242.188843 
	C797.783447,262.881897 802.505066,283.581909 807.404053,304.239746 
	C808.455994,308.675323 810.219055,312.942200 811.654541,317.286804 
	C812.382507,317.380768 813.110474,317.474762 813.838440,317.568726 
	C815.501404,312.919250 817.635132,308.379791 818.751404,303.602600 
	C826.522827,270.345245 834.075562,237.036819 841.713928,203.748352 
	C847.872314,176.910263 854.052734,150.077255 860.332825,122.412766 
z"
        />
        <path
          fill="#E64D3B"
          opacity="1.000000"
          stroke="none"
          d="
M1855.825195,933.768738 
	C1906.997803,915.621216 1957.416870,897.694885 2007.833496,879.761414 
	C2015.599243,876.999146 2015.674683,876.984131 2015.679810,868.329895 
	C2015.708130,820.997925 2015.693481,773.665894 2015.693604,726.333923 
	C2015.693604,722.691589 2015.693604,719.049255 2015.693604,714.706421 
	C2161.253906,714.706421 2306.334229,714.706421 2451.836914,714.706421 
	C2451.836914,795.887268 2451.836914,876.721191 2451.836914,958.199219 
	C2231.556396,958.199219 2011.744141,958.199219 1791.931885,958.199219 
	C1791.764526,957.745056 1791.597290,957.290833 1791.429932,956.836670 
	C1812.643677,949.220276 1833.857300,941.603882 1855.825195,933.768738 
z"
        />
        <path
          fill="#E64D3B"
          opacity="1.000000"
          stroke="none"
          d="
M1188.352539,536.829712 
	C1185.738037,535.611328 1183.485596,534.728088 1182.616333,533.104492 
	C1181.776123,531.534729 1182.325317,529.202271 1182.324707,527.204834 
	C1182.316772,500.883575 1182.305542,474.562317 1182.341919,448.241119 
	C1182.345459,445.643982 1182.656982,443.047241 1182.876587,439.666046 
	C1186.505737,439.486328 1189.727783,439.197113 1192.950806,439.185852 
	C1310.193848,438.775787 1427.436890,438.336121 1544.680298,438.028259 
	C1599.321411,437.884766 1653.963257,438.035583 1708.604736,437.936646 
	C1712.516479,437.929565 1716.605957,437.526672 1720.303833,436.342682 
	C1732.065063,432.577057 1738.261597,424.027191 1738.289917,411.765656 
	C1738.371704,376.448730 1738.319092,341.131470 1738.319214,305.814362 
	C1738.319336,302.189758 1738.319092,298.565155 1738.319092,295.184875 
	C1745.784058,292.774994 1954.072754,291.954620 1969.681152,294.143646 
	C1969.681152,374.506744 1969.681152,454.959778 1969.681152,536.841003 
	C1709.427246,536.841003 1449.373413,536.841003 1188.352539,536.829712 
z"
        />
        <path
          fill="#E64D3B"
          opacity="1.000000"
          stroke="none"
          d="
M2266.000000,126.004829 
	C2328.280762,126.004829 2389.561768,126.004829 2451.573486,126.004829 
	C2451.573486,209.967163 2451.573486,293.382050 2451.573486,377.398041 
	C2306.779053,377.398041 2161.969482,377.398041 2016.426514,377.398041 
	C2016.426514,294.034729 2016.426514,210.619812 2016.426514,126.004829 
	C2099.271484,126.004829 2182.135742,126.004829 2266.000000,126.004829 
z"
        />
        <path
          fill="#E64D3B"
          opacity="1.000000"
          stroke="none"
          d="
M2168.000000,421.167969 
	C2262.961182,421.167572 2356.922363,421.167572 2451.594238,421.167572 
	C2451.594238,501.356964 2451.594238,580.882690 2451.594238,660.511963 
	C2445.096680,662.395508 2031.306274,663.048645 2016.225586,661.025635 
	C2016.225586,581.607483 2016.225586,502.116913 2016.225586,421.168365 
	C2066.656006,421.168365 2116.827881,421.168365 2168.000000,421.167969 
z"
        />
        <path
          fill="#E64F3E"
          opacity="1.000000"
          stroke="none"
          d="
M1804.000000,879.992249 
	C1829.252197,879.992249 1853.504272,879.992249 1877.756470,879.992249 
	C1877.936890,880.539429 1878.117310,881.086609 1878.297729,881.633789 
	C1818.271606,907.314270 1758.245605,932.994751 1697.047119,959.176758 
	C1697.047119,932.336853 1697.047119,906.978149 1697.047119,881.320007 
	C1697.006958,881.364990 1697.206421,881.091309 1697.456055,880.875732 
	C1697.692871,880.670959 1697.981812,880.378357 1698.248779,880.377258 
	C1733.165771,880.233704 1768.082886,880.113586 1804.000000,879.992249 
z"
        />
        <path
          fill="#0A0909"
          opacity="1.000000"
          stroke="none"
          d="
M2501.620117,2506.024414 
	C2501.620117,2502.440918 2501.620117,2499.816406 2501.620117,2496.623047 
	C2524.897949,2496.623047 2547.695801,2496.623047 2571.104492,2496.623047 
	C2571.104492,2504.465820 2571.104492,2512.266602 2571.104492,2521.077637 
	C2564.246338,2521.077637 2557.435303,2521.077637 2550.017090,2521.077637 
	C2550.017090,2566.898926 2550.017090,2611.726807 2550.017090,2657.339844 
	C2541.359619,2657.339844 2533.313965,2657.339844 2524.294922,2657.339844 
	C2524.294922,2612.231934 2524.294922,2567.146973 2524.294922,2521.321777 
	C2516.506104,2521.321777 2509.642578,2521.321777 2501.620117,2521.321777 
	C2501.620117,2516.066406 2501.620117,2511.524902 2501.620117,2506.024414 
z"
        />
        <path
          fill="#F8FAFB"
          opacity="1.000000"
          stroke="none"
          d="
M1564.188232,306.115234 
	C1562.924072,303.801941 1561.969604,302.140381 1560.242920,299.134430 
	C1555.367310,308.558014 1551.030029,316.941284 1546.337280,326.011597 
	C1537.993164,320.926422 1530.418579,316.310272 1522.217407,311.312195 
	C1528.976562,302.967102 1535.206787,295.274994 1542.404785,286.387970 
	C1531.715698,283.924530 1521.979492,281.680664 1511.657593,279.301849 
	C1514.381104,270.114990 1516.890869,261.648987 1519.618530,252.447784 
	C1529.668213,256.585083 1538.762329,260.328979 1549.185669,264.620117 
	C1548.214600,253.700394 1547.349121,243.967911 1546.412109,233.429886 
	C1555.399780,231.698318 1564.161987,232.698380 1574.426880,232.732178 
	C1573.503784,243.337234 1572.634033,253.330246 1571.652832,264.602661 
	C1582.096313,260.326263 1591.246704,256.579315 1601.295044,252.464645 
	C1603.948975,261.463287 1606.448242,269.937408 1609.239380,279.400848 
	C1598.961426,281.694122 1589.390869,283.829559 1578.254395,286.314423 
	C1585.420166,294.990326 1591.703491,302.597839 1598.712524,311.084045 
	C1590.763184,316.034058 1583.285156,320.690552 1574.781250,325.985840 
	C1571.213013,319.316925 1567.855469,313.041901 1564.188232,306.115234 
z"
        />
        <path
          fill="#F9FAFB"
          opacity="1.000000"
          stroke="none"
          d="
M1450.701416,263.296265 
	C1459.689453,259.586365 1467.909424,256.144012 1476.916382,252.372116 
	C1479.604370,261.680542 1482.051270,270.153961 1484.740845,279.468018 
	C1474.302002,281.763763 1464.680542,283.879730 1453.752930,286.282928 
	C1460.949829,294.985596 1467.438477,302.831757 1474.382812,311.229004 
	C1466.093384,316.277771 1458.514526,320.893768 1450.177002,325.971802 
	C1445.431152,317.004456 1441.003174,308.638031 1435.805420,298.816956 
	C1430.816650,308.543488 1426.646118,316.674866 1421.894043,325.939911 
	C1413.688843,320.980896 1405.934692,316.294434 1397.633789,311.277466 
	C1404.483154,302.949066 1410.787354,295.283569 1418.100464,286.391205 
	C1407.583008,284.017273 1398.136719,281.885162 1387.400269,279.461823 
	C1389.862671,270.662720 1392.223877,262.225433 1394.989136,252.344055 
	C1404.803711,256.403473 1414.088257,260.243713 1424.670166,264.620514 
	C1424.225464,258.354584 1423.951294,253.440445 1423.507690,248.541626 
	C1423.062378,243.621872 1422.448242,238.717392 1421.832764,233.123245 
	C1431.433960,233.123245 1440.238037,233.123245 1450.018066,233.123245 
	C1449.241943,241.769623 1448.356812,249.936066 1447.893799,258.126404 
	C1447.793823,259.894775 1449.213745,261.749084 1450.701416,263.296265 
z"
        />
        <path
          fill="#F8FAFB"
          opacity="1.000000"
          stroke="none"
          d="
M1352.935303,281.309387 
	C1345.008789,282.948975 1337.870117,284.373047 1329.312134,286.080231 
	C1336.521484,294.930542 1342.921265,302.786957 1349.832031,311.270752 
	C1341.510498,316.345337 1333.941284,320.961121 1325.647583,326.018738 
	C1320.842773,316.862823 1316.428467,308.451111 1311.383301,298.837372 
	C1306.314941,308.613495 1301.946045,317.040527 1297.319092,325.965118 
	C1289.032715,320.903534 1281.420776,316.253876 1273.209717,311.238373 
	C1280.010742,302.968170 1286.291138,295.330841 1293.614380,286.425507 
	C1282.814209,283.949158 1273.351929,281.779602 1262.840210,279.369385 
	C1265.476196,270.230652 1267.923584,261.746307 1270.592896,252.492264 
	C1280.380859,256.470825 1289.507080,260.180450 1300.110352,264.490417 
	C1299.267334,253.486923 1298.505737,243.545471 1297.706665,233.113815 
	C1307.453979,233.113815 1316.263062,233.113815 1325.594482,233.113815 
	C1324.658325,243.571732 1323.778687,253.398514 1322.778320,264.575226 
	C1332.911499,260.418213 1341.995483,256.691650 1352.215454,252.499039 
	C1354.916504,261.259338 1357.507690,269.663422 1360.288818,278.683655 
	C1357.817383,279.590942 1355.770264,280.342407 1352.935303,281.309387 
z"
        />
        <path
          fill="#FAFBFC"
          opacity="1.000000"
          stroke="none"
          d="
M1605.431396,100.384018 
	C1606.698608,104.976517 1607.731934,108.827126 1609.094360,113.903358 
	C1599.101074,116.140671 1589.292114,118.336754 1578.221069,120.815346 
	C1585.215210,129.255432 1591.531738,136.877960 1598.720337,145.552841 
	C1590.654419,150.578613 1582.979370,155.360809 1574.619019,160.570053 
	C1569.870728,151.529266 1565.470459,143.151154 1560.359863,133.420303 
	C1555.456909,142.878036 1551.221802,151.047577 1546.329590,160.484451 
	C1538.302246,155.588928 1530.567383,150.871735 1522.137695,145.730820 
	C1529.101807,137.236298 1535.519409,129.408478 1542.605103,120.765778 
	C1531.638672,118.354774 1522.067261,116.250450 1511.640747,113.958153 
	C1514.355591,104.702301 1516.821045,96.296394 1519.553589,86.979866 
	C1529.604248,91.080269 1538.643921,94.768204 1549.219238,99.082680 
	C1548.209595,87.892426 1547.353882,78.407867 1546.428711,68.152786 
	C1555.521606,68.152786 1564.300171,68.152786 1574.315674,68.152786 
	C1573.474854,78.018898 1572.643433,87.775215 1571.671753,99.176422 
	C1581.997803,94.900787 1591.065308,91.146248 1601.021729,87.023651 
	C1602.560669,91.674240 1603.879150,95.658188 1605.431396,100.384018 
z"
        />
        <path
          fill="#F9FAFB"
          opacity="1.000000"
          stroke="none"
          d="
M1326.556641,97.640503 
	C1335.292114,94.081902 1343.203979,90.742020 1352.249756,86.923454 
	C1355.025269,96.057648 1357.572388,104.440422 1360.445923,113.897530 
	C1350.150269,116.169266 1340.532959,118.291306 1329.559937,120.712502 
	C1336.398315,129.188400 1342.773804,137.090485 1349.691772,145.665009 
	C1341.851440,150.531448 1334.305298,155.215256 1325.744629,160.528809 
	C1320.951782,151.470184 1316.394897,142.857712 1311.309448,133.246216 
	C1306.415161,142.838974 1302.198730,151.103043 1297.439941,160.430145 
	C1289.223145,155.468048 1281.471191,150.786667 1273.129272,145.748978 
	C1280.168335,137.249710 1286.490723,129.615799 1293.755005,120.844528 
	C1282.940308,118.421371 1273.204224,116.239922 1262.808350,113.910614 
	C1265.441772,104.810493 1267.816162,96.604919 1270.540405,87.190460 
	C1280.476196,91.088257 1289.833008,94.758957 1300.295776,98.863487 
	C1299.338379,88.091721 1298.491333,78.560547 1297.569702,68.189865 
	C1306.862915,68.189865 1315.631226,68.189865 1325.450317,68.189865 
	C1324.750854,76.356453 1324.011719,84.470688 1323.390625,92.593948 
	C1323.231812,94.671097 1321.889282,97.478477 1326.556641,97.640503 
z"
        />
        <path
          fill="#FAFBFC"
          opacity="1.000000"
          stroke="none"
          d="
M1389.618530,114.396194 
	C1390.552979,105.477219 1392.241943,96.886932 1394.255249,86.646980 
	C1404.639404,90.899742 1413.663452,94.595528 1423.487793,98.619026 
	C1425.694702,88.051208 1421.627686,78.441917 1422.258179,68.090790 
	C1431.384644,68.090790 1440.156250,68.090790 1449.871460,68.090790 
	C1449.064087,78.105530 1448.280273,87.827690 1447.368652,99.135651 
	C1457.927368,94.759087 1466.993164,91.001328 1476.868042,86.908234 
	C1479.596191,96.191673 1482.010620,104.407303 1484.807617,113.925110 
	C1474.748535,116.131653 1465.145996,118.238052 1454.037354,120.674812 
	C1460.801636,129.085693 1467.109253,136.928696 1474.116333,145.641388 
	C1466.419922,150.447662 1458.924438,155.128403 1450.394531,160.455154 
	C1445.562378,151.635330 1440.902222,143.129684 1435.663940,133.568665 
	C1430.768921,143.084290 1426.556519,151.272629 1421.847046,160.427505 
	C1413.551147,155.396149 1405.779297,150.682709 1397.600342,145.722290 
	C1404.652954,137.202621 1410.987915,129.549820 1418.254883,120.771210 
	C1408.150513,118.580032 1399.261841,116.652451 1389.618530,114.396194 
z"
        />
        <path
          fill="#FDFDFD"
          opacity="1.000000"
          stroke="none"
          d="
M848.326416,1992.000000 
	C848.326416,1787.036377 848.258179,1583.072632 848.429626,1379.109131 
	C848.447205,1358.182373 850.675903,1337.439453 859.395813,1317.900391 
	C865.558899,1304.090820 874.243713,1292.865234 888.232544,1286.216919 
	C912.140320,1274.854370 941.651184,1283.520996 955.466980,1306.033691 
	C963.799072,1319.610840 968.346741,1334.492920 970.035950,1350.161133 
	C971.172119,1360.699951 971.605896,1371.361694 971.609436,1381.968628 
	C971.692566,1627.924805 971.660339,1873.880981 971.698425,2119.837158 
	C971.701477,2139.707031 967.452209,2158.629395 959.683899,2176.802734 
	C946.494873,2207.657227 913.036255,2215.874268 887.141907,2203.666748 
	C880.526550,2200.548096 874.456177,2194.938965 869.865967,2189.110840 
	C856.506287,2172.147949 850.062256,2152.305908 849.736694,2130.789795 
	C849.041870,2084.863281 848.770935,2038.930298 848.326416,1992.000000 
z"
        />
        <path
          fill="#FDFDFD"
          opacity="1.000000"
          stroke="none"
          d="
M200.325195,1578.000000 
	C200.325195,1486.405884 200.325195,1395.811768 200.325195,1304.373047 
	C220.755814,1303.249756 239.936264,1305.833740 258.884796,1311.015137 
	C271.906128,1314.575806 283.811462,1320.377075 294.397491,1328.680908 
	C310.689911,1341.461304 320.197235,1358.750366 326.157837,1378.181519 
	C332.618042,1399.241211 335.673096,1420.873657 336.333923,1442.857178 
	C337.042755,1466.440674 338.348053,1490.031494 338.172119,1513.609985 
	C337.949219,1543.480469 337.347168,1573.486938 329.664154,1602.611694 
	C326.725250,1613.752563 322.609650,1624.842651 317.273193,1635.036133 
	C306.888092,1654.872925 289.611633,1667.023926 268.521851,1673.636841 
	C246.759995,1680.460571 224.299408,1682.192871 200.325180,1682.102295 
	C200.325180,1647.398438 200.325180,1613.199219 200.325195,1578.000000 
z"
        />
      </svg>
    </Box>
  );
}
