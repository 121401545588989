import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

LogoStanislaus.propTypes = {
  sx: PropTypes.object
};

export default function LogoStanislaus({ sx }) {
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <Box
      sx={{
        width: 200,
        maxHeight: 'auto',
        cursor: 'pointer',
        ...sx
      }}
    >
      <svg width="124" height="86" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m62.354 45.568.488 1.229.447-1.244.605-1.679.496-1.378-1.236.787a5.62 5.62 0 0 1-.97.494l-.468.183.185.467.453 1.141Z"
          fill="#fff"
          stroke="#fff"
        />
        <path
          d="m31.594 33.498.287.885h.043c.047.114.095.228.145.342l1.3 2.967 1.421-2.911.195-.398h5.099l-1.267 2.012H90.79l-6.1-2.859a370.39 370.39 0 0 0-3.596-1.654c-3.284-1.496-6.256-2.85-8.548-4.25-1.544-.942-2.624-1.818-3.244-2.644-.577-.768-.708-1.415-.53-2.091.342-1.227.67-1.929 1.218-2.392.534-.452 1.532-.882 3.695-.882h.017l.078.001c.07.002.178.005.317.012.279.012.679.038 1.153.09.962.107 2.173.315 3.285.711 1.137.405 1.991.945 2.456 1.598.4.562.65 1.397.18 2.815l-1.413 4.092-.686 1.99h33.979l.146-1.338c.52-4.752.056-11.88-5.581-17.805C101.999 5.886 91.545 1.5 72.838 1.5V3l-.001-1.5h-.111l-.303.005c-.262.005-.644.014-1.13.033-.97.039-2.357.115-4.034.266-3.35.303-7.883.91-12.589 2.129-9.235 2.393-19.832 7.335-22.56 17.706-1.553 4.383-1.66 8.196-.622 11.53v.01l.076.23.03.089Z"
          fill="#fff"
          stroke="#fff"
          strokeWidth="3"
          strokeMiterlimit="16"
        />
        <path
          d="m75.374 45.568.488 1.229.448-1.244.603-1.678.495-1.375-1.233.783c-.299.19-.62.355-.972.494l-.467.183.185.466.453 1.142Z"
          fill="#fff"
          stroke="#fff"
        />
        <path
          d="m40.57 36.479-1.278-.838-.814 1.293-1.56 2.48-.692 1.098.988.842c.262.223.53.443.803.658l1.268.997.903-1.337 1.696-2.514.85-1.26-1.27-.833-.894-.586Z"
          fill="#fff"
          stroke="#fff"
          strokeWidth="3"
        />
        <path
          d="m101.175 44.265-.975 2.781h-4.183L97.22 43.7l-3.791 2.54c-1.744 1.169-3.79 1.245-3.954 1.25h-.016c-.33 0-1.38-.027-2.51-.306-1.17-.288-2.146-.78-2.66-1.502l-.003-.002a2.143 2.143 0 0 1-.107-.168L82.547 42.7l-1.079 3.068-.45 1.28h-9.89l-.176-.45-1.471-3.759-1.34 3.808-.142.4h-9.89l-.176-.449-1.471-3.763-1.341 3.811-.141.401h-8.968l7.136 2.89c.7.284 1.39.561 2.067.833 4.22 1.693 7.931 3.182 10.456 4.846 1.454.959 2.312 1.852 2.69 2.688.332.737.371 1.602-.247 2.804-1.547 2.966-4.235 5.404-7.842 5.41h-.011a12.927 12.927 0 0 1-.396-.018c-.279-.016-.68-.047-1.155-.104-.962-.116-2.177-.336-3.292-.742-1.141-.414-2-.96-2.467-1.61-.4-.558-.645-1.374-.177-2.75l2.12-6.219.677-1.984H5.971l4.207 2.755 10.62 6.953c.682 4.788 3 10.15 8.885 14.347 6.124 4.367 15.948 7.356 31.422 7.356V83c0 1.5.001 1.5.002 1.5h.032l.082-.001.306-.006c.266-.006.65-.018 1.14-.042a64.87 64.87 0 0 0 4.07-.336c3.384-.383 7.974-1.15 12.777-2.69 9.54-3.06 20.273-9.303 23.594-22.015 1.603-4.548 1.295-8.49-.36-11.904l-1.573-3.242Z"
          fill="#fff"
          stroke="#fff"
          strokeWidth="3"
          strokeMiterlimit="16"
        />
        <path
          d="m53.7 33.421-8.053-5.389.274 9.686.124 4.351 3.413.546.042.028.013-.02 6.235.997 1.237-3.44 1.322-3.676-3.246-2.172-1.36-.91Z"
          fill="#fff"
          stroke="#fff"
          strokeWidth="10"
        />
        <path
          d="m39.477 36.553 2.333 1.531-6.006 8.899-.526.78h6.054l.147-.233.61-.967h2.58l-.011.69-.01.51H49.83l.743.743-.035-1.257-.316-11.128-.014-.486H38.08l1.398.918Zm5.376 7.563-1.304.01 1.426-2.24-.122 2.23Z"
          fill="#fff"
          stroke="#fff"
        />
        <path
          d="m64.025 37.25.353-.352h.001l.003.003.006.006.016.017.05.057c.04.047.094.113.153.198.12.17.267.417.39.737.245.642.382 1.558.014 2.689-.14.631-.544 1.182-.871 1.55a6.014 6.014 0 0 1-.652.628l-.013.01-.004.004h-.001v.001l-.309-.394.308.394-.002.002c-.63.487-1.347.773-2.003.95l1.32 3.33.273.684h-5.348l-.125-.318-.982-2.512-.878 2.496-.118.334H49.851l.24-.67 3.213-8.93-2.41-1.613-1.367-.915h11.131a6.15 6.15 0 0 1 2.724.608c.313.154.553.308.717.427a3.445 3.445 0 0 1 .244.194l.016.014.006.005.002.002v.001l-.342.364Zm0 0 .353-.353-.01-.01-.343.364Zm-4.33 2.974a3.197 3.197 0 0 1-.824.103h-.64l.362-1.02h.783c.252 0 .401.046.473.076a.413.413 0 0 1 .053.027.317.317 0 0 1 .077.107c.005.015.011.04.011.131l-.003.028a1.208 1.208 0 0 1-.093.29c-.073.153-.15.231-.2.258Z"
          fill="#fff"
          stroke="#fff"
        />
        <path
          d="M77.044 37.25c.353-.353.353-.353.354-.352h.001l.002.003.006.006.016.017.05.057c.04.047.094.113.153.198.12.17.267.417.39.737.245.641.382 1.556.015 2.685-.138.633-.543 1.185-.871 1.554a6.035 6.035 0 0 1-.652.628l-.013.01-.004.003-.001.002-.309-.394.308.394-.002.002c-.631.487-1.347.773-2.003.95l1.32 3.33.272.684H70.73l-.124-.318-.983-2.512-.878 2.496-.118.334H62.87l.241-.67 3.213-8.93-2.41-1.613-1.368-.915H73.678c1.182 0 2.098.301 2.724.608.312.154.552.308.716.427a3.45 3.45 0 0 1 .245.194l.016.014.005.005.002.002.001.001-.343.364Zm0 0 .353-.353-.01-.01-.343.364Zm-4.176 2.133a.4.4 0 0 1 .053.027.32.32 0 0 1 .078.107c.005.015.011.04.011.131 0 0 0 .01-.003.028a1.213 1.213 0 0 1-.093.29c-.073.154-.152.232-.201.258l-.013.005a1.241 1.241 0 0 1-.133.036 3.194 3.194 0 0 1-.676.062h-.64l.361-1.02h.783c.253 0 .402.046.473.076Z"
          fill="#fff"
          stroke="#fff"
        />
        <path
          d="m85.676 36.302.234-.666H75.567l1.367.915 2.41 1.613-3.212 8.93-.241.67h5.756l.118-.335 3.912-11.127ZM89.579 47.705l.004.5h.002l-.006-.5.007.5h.02l.049-.002.174-.01a9.343 9.343 0 0 0 2.568-.576c1.495-.572 3.228-1.72 4.007-4v-.001l.99-2.923c.45-1.322.31-2.387-.24-3.211-.533-.797-1.399-1.286-2.246-1.593-.855-.31-1.753-.456-2.427-.526a12.585 12.585 0 0 0-1.136-.065h-.025l.005.5-.006-.5h-.001l.007.5-.008-.5h-.02l-.049.003a8.372 8.372 0 0 0-.788.078 9.344 9.344 0 0 0-1.954.508c-1.495.571-3.228 1.72-4.007 4l-.988 2.923c-.456 1.316-.32 2.38.23 3.202.532.796 1.398 1.286 2.246 1.595.856.31 1.756.459 2.431.53a12.747 12.747 0 0 0 1.14.068h.024l-.003-.5Zm-.98-3.157v-.002l1.578-4.523.008-.023.006-.024c.191-.793.55-1.135.81-1.29a1.176 1.176 0 0 1 .442-.157h.056c.037.003.09.006.154.013.132.015.289.042.426.091.146.052.212.108.234.138.002.003.034.035-.01.17l-1.577 4.518-.008.023-.006.025c-.19.79-.553 1.132-.818 1.288a1.21 1.21 0 0 1-.453.158l-.014.001h-.004l-.04-.001a2.472 2.472 0 0 1-.154-.013 1.929 1.929 0 0 1-.423-.09c-.144-.052-.205-.106-.224-.133a.054.054 0 0 1-.01-.029.343.343 0 0 1 .027-.14Z"
          fill="#fff"
          stroke="#fff"
        />
        <path
          d="m109.59 36.888-.344.363c.344-.363.343-.364.343-.364h-.001l-.002-.003-.005-.005-.016-.014a3.643 3.643 0 0 0-.244-.194 4.99 4.99 0 0 0-.717-.427 6.147 6.147 0 0 0-2.723-.608H94.748l1.367.915 2.41 1.613-3.212 8.93-.24.67h5.755l.118-.334.878-2.496.982 2.512.124.318H108.279l-.272-.685-1.321-3.33c.656-.176 1.372-.462 2.002-.949l.003-.002-.308-.394.308.394h.001l.001-.002.004-.003.013-.01.043-.036a5.896 5.896 0 0 0 .608-.592c.329-.37.735-.922.873-1.556.365-1.128.229-2.042-.017-2.683a3.47 3.47 0 0 0-.309-.618l.056-.056-.374-.354Zm-4.674 3.336a3.188 3.188 0 0 1-.823.103h-.639l.361-1.02h.783c.253 0 .401.046.473.076.026.011.044.02.053.026a.322.322 0 0 1 .077.108c.005.014.011.04.011.131l-.003.028a1.184 1.184 0 0 1-.093.29c-.073.152-.151.23-.2.258Z"
          fill="#fff"
          stroke="#fff"
        />
        <path
          d="m117.856 39.43.003-.008.21-.607c.044-.136.012-.168.01-.17-.022-.03-.087-.086-.233-.138a1.966 1.966 0 0 0-.426-.091 2.502 2.502 0 0 0-.195-.014h-.008m.639 1.029-.165.475c-.898-.425-1.561-.823-1.421-1.351.106-.38.253-.653.947-.653v.5m.639 1.029a8.68 8.68 0 0 1-.546-.28c-.263-.15-.424-.273-.508-.372-.038-.046-.047-.072-.05-.08-.001-.002-.001-.005.001-.014.049-.173.08-.206.093-.217.008-.007.08-.066.37-.066h.001m.639 1.029-.639-1.029m2.047 1.683H123.324l.048-.446c.082-.75.012-1.907-.905-2.87-.911-.957-2.557-1.618-5.376-1.618v.496l-.001-.496h-.019l-.047.001a12.09 12.09 0 0 0-.789.046c-.508.046-1.198.138-1.917.324-1.381.358-3.1 1.126-3.553 2.822-.202.574-.253 1.104-.164 1.592.091.494.321.91.627 1.26.595.681 1.503 1.136 2.332 1.491l.341.143h-4.712l-.078.406c-.126.668-.201 1.904.653 2.976.857 1.077 2.537 1.845 5.581 1.845v-.5.5h.02a5.132 5.132 0 0 0 .226-.008 10.268 10.268 0 0 0 2.578-.463c1.452-.465 3.159-1.438 3.692-3.458.172-.493.21-.96.125-1.395a2.541 2.541 0 0 0-.563-1.144c-.527-.633-1.328-1.095-2.076-1.464l-.083-.04Zm-2.173-4.434s-5.112 0-5.848 2.84c-.855 2.393 1.438 3.312 3.306 4.061 1.35.541 2.478.994 2.037 1.85-.252.484-.715.926-1.367.926l1.872-9.677Zm-.949 8.52c-.203.39-.525.654-.919.657h-.006l-.041-.003a2.506 2.506 0 0 1-.155-.014 2.012 2.012 0 0 1-.428-.095c-.147-.054-.214-.11-.237-.141-.002-.002-.033-.03.011-.16l.315-.924.104-.304c.379.156.701.298.951.443.262.151.382.27.426.348.019.033.041.072-.021.194ZM15.815 47.446l-.031.53H22.634l.145-.248 3.157-5.398v5.646H32.49l.148-.234 8.404-13.352.483-.766h-6.897l-.137.28-2.138 4.383-.017-4.165-.002-.498h-6.432l-.146.239-2.74 4.478.383-4.171.05-.546H10.976l1.407.919 4.048 2.642-.615 10.261Z"
          fill="#fff"
          stroke="#fff"
        />
        <path
          d="m62.82 45.384.603-1.679a6.117 6.117 0 0 1-1.057.537l.453 1.142ZM32.988 32.883v.055c.134.404.285.799.455 1.185l.605-1.24H42.8l-1.266 2.012h42.52c-9.17-4.297-18.026-7.526-16.729-12.391.707-2.544 1.697-4.381 6.36-4.381 0 0 11.308 0 8.906 7.209l-1.415 4.1h30.53C112.695 20.384 109.869 3 72.838 3c0 0-34.346 0-39.292 19.08-1.513 4.235-1.557 7.782-.575 10.803h.017ZM75.839 45.384l.604-1.678a6.164 6.164 0 0 1-1.057.536l.453 1.142ZM39.748 37.733l-1.561 2.48c.247.21.499.416.757.62l1.697-2.514-.893-.586ZM101.263 48.546h-7.38l.382-1.06c-2.156 1.444-4.62 1.5-4.775 1.502h-.03c-.778 0-4.741-.117-6.392-2.437a3.606 3.606 0 0 1-.185-.286l-.802 2.281H70.104l-.549-1.402-.493 1.402H57.085l-.55-1.403-.493 1.403h-2.331c9.93 4.022 18.92 7.072 15.734 13.251-1.694 3.252-4.805 6.22-9.185 6.22 0 0-11.308-.14-8.906-7.207l2.12-6.22H11l11.198 7.333C23.224 71.155 30.486 83 61.105 83c0 0 34.347 0 40.567-24.03 1.504-4.226 1.2-7.77-.274-10.81l-.135.386ZM50.92 37.577l.123 4.35 1.237-3.44-1.36-.91Z"
          fill="#DF1E26"
        />
        <path
          d="m50.038 47.263-.316-11.128h-9.97l2.755 1.81-6.289 9.318h4.838l.757-1.2h3.366l-.022 1.2h4.88Zm-7.404-2.63 2.945-4.627-.253 4.606-2.692.021ZM64.53 40.469c.672-2.04-.505-3.218-.505-3.218s-1.179-1.115-3.367-1.115h-9.486l2.735 1.83-3.345 9.298h4.69l1.325-3.766 1.473 3.766h4.27l-1.535-3.87c.757-.148 1.64-.421 2.376-.99 0 0 1.157-.904 1.367-1.935h.001Zm-4.629.21s-.315.148-1.03.148h-1.347l.715-2.02h1.137c.63 0 .862.231.862.231.231.232.252.4.252.61 0 .232-.189.842-.589 1.031Z"
          fill="#DF1E26"
        />
        <path
          d="M77.549 40.469c.672-2.04-.505-3.218-.505-3.218s-1.178-1.115-3.366-1.115h-9.486l2.734 1.83-3.345 9.298h4.691l1.325-3.766 1.473 3.766h4.27l-1.536-3.87c.757-.148 1.64-.421 2.377-.99 0 0 1.157-.904 1.367-1.935Zm-4.628.21s-.315.148-1.03.148h-1.347l.715-2.02h1.136c.631 0 .862.231.862.231.232.232.253.4.253.61 0 .232-.19.842-.59 1.031h.001Z"
          fill="#DF1E26"
        />
        <path
          d="M85.205 36.136H77.21l2.734 1.83-3.344 9.297h4.691l3.913-11.127ZM89.579 47.705s4.922-.064 6.352-4.25l.989-2.924c1.64-4.816-5.596-4.733-5.596-4.733s-4.922.064-6.352 4.25l-.988 2.924c-1.662 4.796 5.595 4.733 5.595 4.733Zm-1.452-3.324 1.578-4.522c.442-1.83 1.746-1.83 1.746-1.83s1.682 0 1.325 1.072l-1.578 4.523c-.441 1.83-1.767 1.83-1.767 1.83s-1.682 0-1.304-1.073Z"
          fill="#DF1E26"
        />
        <path
          d="M109.246 37.25s-1.178-1.114-3.365-1.114h-9.487l2.734 1.83-3.344 9.298h4.69l1.326-3.766 1.472 3.766h4.27l-1.536-3.87c.758-.148 1.641-.421 2.377-.99 0 0 1.157-.904 1.367-1.935.673-2.04-.505-3.218-.505-3.218h.001Zm-4.123 3.43s-.315.146-1.03.146h-1.347l.716-2.02h1.136c.631 0 .862.232.862.232.231.231.252.4.252.61 0 .231-.189.842-.589 1.031ZM118.332 39.585h4.543c.147-1.347-.273-3.934-5.784-3.934 0 0-5.112 0-5.848 2.84-1.472 4.123 6.395 3.87 5.343 5.91-.252.485-.715.927-1.367.927 0 0-1.683-.021-1.325-1.073l.315-.925h-4.606c-.232 1.219-.211 4.227 5.742 4.227 0 0 5.112 0 6.037-3.576 1.22-3.428-5.532-3.849-5.112-5.426.106-.38.253-.653.947-.653 0 0 1.683 0 1.325 1.073l-.21.61ZM16.314 47.476h6.033l4.089-6.992v6.992h5.78l8.404-13.352h-5.679l-3.079 6.31-.025-6.31h-5.654l-3.861 6.31.58-6.31H12.655l4.29 2.802-.63 10.55Z"
          fill="#DF1E26"
        />
      </svg>
    </Box>
  );
}
