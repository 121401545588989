import { createSlice } from '@reduxjs/toolkit';
import { omit, cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

// Firebase
import { db, arrayRemove, arrayUnion, serverTimestamp, fromDate } from 'config/firebase';
import { getZoneName } from 'utils/timeZoneUtils';
import { convertTimestampToTimezone, getUnix } from 'utils/date-utils';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  success: null,
  itemList: [],
  currentItem: null,
  isOpenModal: false,
  itemGroups: [],
  formikState: {}
};

const slice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // IS SUCCESS
    isSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },

    // GET ITEM LIST
    getItemListSuccess(state, action) {
      state.isLoading = false;
      state.itemList = action.payload;
      state.currentItem = null;
    },

    // GET ITEM
    getItemSuccess(state, action) {
      state.isLoading = false;
      state.currentItem = action.payload;
    },

    // CREATE ITEM
    getCreateItemSuccess(state, action) {
      const newItem = action.payload;
      state.isLoading = false;
      state.itemList = [...state.itemList, newItem];
    },

    // DELETE ITEM
    getDeleteItemSuccess(state, action) {
      const itemId = action.payload;
      state.isLoading = false;
      state.itemList = state.itemList.filter((item) => item.id !== itemId);
    },

    // DELETE MULTIPLE ITEMS
    getDeleteMultiItemSuccess(state, action) {
      const itemIds = action.payload;
      state.isLoading = false;
      state.itemList = state.itemList.filter((item) => !itemIds.includes(item.id));
    },

    // UPDATE ITEM
    getUpdateItemSuccess(state, action) {
      state.isLoading = false;
      state.currentItem.groups = action.payload.linkedGroups;
      state.currentItem.name = state.formikState.name;
      if (action.payload.publishDateChange === true) {
        const timeZoneString = convertTimestampToTimezone(getUnix(new Date()), getZoneName(), 'ZZ');
        const publishDate = new Date(
          `${action.payload.publishDate}T${action.payload.publishTime}:00.000${timeZoneString}`
        );
        state.currentItem.scheduledConfig.publishDate = fromDate(publishDate);
      }
    },

    // GET ITEMS FROM QUERY
    getItemsFromQuerySuccess(state, action) {
      state.isLoading = false;
      state.currentItemGroups = action.payload;
    },

    // CREATE ITEM SECTION
    getCreateItemSectionSuccess(state, action) {
      state.isLoading = false;
      state.currentItem = action.payload;

      // keep item name changes outside section content creation before save
      state.currentItem.name = state.formikState.name;
    },

    // DELETE ITEM SECTION
    getDeleteItemContentSuccess(state, action) {
      const { sectionId } = action.payload;
      state.isLoading = false;
      state.currentItem.content = omit(state.currentItem.content, [sectionId]);
      state.currentItem.contentOrder = state.currentItem.contentOrder.filter((item) => item !== sectionId);
      for (const item of state.currentItem.contentOrder) {
        state.currentItem.content[item].value = state.formikState[item];
      }

      // keep changes outside section content on update before save
      state.currentItemGroups = state.formikState.linkedGroups;
      state.currentItem.name = state.formikState.name;
    },

    persistFormikSuccess(state, action) {
      state.isLoading = false;
      state.formikState = action.payload;
      state.currentItem.groups = action.payload.linkedGroups;
    },

    // UPDATE CONTENT
    getUpdateItemContentSuccess(state, action) {
      const updatedItem = action.payload;
      state.isLoading = false;
      state.currentItem.content[updatedItem.id] = updatedItem;
      for (const item of state.currentItem.content) {
        state.currentItem.content[item].value = state.formikState[item];
      }
    },
    // PERSIST CONTENT ON DRAG
    persistContent(state, action) {
      state.isLoading = false;
      state.currentItem.contentOrder = action.payload;
      for (const item of state.currentItem.contentOrder) {
        state.currentItem.content[item].value = state.formikState[item];
      }
    },

    // GET Content Categories
    getItemGroupsSuccess(state, action) {
      state.isLoading = false;
      state.itemGroups = action.payload;
    },

    // CREATE Content Category
    getCreateItemGroupSuccess(state, action) {
      state.isLoading = false;
      state.itemGroups = [...state.itemGroups, action.payload];
    },

    // UPDATE Content Category
    getUpdateItemGroupSuccess(state, action) {
      state.isLoading = false;
      const groupIndex = state.itemGroups.findIndex((item) => item.id === action.payload.id);
      state.itemGroups[groupIndex] = action.payload;
    },

    // DELETE Content Category
    getDeleteItemGroupSuccess(state, action) {
      const itemGroupId = action.payload;
      state.isLoading = false;
      state.itemGroups = state.itemGroups.filter((group) => group.id !== itemGroupId);
    }
  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getItemList() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { client } = cloneDeep(getState());
    const clientRef = db.collection('clients').doc(client.currentClient.id);
    const itemRef = clientRef.collection('items');
    try {
      const items = await itemRef.get();
      const itemList = [];
      items.forEach(async (item) => itemList.push(item.data()));
      dispatch(slice.actions.getItemListSuccess(itemList));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getItemsFromQuery(values) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { client } = cloneDeep(getState());
    try {
      const currentItemGroups = [];

      if (values.length >= 1) {
        const query = db
          .collectionGroup('items')
          .where('groups', 'array-contains-any', values)
          .where('clientId', '==', client.currentClient.id);
        const result = await query.get();
        result.forEach((item) => currentItemGroups.push(item.data()));
      }
      dispatch(slice.actions.getItemsFromQuerySuccess(currentItemGroups));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getItem(itemId, clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const itemRef = await db.doc(`clients/${clientId}/items/${itemId}`).get();
      dispatch(slice.actions.getItemSuccess(itemRef.data()));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createItem(values) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { client } = cloneDeep(getState());

    try {
      let publishDate = null;
      let scheduleRef = null;
      if (values.isScheduled === true) {
        const timeZoneString = convertTimestampToTimezone(getUnix(new Date()), getZoneName(), 'ZZ');
        publishDate = new Date(`${values.publishDate}T${values.publishTime}:00.000${timeZoneString}`);
      }

      const itemRef = await db.collection(`clients`).doc(client.currentClient.id).collection('items').doc();
      const newItem = {
        name: values.name,
        clientId: client.currentClient.id,
        id: itemRef.id,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        contentOrder: [],
        content: {},
        ...(values.isScheduled === true && {
          scheduledConfig: {
            isScheduled: values.isScheduled,
            publishDate,
            isPublished: false
          }
        })
      };

      await itemRef.set(newItem);

      if (values.isScheduled === true) {
        scheduleRef = db.collection('scheduled').doc(itemRef.id);
        await scheduleRef.set({
          name: values.name,
          clientId: client.currentClient.id,
          isPublished: false,
          isScheduled: true,
          type: 'contentItem',
          publishDate,
          createdAt: serverTimestamp()
        });
      }

      dispatch(
        slice.actions.getCreateItemSuccess({
          ...newItem,
          updatedAt: new Date(),
          createdAt: new Date()
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateItem(values, itemId, clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const { name, linkedGroups, linkedWebview } = values;
    const contentKeys = Object.keys(values).filter(
      (content) =>
        content !== 'linkedGroups' &&
        content !== 'name' &&
        content !== 'publishNow' &&
        content !== 'publishDateChange' &&
        content !== 'publishDate' &&
        content !== 'publishTime' &&
        content !== 'undefined'
    );
    const tmp = {};
    contentKeys.forEach((cont) => {
      tmp[cont] = { value: values[cont] };
    });

    let publishDate = null;
    if (values.publishDateChange === true) {
      const timeZoneString = convertTimestampToTimezone(getUnix(new Date()), getZoneName(), 'ZZ');
      publishDate = new Date(`${values.publishDate}T${values.publishTime}:00.000${timeZoneString}`);
    }

    const firebaseObject = {
      name,
      linkedWebview,
      groups: linkedGroups,
      updatedAt: serverTimestamp(),
      content: {
        ...tmp
      },
      ...(values.publishDateChange === true && {
        scheduledConfig: {
          publishDate
        }
      }),
      ...(values.publishNow === true && {
        scheduledConfig: {
          isPublished: true,
          isScheduled: false,
          publishDate: serverTimestamp()
        }
      })
    };

    try {
      const clientRef = db.doc(`clients/${clientId}`);
      const itemRef = clientRef.collection('items').doc(itemId);

      let scheduleEntryRef = null;

      if (values.publishNow) {
        scheduleEntryRef = db.doc(`scheduled/${itemId}`);
        await scheduleEntryRef.set(
          {
            isPublished: true,
            isScheduled: false,
            publishDate: serverTimestamp()
          },
          { merge: true }
        );
      }

      await itemRef.set(firebaseObject, { merge: true });

      dispatch(slice.actions.getUpdateItemSuccess(values));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteItem(itemId) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { client } = cloneDeep(getState());

    try {
      await db.doc(`clients/${client.currentClient.id}/items/${itemId}`).delete();
      await db.doc(`scheduled/${itemId}`).delete();
      dispatch(slice.actions.getDeleteItemSuccess(itemId));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteMultiItems(itemIds) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { client } = cloneDeep(getState());

    try {
      const batch = db.batch();
      itemIds.forEach((id) => {
        const itemRef = db.doc(`clients/${client.currentClient.id}/items/${id}`);
        const scheduledItemRef = db.doc(`scheduled/${id}`);
        batch.delete(itemRef);
        batch.delete(scheduledItemRef);
      });
      await batch.commit();
      dispatch(slice.actions.getDeleteMultiItemSuccess(itemIds));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createItemSection(section, clientId, itemId) {
  return async (dispatch, getState) => {
    const { item } = cloneDeep(getState());
    // dispatch(slice.actions.startLoading());

    const contentIds = Object.keys(item.formikState).filter((ids) => ids !== 'name' && ids !== 'linkedGroups');
    contentIds.forEach(
      (id) => (item.currentItem.content[id] = { ...item.currentItem.content[id], value: item.formikState[id] })
    );

    try {
      section = { ...section, id: uuidv4() };
      await db.doc(`clients/${clientId}/items/${itemId}`).set(
        {
          updatedAt: serverTimestamp(),
          contentOrder: arrayUnion(section.id),
          content: {
            [section.id]: section
          }
        },
        { merge: true }
      );
      item.currentItem.contentOrder.push(section.id);
      item.currentItem.content[section.id] = section;

      dispatch(slice.actions.getCreateItemSectionSuccess(item.currentItem));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function persistFormikState(formikValues) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.persistFormikSuccess(formikValues));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function persistContent(newContentOrder, itemId, clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const itemRef = db.doc(`clients/${clientId}/items/${itemId}`);

    // TO DO: Rework this to catch if document write returns error
    dispatch(slice.actions.persistContent(newContentOrder));

    try {
      await itemRef.set({ updatedAt: serverTimestamp(), contentOrder: newContentOrder }, { merge: true });
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteItemContent(sectionId, currentItem, clientId) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { item } = cloneDeep(getState());
    try {
      await db.doc(`clients/${clientId}/items/${currentItem.id}`).update({
        ...item.currentItem,
        updatedAt: serverTimestamp(),
        contentOrder: arrayRemove(sectionId),
        content: omit(item.currentItem.content, [sectionId])
      });
      dispatch(slice.actions.getDeleteItemContentSuccess({ sectionId, linkedGroups: currentItem.groups }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getItemGroups(clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await db.collection(`clients/${clientId}/itemGroups`).get();
      const itemGroups = [];
      response.forEach((group) => itemGroups.push(group.data()));
      dispatch(slice.actions.getItemGroupsSuccess(itemGroups));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateItemGroup(values, clientId, groupId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const itemGroupRef = db.collection(`clients/${clientId}/itemGroups`).doc(groupId);
      await itemGroupRef.set({ ...values }, { merge: true });
      dispatch(slice.actions.getUpdateItemGroupSuccess({ ...values, itemIds: [], id: groupId }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createItemGroup(values, clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const { id } = values;
    try {
      const itemGroupRef = db.collection(`clients/${clientId}/itemGroups`).doc(id);
      const firebaseObject = {
        ...values,
        itemIds: []
      };
      await itemGroupRef.set(firebaseObject, { merge: true });

      dispatch(slice.actions.getCreateItemGroupSuccess(firebaseObject));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteItemGroup(itemGroup, clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const { id } = itemGroup;
    try {
      await db.doc(`clients/${clientId}/itemGroups/${id}`).delete();
      dispatch(slice.actions.getDeleteItemGroupSuccess(id));
      dispatch(slice.actions.isSuccess('Category deleted'));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
