export const getZoneName = () => {
  const localTimeZone = new Date()
    .toLocaleDateString('en-US', {
      day: '2-digit',
      timeZoneName: 'long'
    })
    .slice(4);

  // https://docs.oracle.com/middleware/1221/wcs/tag-ref/MISC/TimeZones.html

  switch (localTimeZone) {
    // covers Guam, American Samoa
    case 'Chamorro Standard Time':
      return 'Pacific/Guam';
    case 'Hawaii-Aleutian Standard Time':
      return 'US/Hawaii';
    case 'Alaska Standard Time':
      return 'America/Anchorage';
    case 'Alaska Daylight Time':
      return 'America/Anchorage';
    case 'Pacific Standard Time':
      return 'America/Los_Angeles';
    case 'Pacific Daylight Time':
      return 'America/Los_Angeles';
    case 'Mountain Standard Time':
      return 'America/Phoenix';
    case 'Mountain Daylight Time':
      return 'America/Denver';
    case 'Central Standard Time':
      return 'America/Chicago';
    case 'Central Daylight Time':
      return 'America/Chicago';
    case 'Eastern Standard Time':
      return 'America/New_York';
    case 'Eastern Daylight Time':
      return 'America/New_York';
    // covers Puerto Rico, US virgin islands
    case 'Atlantic Standard Time':
      return 'America/Puerto_Rico';
    default:
      return 'America/Phoenix';
  }
};
