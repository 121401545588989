import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

LogoNordecke.propTypes = {
  sx: PropTypes.object
};

export default function LogoNordecke({ sx }) {
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box
      sx={{
        width: 200,
        maxHeight: 'auto',
        cursor: 'pointer',
        ...sx
      }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 228 229"
        enableBackground="new 0 0 228 229"
      >
        <path
          fill="#FEFEFD"
          opacity="1.000000"
          stroke="none"
          d="
M229.000000,86.000000 
	C229.000000,133.166672 228.964386,179.833420 229.083344,226.499786 
	C229.090958,229.482864 228.482239,230.086426 225.499878,230.082184 
	C151.833359,229.977249 78.166634,229.977249 4.500117,230.082169 
	C1.517761,230.086411 0.913602,229.482239 0.917826,226.499878 
	C1.022640,152.500031 1.022640,78.499969 0.917826,4.500116 
	C0.913602,1.517765 1.517761,0.913569 4.500117,0.917817 
	C78.166634,1.022739 151.833359,1.022739 225.499878,0.917817 
	C228.482239,0.913569 229.101471,1.515609 229.085342,4.500461 
	C228.939545,31.499674 229.000000,58.500000 229.000000,86.000000 
z"
        />
        <path
          fill="#040404"
          opacity="1.000000"
          stroke="none"
          d="
M32.373978,181.862854 
	C30.064829,182.494644 29.767019,180.589325 28.994566,179.581482 
	C-20.248440,115.331573 18.488831,19.736624 98.566711,7.818561 
	C156.874130,-0.859386 210.029999,35.951111 221.459732,93.939896 
	C227.721313,125.708069 220.704498,155.025696 200.373962,180.931351 
	C198.215530,180.536530 197.064133,178.832077 194.858673,178.406235 
	C190.166702,178.237747 185.835953,178.419312 181.509750,178.281372 
	C179.729889,178.224625 177.544510,178.542282 176.737381,176.439880 
	C175.951263,174.392258 177.685883,173.060501 178.903503,171.751343 
	C190.489517,159.294464 197.572464,144.548828 199.870132,127.816002 
	C204.151764,96.635284 194.528641,70.191559 170.778503,49.462727 
	C139.984314,22.585951 93.428810,22.483488 62.942104,47.044220 
	C43.333176,62.841583 31.970707,83.054489 30.207602,108.583107 
	C28.583597,132.097595 35.642574,152.451065 50.567806,170.396973 
	C52.290115,172.467850 55.472080,174.622787 54.082779,177.551895 
	C52.676403,180.517014 49.017563,179.097733 46.310219,179.285797 
	C43.824223,179.458496 41.317902,179.301270 38.457054,179.348633 
	C35.743942,178.879074 34.766396,181.607605 32.373978,181.862854 
z"
        />
        <path
          fill="#0C0B03"
          opacity="1.000000"
          stroke="none"
          d="
M197.193710,217.647842 
	C196.960037,217.984863 196.508209,218.148865 196.272491,218.101929 
	C194.903412,217.023788 196.140839,215.285400 195.025208,214.252640 
	C196.048447,215.226517 195.239014,216.490036 195.362045,217.954895 
	C195.014618,218.760437 195.149597,219.564148 194.107605,219.245239 
	C193.057541,217.307434 193.611816,215.461441 193.658905,213.797913 
	C193.019409,215.059830 194.152191,216.878693 192.354370,218.111526 
	C191.950439,218.065842 191.991989,218.032211 192.008484,218.011169 
	C190.933136,216.826065 192.019379,215.211975 191.185318,213.911758 
	C191.087601,213.334518 190.638901,213.626465 190.923782,213.862549 
	C192.051071,214.796753 191.286987,216.202347 191.300415,217.762329 
	C190.893005,218.628387 191.184708,219.468231 190.098175,219.394211 
	C189.362595,217.912476 188.922516,216.550186 188.204956,215.027512 
	C187.496368,214.560471 186.978714,214.426453 186.164627,214.732651 
	C185.068253,215.595383 185.125717,216.811310 184.188629,217.843658 
	C183.968460,218.090088 183.574631,218.305038 183.353455,218.342438 
	C182.112076,217.088440 182.615311,215.750122 182.564377,214.383469 
	C182.385422,215.682159 182.564316,217.067856 182.291092,218.781601 
	C182.161392,219.157272 182.004639,219.051224 182.074219,219.115494 
	C181.950546,219.217056 181.757278,219.254349 181.316879,219.130066 
	C181.069763,218.968491 180.943497,218.528458 180.879822,218.308594 
	C180.127960,216.632874 180.895233,214.994858 180.256561,213.533295 
	C180.671387,214.792465 180.432922,216.084824 180.263107,217.720016 
	C180.083893,218.075546 180.077408,218.006775 180.043610,217.999603 
	C176.838531,217.902298 179.800201,214.724670 178.000610,214.071030 
	C177.847153,213.301788 177.608185,214.533600 178.112579,214.097672 
	C178.867188,215.478409 178.354324,216.960403 178.247772,218.735474 
	C175.817108,219.831573 176.830597,217.923599 176.801468,217.334366 
	C176.615585,213.572693 175.688278,209.809692 176.568466,206.666061 
	C136.824966,230.121124 96.770752,231.936493 56.105247,207.697998 
	C55.452675,211.689514 56.788788,216.295456 55.188194,220.495758 
	C54.016239,218.874405 54.833824,217.441833 54.501957,216.281921 
	C54.283531,216.933121 54.733051,218.073181 53.811134,219.119843 
	C51.536903,219.005905 52.143646,214.915649 48.743782,216.269516 
	C48.156754,216.580765 47.562428,215.568344 47.728794,216.247955 
	C48.098740,217.759171 48.086800,219.102509 47.047630,220.611633 
	C46.481068,220.440659 46.189583,220.101227 45.845413,219.516296 
	C45.189552,217.755524 45.790993,216.117249 45.209980,214.122757 
	C45.631683,215.885300 45.441174,217.199127 45.268867,218.876709 
	C44.107533,219.725708 43.127258,220.204056 42.021606,220.421921 
	C41.199974,218.416229 41.425674,216.689392 41.741783,215.180099 
	C40.770256,216.124451 42.237175,218.086533 40.345642,219.103745 
	C39.946823,219.044815 39.959526,219.081741 39.940105,219.083862 
	C39.061146,217.633423 39.867920,215.966080 39.354332,214.570480 
	C39.603607,215.767532 39.453884,217.076797 39.303474,218.752808 
	C38.998684,219.685043 38.847191,220.245438 38.400116,220.803497 
	C36.892570,219.725693 37.551701,218.421356 37.620106,217.303391 
	C37.883339,216.555267 36.530067,217.056259 37.194893,217.105759 
	C37.433960,217.123550 37.428860,217.236557 37.455448,217.399826 
	C37.717110,219.006409 36.446167,219.156708 35.196995,219.803513 
	C31.771677,221.319122 33.178398,218.512451 33.169209,217.714905 
	C33.040184,206.516113 33.062637,195.315567 33.081047,183.660645 
	C34.381767,180.035889 36.663605,178.739471 40.109650,178.958160 
	C43.072235,179.146149 46.065590,179.119125 49.442955,178.791077 
	C55.656551,176.970383 59.774376,179.508072 64.069939,183.313232 
	C76.739731,194.536514 92.002701,199.425598 108.711227,200.842010 
	C126.533745,202.352859 142.984070,198.376266 158.470016,189.789536 
	C162.562866,187.520096 166.166245,184.560074 169.404053,181.177887 
	C172.057159,178.406494 174.939011,176.889328 179.099335,177.393967 
	C183.523209,177.930588 188.061951,177.512436 192.946838,177.524170 
	C195.772522,177.931213 197.938278,178.688843 198.775085,181.799377 
	C199.166977,193.118225 199.468048,204.068771 199.634048,215.021378 
	C199.654144,216.347336 200.545029,218.042618 198.784485,219.055634 
	C198.675949,219.118088 198.468170,219.110626 198.360565,219.045914 
	C198.228714,218.966599 198.153793,218.792603 197.937393,218.375259 
	C197.185104,216.241852 197.678345,214.358002 197.555679,212.903275 
	C197.319702,214.085434 197.595932,215.708313 197.193710,217.647842 
z"
        />
        <path
          fill="#454015"
          opacity="1.000000"
          stroke="none"
          d="
M47.832863,216.256485 
	C49.171951,215.090790 49.945332,213.523636 51.732895,214.193634 
	C53.677155,214.922379 52.655518,216.689789 53.039455,218.371185 
	C53.061481,219.180679 52.864174,219.477509 52.211994,219.780334 
	C51.639626,220.950302 51.022636,220.832108 50.185467,220.116974 
	C48.322712,219.456192 47.073563,218.674820 47.832863,216.256485 
z"
        />
        <path
          fill="#D1CFBF"
          opacity="1.000000"
          stroke="none"
          d="
M49.713688,178.813507 
	C46.889450,180.047577 43.607574,179.325165 40.366749,179.479553 
	C37.635712,179.609634 35.138000,180.032181 33.414543,182.974915 
	C32.922813,182.909378 32.731808,182.612946 32.315575,182.178436 
	C33.475235,180.684921 34.341908,178.777496 36.678635,178.076813 
	C41.099800,179.064102 45.243290,178.194565 49.713688,178.813507 
z"
        />
        <path
          fill="#464117"
          opacity="1.000000"
          stroke="none"
          d="
M184.738190,213.503708 
	C186.005493,213.062271 187.096558,213.029465 188.597046,212.999039 
	C189.857574,214.444443 189.383255,216.006699 189.168457,217.916550 
	C187.411179,217.106094 186.656281,221.149094 184.896393,218.598907 
	C184.286285,216.874786 184.576385,215.385239 184.738190,213.503708 
z"
        />
        <path
          fill="#D6D5C9"
          opacity="1.000000"
          stroke="none"
          d="
M199.058929,181.933533 
	C197.609131,180.709763 196.565430,178.958237 194.237732,178.198441 
	C194.312271,177.458710 194.912827,177.546921 195.693207,177.327881 
	C197.841736,177.513077 198.689987,179.100311 199.915558,180.674103 
	C199.760483,181.215744 199.485764,181.457886 199.058929,181.933533 
z"
        />
        <path
          fill="#837D46"
          opacity="1.000000"
          stroke="none"
          d="
M35.285133,219.984009 
	C35.849232,218.286179 37.935741,217.149292 38.049374,214.795364 
	C38.049374,216.529510 38.049374,218.263641 38.076950,220.399475 
	C37.274632,220.508102 36.444729,220.215073 35.285133,219.984009 
z"
        />
        <path
          fill="#88824B"
          opacity="1.000000"
          stroke="none"
          d="
M48.008224,215.648392 
	C47.738262,217.501907 48.022602,218.742493 49.915504,219.550812 
	C48.994694,219.497620 48.250835,220.131958 47.127300,220.733749 
	C46.883984,218.963776 46.991634,217.147202 47.114964,215.331680 
	C47.123615,215.204330 47.248184,215.084839 47.318840,214.961685 
	C47.524807,215.093689 47.730778,215.225693 48.008224,215.648392 
z"
        />
        <path
          fill="#857F47"
          opacity="1.000000"
          stroke="none"
          d="
M52.318886,219.809555 
	C52.143639,219.598328 52.328480,219.266724 52.799194,218.847656 
	C55.042610,218.058548 53.320751,216.004730 54.913223,214.604553 
	C54.913223,216.457550 54.913223,217.948120 54.895355,219.857010 
	C54.144653,220.079956 53.411816,219.884567 52.318886,219.809555 
z"
        />
        <path
          fill="#7E7842"
          opacity="1.000000"
          stroke="none"
          d="
M39.981926,219.373077 
	C41.972755,218.224625 40.376915,216.028534 41.767506,214.409958 
	C41.870422,216.352203 41.949696,217.848251 41.962597,219.752869 
	C41.269825,220.008072 40.643429,219.854706 39.981926,219.373077 
z"
        />
        <path
          fill="#857F48"
          opacity="1.000000"
          stroke="none"
          d="
M178.356903,218.995758 
	C178.039719,217.310822 178.039719,215.543991 178.039719,213.538589 
	C179.609589,214.813080 178.431702,216.536331 179.669098,217.843201 
	C179.957947,218.869843 179.378891,218.983017 178.356903,218.995758 
z"
        />
        <path
          fill="#B3AE7F"
          opacity="1.000000"
          stroke="none"
          d="
M192.075821,218.346100 
	C194.276901,217.171021 192.098923,214.790421 193.507767,213.447754 
	C194.537949,215.020462 193.627441,216.695663 193.954620,218.662750 
	C193.372589,218.936371 192.786896,218.781372 192.075821,218.346100 
z"
        />
        <path
          fill="#7E783F"
          opacity="1.000000"
          stroke="none"
          d="
M188.982712,218.559540 
	C188.955353,216.835175 188.986954,215.360855 189.012512,213.443970 
	C190.798141,214.532410 189.618988,216.585236 189.942230,218.798920 
	C189.636917,219.086548 189.339294,218.948059 188.982712,218.559540 
z"
        />
        <path
          fill="#A49F6E"
          opacity="1.000000"
          stroke="none"
          d="
M197.171021,218.044952 
	C196.960037,216.376801 196.960037,214.768738 196.960037,212.026932 
	C198.501328,214.403275 197.708710,215.913162 197.831955,217.694672 
	C197.820526,218.090973 197.382004,218.105057 197.171021,218.044952 
z"
        />
        <path
          fill="#857F47"
          opacity="1.000000"
          stroke="none"
          d="
M182.407089,219.156342 
	C182.161392,217.415848 182.161392,215.674438 182.161392,213.611938 
	C183.456635,214.993942 182.672272,216.373062 183.062088,217.971436 
	C183.185181,218.769913 183.025345,219.028427 182.407089,219.156342 
z"
        />
        <path
          fill="#7E783F"
          opacity="1.000000"
          stroke="none"
          d="
M184.596680,213.295959 
	C184.911880,214.546783 184.909348,215.998489 184.855057,217.885178 
	C184.803299,218.320145 184.356094,218.315140 184.162277,218.202606 
	C184.071945,216.559006 184.175430,215.027924 184.596680,213.295959 
z"
        />
        <path
          fill="#817B42"
          opacity="1.000000"
          stroke="none"
          d="
M191.367554,218.103958 
	C191.145447,216.657928 191.145447,215.193665 191.145447,213.451416 
	C192.442413,214.727875 191.719315,216.061462 192.009979,217.598663 
	C192.024963,217.990143 191.589645,218.085739 191.367554,218.103958 
z"
        />
        <path
          fill="#8B864F"
          opacity="1.000000"
          stroke="none"
          d="
M195.494446,218.350647 
	C195.269867,216.844620 195.269867,215.364670 195.269867,213.738907 
	C196.420959,214.829590 195.653061,216.158386 196.001236,217.662186 
	C196.036789,218.054977 195.719025,218.376709 195.494446,218.350647 
z"
        />
        <path
          fill="#B8B386"
          opacity="1.000000"
          stroke="none"
          d="
M180.266586,218.098999 
	C180.083893,216.676651 180.083893,215.277756 180.083893,213.878860 
	C180.315170,213.852036 180.546432,213.825226 180.777710,213.798401 
	C180.841080,214.967804 180.904449,216.137222 180.891998,217.697678 
	C180.816147,218.088730 180.449280,218.122437 180.266586,218.098999 
z"
        />
        <path
          fill="#918C56"
          opacity="1.000000"
          stroke="none"
          d="
M39.342064,219.100174 
	C39.150177,217.691650 39.150177,216.258667 39.150177,214.825684 
	C39.311829,214.815933 39.473480,214.806183 39.635136,214.796432 
	C39.771587,215.969757 39.908039,217.143097 39.982590,218.701202 
	C39.920685,219.085999 39.533947,219.075714 39.342064,219.100174 
z"
        />
        <path
          fill="#7E783F"
          opacity="1.000000"
          stroke="none"
          d="
M45.262703,219.293243 
	C45.087807,217.818710 45.087807,216.390060 45.087807,214.961426 
	C45.362946,214.938797 45.638084,214.916168 45.913223,214.893539 
	C45.913223,216.082291 45.913223,217.271027 45.852974,218.865295 
	C45.792728,219.270828 45.437603,219.339142 45.262703,219.293243 
z"
        />
        <path
          fill="#857F47"
          opacity="1.000000"
          stroke="none"
          d="
M181.047516,219.256256 
	C181.247696,219.008713 181.425644,219.048950 181.873688,219.134491 
	C181.957413,219.873734 181.636124,220.153503 181.047516,219.256256 
z"
        />
        <path
          fill="#FBFBF7"
          opacity="1.000000"
          stroke="none"
          d="
M195.507065,177.020889 
	C195.458054,177.414749 194.973434,177.627426 194.225342,177.931213 
	C181.837860,176.607605 172.491821,180.765274 162.382141,188.293320 
	C133.409897,209.867096 87.327599,206.909241 62.058903,183.374786 
	C58.830093,180.367569 55.469696,178.361740 50.507858,179.071381 
	C45.940929,178.905472 41.791405,178.764236 37.335682,178.357208 
	C42.596489,177.093262 48.256416,177.705536 54.806145,177.383835 
	C32.919949,154.085007 23.989500,127.382767 30.279339,96.546867 
	C34.670238,75.020493 46.194004,57.656559 63.852520,44.545853 
	C98.610870,18.739235 147.918640,22.931179 177.655991,53.745518 
	C208.509476,85.716408 212.761932,138.354156 176.492981,176.839706 
	C183.336227,176.839706 189.203842,176.839706 195.507065,177.020889 
z"
        />
        <path
          fill="#D8C100"
          opacity="1.000000"
          stroke="none"
          d="
M100.039291,213.157303 
	C99.655075,215.146194 99.304092,216.722397 98.904999,218.514664 
	C95.819786,217.239532 96.639412,214.407593 95.545029,212.545502 
	C94.260658,213.946777 95.103409,216.455765 92.629326,217.923523 
	C92.030151,213.515640 93.334908,209.920593 94.092758,206.076782 
	C97.134888,207.055389 96.240143,209.830994 97.594696,211.744965 
	C98.196289,210.056946 98.739326,208.533234 99.388374,206.712082 
	C102.069603,208.778549 99.824097,210.874527 100.039291,213.157303 
z"
        />
        <path
          fill="#D9C300"
          opacity="1.000000"
          stroke="none"
          d="
M119.158440,217.852356 
	C118.515244,219.740723 117.411705,220.184937 117.115761,218.336884 
	C116.675186,215.585526 116.739082,212.725555 116.848732,209.921967 
	C116.952988,207.256348 119.189011,208.154144 120.641495,208.110397 
	C123.302109,208.030243 124.281197,209.954208 123.773170,212.061157 
	C123.215332,214.374680 123.088547,216.684326 123.151321,218.635162 
	C120.755287,220.473145 121.918915,214.329071 119.158440,217.852356 
z"
        />
        <path
          fill="#DAC300"
          opacity="1.000000"
          stroke="none"
          d="
M128.619568,211.517609 
	C127.837929,208.950027 127.735695,207.217102 130.788773,207.027405 
	C132.950226,206.893097 134.776321,206.927063 135.084015,209.693741 
	C135.318985,211.806549 135.984695,213.927643 135.860809,216.009460 
	C135.716583,218.432999 133.316223,218.142044 131.647232,218.534698 
	C129.338394,219.077881 129.408340,217.229202 129.159561,215.840836 
	C128.928329,214.550537 128.845840,213.233582 128.619568,211.517609 
z"
        />
        <path
          fill="#E0C900"
          opacity="1.000000"
          stroke="none"
          d="
M69.453384,204.537994 
	C68.281235,205.856522 68.099579,207.684830 66.259102,207.783295 
	C64.500687,205.839401 68.935432,203.974945 66.542496,202.562759 
	C64.552338,201.388306 64.637695,205.515808 61.880104,205.027512 
	C63.017361,201.234253 65.198456,198.317535 67.991776,195.560852 
	C70.088188,197.831696 65.543312,199.379410 67.679520,200.786255 
	C69.952644,202.283234 69.888199,197.900085 72.432617,198.241364 
	C72.750786,200.888290 70.455383,202.253922 69.453384,204.537994 
z"
        />
        <path
          fill="#E4CC00"
          opacity="1.000000"
          stroke="none"
          d="
M105.004013,216.711884 
	C105.045532,214.459747 105.010605,212.639053 105.194572,210.840759 
	C105.501419,207.841324 107.616425,207.659775 109.917221,208.136185 
	C111.703346,208.506042 112.287125,209.923920 111.975845,211.527527 
	C111.478317,214.090500 113.160408,217.518463 109.841980,218.966583 
	C108.244888,219.663528 105.804375,219.925262 105.004013,216.711884 
z"
        />
        <path
          fill="#D9C200"
          opacity="1.000000"
          stroke="none"
          d="
M73.450607,211.495605 
	C72.752037,209.065811 74.147827,207.429962 74.842133,205.621811 
	C77.051643,199.867737 77.102264,199.887177 82.527290,203.672974 
	C81.137512,205.636520 78.636620,201.637833 77.470619,204.582825 
	C76.607025,206.763992 79.535828,206.235733 79.830719,207.732620 
	C78.963287,209.560425 76.508308,206.622025 75.774979,209.006668 
	C74.915367,211.801956 78.668617,210.771378 78.775452,212.804962 
	C76.833931,213.871765 75.426781,212.033188 73.450607,211.495605 
z"
        />
        <path
          fill="#E1C900"
          opacity="1.000000"
          stroke="none"
          d="
M162.524323,198.934631 
	C163.095016,199.442932 163.276169,200.733185 163.887512,199.543716 
	C164.570587,198.214630 163.369720,196.147079 165.986954,195.046722 
	C164.818726,199.823807 167.870331,202.204895 171.105316,204.426361 
	C168.920639,206.166428 167.951416,203.391647 166.171539,203.930878 
	C165.136398,204.911255 167.290146,206.223206 166.166260,207.381821 
	C165.858536,207.450668 165.336990,207.646866 165.282288,207.557449 
	C163.649796,204.889145 161.724014,202.328339 160.575912,199.458023 
	C159.635178,197.106186 160.931091,197.252762 162.524323,198.934631 
z"
        />
        <path
          fill="#D4BE00"
          opacity="1.000000"
          stroke="none"
          d="
M155.318359,209.904114 
	C157.363907,210.310730 156.524719,207.341476 158.355362,207.925949 
	C159.291504,208.224854 159.310425,209.253082 159.013153,210.017090 
	C158.301865,211.845169 156.579330,212.194473 154.926926,212.288422 
	C153.496628,212.369736 149.608032,205.504105 150.244492,203.719955 
	C151.170792,201.123367 153.248291,200.620575 156.921158,201.518661 
	C151.017212,203.852829 153.126480,206.800247 155.318359,209.904114 
z"
        />
        <path
          fill="#D9C300"
          opacity="1.000000"
          stroke="none"
          d="
M58.814423,192.394226 
	C59.007641,191.140259 59.007641,190.211807 59.007641,189.145401 
	C61.070831,189.764496 62.343903,191.224823 64.320381,193.027267 
	C59.923347,192.868988 58.661552,196.213654 56.518322,198.491058 
	C55.995335,199.046814 55.576706,200.031708 54.588909,199.302689 
	C53.605179,198.576691 54.306358,197.863190 54.810013,197.248856 
	C56.060879,195.723129 57.348396,194.227478 58.814423,192.394226 
z"
        />
        <path
          fill="#E2CB00"
          opacity="1.000000"
          stroke="none"
          d="
M145.198349,215.544571 
	C143.194016,216.501999 141.776047,216.422211 141.238159,214.186798 
	C140.892349,212.749664 140.195099,211.336197 140.164001,209.901108 
	C140.131897,208.419662 137.992569,206.341995 140.134186,205.603973 
	C142.265533,204.869507 141.344543,208.370972 142.078110,208.530731 
	C145.903275,209.363770 140.997543,213.304276 144.611465,214.126678 
	C145.337479,214.462448 145.475540,214.846909 145.198349,215.544571 
z"
        />
        <path
          fill="#DDC600"
          opacity="1.000000"
          stroke="none"
          d="
M175.729218,194.288605 
	C174.667999,196.089081 175.391266,197.041092 176.722961,197.730713 
	C177.425995,198.094818 179.056412,197.680099 177.991623,199.314621 
	C177.179230,200.561722 176.133621,200.483505 175.174057,199.401566 
	C173.434265,197.439911 171.739212,195.437897 169.972794,193.500763 
	C169.027908,192.464554 169.609985,191.732651 170.367218,191.077454 
	C170.836044,190.671814 171.584045,190.070511 171.993973,190.205093 
	C173.742508,190.779129 171.763489,191.651627 172.050797,192.363602 
	C172.421204,193.281570 172.926651,194.117096 174.499878,193.904327 
	C175.104050,193.869904 175.354813,193.900208 175.729218,194.288605 
z"
        />
        <path
          fill="#867800"
          opacity="1.000000"
          stroke="none"
          d="
M43.884792,206.874146 
	C43.593040,208.952972 45.008759,211.767502 41.491085,211.914917 
	C38.259674,212.050323 39.036568,209.650848 38.926712,207.415054 
	C39.890930,205.930084 41.097813,205.943329 42.683739,206.135773 
	C43.250011,206.341415 43.462818,206.458588 43.884792,206.874146 
z"
        />
        <path
          fill="#CDB800"
          opacity="1.000000"
          stroke="none"
          d="
M183.584503,206.088562 
	C183.943939,206.740860 184.001740,207.569916 184.062149,208.398788 
	C184.195969,210.234589 183.056076,210.660049 181.547791,210.652405 
	C180.708588,210.648148 179.728638,210.649277 179.498154,209.656891 
	C179.148819,208.152802 179.815506,207.077545 181.330383,206.641174 
	C181.965042,206.458344 182.631866,206.387177 183.584503,206.088562 
z"
        />
        <path
          fill="#030300"
          opacity="1.000000"
          stroke="none"
          d="
M44.258408,206.606262 
	C42.571796,207.068161 41.179237,207.161850 39.386719,207.122864 
	C38.986752,206.990204 39.001907,207.029617 39.023026,207.025970 
	C38.229481,206.221451 37.986843,205.242645 38.264030,203.757904 
	C38.532436,203.158401 38.683922,202.939499 39.176926,202.512207 
	C40.578850,201.945068 41.608746,201.953598 42.895309,202.812195 
	C43.201553,203.065506 43.432850,203.028931 43.538555,202.978561 
	C44.945564,203.757477 44.966259,204.938156 44.258408,206.606262 
z"
        />
        <path
          fill="#121000"
          opacity="1.000000"
          stroke="none"
          d="
M36.221718,203.208008 
	C34.527946,201.714874 35.141457,200.098999 35.046139,198.233139 
	C36.090008,197.020386 37.188023,196.962173 38.699608,197.779236 
	C39.052406,198.038620 38.986156,198.006210 38.970825,197.972672 
	C40.054737,199.274033 40.343994,200.710953 39.230583,202.671692 
	C38.957649,203.053207 38.965527,202.962036 39.011040,202.957367 
	C38.331543,203.598694 37.506947,203.756973 36.221718,203.208008 
z"
        />
        <path
          fill="#F6DC00"
          opacity="1.000000"
          stroke="none"
          d="
M44.037476,206.997330 
	C43.911434,205.892273 43.858517,204.810089 43.724930,203.328049 
	C46.603443,202.929230 48.743496,203.710968 47.912300,207.730591 
	C46.921600,207.879410 45.890102,207.661118 44.476284,207.307678 
	C44.093964,207.172531 44.110596,207.020203 44.037476,206.997330 
z"
        />
        <path
          fill="#D0BA00"
          opacity="1.000000"
          stroke="none"
          d="
M189.394226,205.336929 
	C187.583069,205.994568 186.158096,206.051575 184.309021,206.010223 
	C183.884949,205.911880 183.927368,205.962067 183.949432,205.984421 
	C184.103043,203.287857 185.038177,201.412308 188.889908,202.266052 
	C189.848923,203.286621 189.911133,204.000031 189.394226,205.336929 
z"
        />
        <path
          fill="#DEC700"
          opacity="1.000000"
          stroke="none"
          d="
M39.377899,202.989853 
	C38.998283,201.631073 39.038914,200.208954 39.017521,198.362976 
	C41.529495,198.245331 43.936314,198.721786 43.273788,202.657959 
	C42.067081,203.019165 40.932613,202.972839 39.377899,202.989853 
z"
        />
        <path
          fill="#D4BE00"
          opacity="1.000000"
          stroke="none"
          d="
M189.007431,202.047073 
	C188.584595,199.437546 188.638885,197.126846 192.582321,196.979950 
	C192.985687,198.309647 193.033630,199.734543 192.734589,201.841888 
	C191.420242,202.967484 190.463196,202.992874 189.241699,202.204987 
	C188.963303,201.956390 188.981094,202.024048 189.007431,202.047073 
z"
        />
        <path
          fill="#BDA900"
          opacity="1.000000"
          stroke="none"
          d="
M193.003571,196.941132 
	C193.083252,196.651535 193.199142,196.334381 193.217285,196.011749 
	C193.310043,194.362930 193.156998,192.471466 195.528839,192.418167 
	C196.806473,192.389450 196.910492,193.529312 196.964584,194.566101 
	C197.091019,196.989273 195.608658,197.224915 193.345184,196.938995 
	C192.937759,196.884750 192.994675,196.911057 193.003571,196.941132 
z"
        />
        <path
          fill="#DDC600"
          opacity="1.000000"
          stroke="none"
          d="
M192.952576,201.979065 
	C194.367233,202.332275 196.635406,200.432922 197.029175,203.558380 
	C197.366425,206.235153 195.858978,206.480606 193.126709,205.714340 
	C192.023529,204.408615 192.005142,203.452835 192.797592,202.241821 
	C193.047226,201.966064 192.975967,201.951157 192.952576,201.979065 
z"
        />
        <path
          fill="#DDC600"
          opacity="1.000000"
          stroke="none"
          d="
M192.963654,205.963959 
	C192.719421,207.451920 194.533081,209.719681 191.448090,210.173935 
	C188.661163,210.584290 188.584396,208.839645 188.972137,206.383621 
	C189.008057,205.937592 188.950287,205.980637 188.960022,206.015320 
	C189.971680,205.178619 191.076965,204.955521 192.674316,205.779419 
	C193.041138,206.041122 192.989655,205.989517 192.963654,205.963959 
z"
        />
        <path
          fill="#D9C300"
          opacity="1.000000"
          stroke="none"
          d="
M38.986908,197.636169 
	C37.981110,198.052109 36.909813,198.065582 35.452991,197.961243 
	C35.500992,196.479004 34.082794,194.205215 36.601742,193.897842 
	C39.008110,193.604218 38.919712,195.571060 38.986908,197.636169 
z"
        />
        <path
          fill="#F2D900"
          opacity="1.000000"
          stroke="none"
          d="
M47.893875,208.180817 
	C48.341179,208.079086 48.814423,207.950043 49.290825,207.833984 
	C50.900661,207.441772 52.287727,207.714264 52.846645,209.450836 
	C53.184528,210.500626 52.844578,211.310379 51.487865,211.447052 
	C49.441044,211.653259 48.365189,210.887619 48.178627,208.431793 
	C47.953098,208.097717 47.921257,208.159454 47.893875,208.180817 
z"
        />
        <path
          fill="#EED500"
          opacity="1.000000"
          stroke="none"
          d="
M35.717995,203.396484 
	C36.657562,203.001740 37.460377,203.044052 38.659874,203.019531 
	C39.034664,204.037231 39.012779,205.121780 39.017521,206.614334 
	C35.716282,208.319824 35.372982,206.376175 35.717995,203.396484 
z"
        />
        <path
          fill="#C1BD98"
          opacity="1.000000"
          stroke="none"
          d="
M189.543686,192.360107 
	C190.524475,191.044434 191.377289,190.029968 192.230087,189.015518 
	C192.133362,191.051285 192.189636,193.218704 189.543686,192.360107 
z"
        />
        <path
          fill="#AF9E00"
          opacity="1.000000"
          stroke="none"
          d="
M144.670380,204.110153 
	C145.938980,204.874893 145.791382,205.537552 144.793915,205.882721 
	C144.110291,206.119278 143.102005,206.411316 142.971756,205.197968 
	C142.885757,204.396652 143.688889,204.254379 144.670380,204.110153 
z"
        />
        <path
          fill="#CDB800"
          opacity="1.000000"
          stroke="none"
          d="
M145.346161,215.766129 
	C144.978149,215.423782 144.983109,215.063553 144.984253,214.433136 
	C145.935089,214.092514 147.224564,212.742401 147.732956,214.288193 
	C148.086395,215.362915 146.433228,215.263000 145.346161,215.766129 
z"
        />
        <path
          fill="#DDC600"
          opacity="1.000000"
          stroke="none"
          d="
M179.455109,197.917404 
	C178.002380,197.283234 178.786652,196.673401 179.358826,196.207474 
	C179.607437,196.005020 180.184586,196.205978 180.612518,196.223694 
	C180.358429,196.733536 180.104355,197.243378 179.455109,197.917404 
z"
        />
        <path
          fill="#DDC600"
          opacity="1.000000"
          stroke="none"
          d="
M175.090134,194.057556 
	C175.327454,193.214920 175.774017,192.426559 176.695572,193.120392 
	C176.809814,193.206421 176.483673,193.877350 176.107239,194.461990 
	C175.677567,194.522995 175.502258,194.399246 175.090134,194.057556 
z"
        />
        <path
          fill="#FBE102"
          opacity="1.000000"
          stroke="none"
          d="
M167.350693,52.627365 
	C193.279816,75.626793 203.026520,103.944626 193.606567,136.914017 
	C184.324921,169.399323 161.582108,189.160568 128.246750,194.668808 
	C84.767410,201.853195 43.918304,172.148361 36.165161,129.049164 
	C27.973419,83.511841 60.487411,40.124760 106.535866,34.840706 
	C129.259308,32.233192 149.263474,38.387047 167.350693,52.627365 
z"
        />
        <path
          fill="#0C0B00"
          opacity="1.000000"
          stroke="none"
          d="
M120.135544,213.824219 
	C118.832436,212.696472 118.688904,211.476318 119.505249,210.288208 
	C119.639320,210.093079 120.553513,210.105804 120.737823,210.328339 
	C121.701248,211.491608 121.670998,212.702209 120.135544,213.824219 
z"
        />
        <path
          fill="#211E00"
          opacity="1.000000"
          stroke="none"
          d="
M132.907013,216.410858 
	C130.125153,214.631516 130.959641,211.897049 130.865112,209.401108 
	C130.860855,209.288757 132.015579,208.938614 132.083694,209.045990 
	C133.484222,211.254425 134.343384,213.588226 132.907013,216.410858 
z"
        />
        <path
          fill="#242000"
          opacity="1.000000"
          stroke="none"
          d="
M109.868942,214.580902 
	C109.815659,216.198593 109.714836,217.575058 108.218506,217.596222 
	C106.856087,217.615494 107.039345,216.271423 106.995232,215.310089 
	C106.972458,214.813889 106.950890,214.287949 107.084396,213.819839 
	C107.484985,212.415253 106.240677,209.726578 108.751282,209.954895 
	C111.077339,210.166428 109.545151,212.692398 109.868942,214.580902 
z"
        />
        <path
          fill="#000000"
          opacity="1.000000"
          stroke="none"
          d="
M193.167175,205.645264 
	C191.948288,206.125763 190.855453,206.210403 189.366180,206.172516 
	C188.896515,204.952972 188.823257,203.855957 188.856659,202.357666 
	C190.058884,201.882751 191.154449,201.809113 192.648636,201.850769 
	C193.129227,203.060501 193.211212,204.154953 193.167175,205.645264 
z"
        />
        <path
          fill="#040300"
          opacity="1.000000"
          stroke="none"
          d="
M110.264740,104.392059 
	C110.069626,123.169746 109.979355,142.164978 110.276123,161.154160 
	C110.348419,165.780212 109.008888,167.630096 104.373825,167.047333 
	C102.404984,166.799759 100.379082,167.006027 98.379112,167.006012 
	C90.154915,167.005936 90.192131,167.005722 90.145966,159.009216 
	C90.068054,145.514801 89.959068,132.020477 89.824425,118.526505 
	C89.618355,97.873146 89.470352,77.218399 89.078209,56.568401 
	C88.999031,52.398773 90.341759,51.075665 94.496284,51.069340 
	C107.829628,51.049049 107.844269,50.899944 112.433090,63.258892 
	C117.986122,78.214745 123.486839,93.190086 129.082184,108.130028 
	C129.688721,109.749527 129.824722,111.686768 131.304153,112.770172 
	C131.304153,98.399826 131.316498,84.008156 131.297424,69.616516 
	C131.290802,64.619194 131.263596,59.620502 131.134964,54.625290 
	C131.063431,51.847961 132.248001,50.756393 135.018127,50.787411 
	C138.840012,50.830212 142.668991,50.658134 146.487625,50.446320 
	C149.810760,50.261986 151.095169,51.466282 151.120880,55.014565 
	C151.299423,79.665863 151.712051,104.315353 151.994202,128.966064 
	C152.120071,139.963074 151.998627,150.964218 152.242294,161.957870 
	C152.321030,165.510040 151.001175,166.498245 147.631210,166.597137 
	C133.748367,167.004501 133.750656,167.093475 128.890137,153.991928 
	C122.936424,137.943649 116.983322,121.895157 110.980316,105.479401 
	C110.791115,104.943817 110.651558,104.775589 110.264740,104.392059 
z"
        />
        <path
          fill="#FFFDF1"
          opacity="1.000000"
          stroke="none"
          d="
M87.023010,104.999451 
	C87.228683,124.485741 87.416550,143.472168 87.650558,162.458038 
	C87.723114,168.344269 89.212105,169.758255 95.125885,169.813889 
	C97.458801,169.835846 99.799339,169.707718 102.122345,169.861053 
	C103.424561,169.947006 105.478424,168.985092 105.722694,171.296738 
	C105.909790,173.067245 104.896080,174.915833 103.206566,175.219513 
	C96.651604,176.397766 89.980255,176.714050 83.451454,175.318985 
	C81.055359,174.806992 81.107231,172.021179 81.099754,169.788055 
	C81.020592,146.126373 80.948425,122.464584 80.804604,98.803246 
	C80.724640,85.648773 80.490036,72.495270 80.390762,59.340836 
	C80.378418,57.705059 80.522934,55.982853 81.040108,54.451050 
	C81.617554,52.740711 83.183441,51.905178 85.014771,52.049286 
	C87.457901,52.241531 86.587372,54.237034 86.601814,55.520016 
	C86.785492,71.845978 86.894783,88.172783 87.023010,104.999451 
z"
        />
        <path
          fill="#FFFEF7"
          opacity="1.000000"
          stroke="none"
          d="
M112.830734,130.129974 
	C112.830688,126.867477 112.830688,124.077324 112.830688,121.212631 
	C114.709282,121.492416 114.554459,123.317169 115.012070,124.537277 
	C119.785980,137.265900 124.610512,149.978363 129.132675,162.797028 
	C130.731949,167.330399 133.048828,169.911346 138.175369,169.181427 
	C140.129715,168.903168 142.163300,169.039261 144.148590,169.170639 
	C145.472748,169.258270 147.484009,168.382645 147.732742,170.665298 
	C147.953766,172.693710 146.784454,174.534149 144.851974,174.769119 
	C138.750183,175.511047 132.589508,175.452454 126.479294,174.805832 
	C125.028442,174.652298 124.198288,173.034164 123.670731,171.552032 
	C120.167892,161.710846 116.346985,151.968933 113.275162,141.995865 
	C112.193916,138.485428 112.926430,134.416336 112.830734,130.129974 
z"
        />
        <path
          fill="#FFFEF4"
          opacity="1.000000"
          stroke="none"
          d="
M129.017853,92.517876 
	C129.017838,94.208015 129.017838,95.467026 129.017838,96.726036 
	C125.656197,89.303139 121.917198,82.169891 122.723457,73.655251 
	C123.253975,68.052597 122.812088,62.359001 122.833435,56.706230 
	C122.839035,55.222672 122.697891,53.692608 123.617561,52.367409 
	C124.652306,50.876396 126.204895,50.012852 127.877151,50.338825 
	C129.770950,50.707973 128.821793,52.538940 128.831970,53.684746 
	C128.945709,66.484955 128.969696,79.285957 129.017853,92.517876 
z"
        />
        <path
          fill="#453E00"
          opacity="1.000000"
          stroke="none"
          d="
M110.199669,104.455582 
	C110.548653,103.943489 110.845207,104.175682 110.968781,104.893753 
	C110.747742,104.986176 110.564804,104.860306 110.199669,104.455582 
z"
        />
      </svg>{' '}
    </Box>
  );
}
